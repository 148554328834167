import './subject-header.cmpt.scss'

import * as React from "react";
import {DragEvent} from "react";
import {Category, Subject} from "../../../../subject";
import {SubjectBreadcrumbCmpt} from "../../../subject-breadcrumb/subject-breadcrumb.cmpt";
import {SubjectHeaderCategoryCmpt} from "./subject-header-category/subject-header-category.cmpt";


export interface SubjectHeaderCmptProps {
    subject: Subject;
    isSelected: boolean;
    isExpand: boolean;
    subjectCategory: Category;
    onDragStart: (e: DragEvent) => void;
    onDragEnd: (e: DragEvent) => void;
}


export class SubjectHeaderCmpt extends React.Component<SubjectHeaderCmptProps> {

    shouldComponentUpdate(nextProps: Readonly<SubjectHeaderCmptProps>): boolean {
        const {subject, isSelected, isExpand, subjectCategory} = this.props;
        if(subject.code !== nextProps.subject.code) {
            return true;
        }
        if(isSelected !== nextProps.isSelected) {
            return true;
        }
        if(isExpand !== nextProps.isExpand) {
            return true;
        }
        if(subjectCategory !== nextProps.subjectCategory) {
            return true;
        }

        return false;
    }

    render() {
        const {subject, isSelected, isExpand, onDragStart, onDragEnd, subjectCategory} = this.props;

        return (
            <React.Fragment>
                <div
                    className={`subject-header ${isSelected ? 'subject-header--selected' : ''}`}
                    draggable
                    onDragStart={onDragStart}
                    onDragEnd={onDragEnd}>
                    <SubjectHeaderCategoryCmpt isSelected={isSelected} category={subjectCategory} expand={isExpand}/>
                    <div className="subject-header__text">{subject.code}&nbsp;&nbsp;&nbsp;&nbsp;{subject.info.name}</div>
                    <SubjectBreadcrumbCmpt subject={subject}/>
                </div>
            </React.Fragment>
        );
    }
}