import './search-box.cmpt.scss';
import * as React from "react";

export interface SearchBoxCmptProps {
    onSearch: (toSearch: string) => void;
    value: string;
}

export class SearchBoxCmpt extends React.Component<SearchBoxCmptProps> {

    constructor(props: SearchBoxCmptProps, context: any) {
        super(props, context);

        this.onChange = this.onChange.bind(this);
        this.clearInput = this.clearInput.bind(this);
    }

    private clearInput() {
        this.props.onSearch("");
    }

    private onChange(event) {
        const toSearch = (event.currentTarget as HTMLInputElement).value;
        this.props.onSearch(toSearch);
    }

    render() {
        const {value} = this.props;
        return (
            <div className="search-box">
                <div className="search-box__search material-icons dp48">search</div>
                {!!value ? <div className="search-box__clear material-icons dp48" onClick={this.clearInput}>close</div> : <></>}
                <input className="search-box__input"
                       type="search"
                       placeholder="Search..."
                       onChange={this.onChange}
                       value={!value ? "" : value}/>
            </div>
        );
    }
}