import './selected-subjects.cmpt.scss';

import * as React from "react";
import {inject, observer} from "mobx-react";
import {CourseYearCmpt} from "./course-year/course-year.cmpt";
import {DragDropContext, DropResult} from "react-beautiful-dnd";
import {FavouritedSubjectsCmpt} from "./favourited-subjects/favourited-subjects.cmpt";
import {EventBus, EventRegistrationHandle, Events} from "../../shared/services/event-bus.service";
import {Given as CoursePlanGiven} from "../course-plan";
import {ToolsCmpt} from "../../tools/tools.cmpt";
import {FeatureSupportStore} from "../../shared/stores/feature-support.store";
import {MyCoursePlansStore} from "../../shared/stores/my-course-plans.store";
import {StringUtil} from "../../shared/util";

interface SelectedSubjectsCmptProps {
    myCoursePlans?: MyCoursePlansStore;
    featureSupport?: FeatureSupportStore;
}

interface SelectedSubjectsCmptState {
    isDragging: boolean;
}

@inject('myCoursePlans', 'featureSupport')
@observer
export class SelectedSubjectsCmpt extends React.Component<SelectedSubjectsCmptProps, SelectedSubjectsCmptState> {

    static DRAG_AND_DROP_TYPE = 'SUBJECT';

    private reorderSubjectEventRegHandler: EventRegistrationHandle;


    constructor(props: SelectedSubjectsCmptProps, context: any) {
        super(props, context);

        this.state = {isDragging: false};
    }

    onDragEnd(result: DropResult) {
        if (!result.destination || !result.destination.droppableId || !result.draggableId) {
            return;
        }

        const myCoursePlans = this.props.myCoursePlans;
        const subjectCode = result.draggableId.split('-')[1];
        if (result.destination.droppableId === 'favourite') {
            myCoursePlans.moveSubjectToFavourite(subjectCode);
        } else {
            const dropIdParts = result.destination.droppableId.split('-');
            const toYear = Number(dropIdParts[0]);
            const semester = dropIdParts[1];
            const subjectsByCourseSemester = CoursePlanGiven.subjects(this.props.myCoursePlans.coursePlan.course.subjects).findByCourseSemester(toYear, semester);
            const dropIndex = (result.destination.index >= subjectsByCourseSemester.length) ? subjectsByCourseSemester.length - 1 : result.destination.index;
            const insertAtSubject = subjectsByCourseSemester.length === 0 ? null : subjectsByCourseSemester[dropIndex].code;

            myCoursePlans.moveSubjectToYear(
                subjectCode,
                toYear,
                semester,
                insertAtSubject,
                (subjectsByCourseSemester.length - 1) === dropIndex ? 'after' : 'before',
                result.source.droppableId === 'favourite' ? true : false
            );
        }
    }

    componentDidMount(): void {
        this.reorderSubjectEventRegHandler = EventBus.register(Events.REORDERING_SELECTED_SUBJECT, (payload) => {
            if (payload === true) {
                this.onHtmlDragStart();
            } else {
                this.onHtmlDragEnd();
            }
        });
    }

    componentWillUnmount(): void {
        this.reorderSubjectEventRegHandler.done()
    }

    onHtmlDragStart() {
        this.setState({isDragging: true});
    }

    onHtmlDragEnd() {
        this.setState({isDragging: false});
    }


    render() {
        const {isDragging} = this.state;
        const {myCoursePlans, featureSupport} = this.props;

        let className = `selected-subjects
                         ${featureSupport.htmlDnD ? '' : 'selected-subjects--html-dragging-mode selected-subjects--react-dnd'}
                         ${isDragging ? 'selected-subjects--html-dragging-mode' : ''}`;
        return (
            <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
                <div className={StringUtil.ensureOneLine(className)}>
                    <div>
                        {
                            myCoursePlans.coursePlan
                                .coursePlanReq
                                .yearReq
                                .map((courseYear) => <CourseYearCmpt yearRequirement={courseYear} key={courseYear.year}/>)
                        }
                        <FavouritedSubjectsCmpt/>
                        <ToolsCmpt/>
                    </div>
                </div>
            </DragDropContext>
        );
    }
}