import {ArrayUtil} from "../shared/util";
import {Status} from "./../status";
import {UserPref} from "./outcome.user-pref";

export interface Outcome {
    code: string;
    class: Class;
    info: Info;
    specialisations: Array<Outcome>;
    state: State;
    userPref: UserPref;
}


export interface Info {
    name: string;
    overview: string;
    handbook_url: string;
}

export interface State {
    status: Status //'met|open|unmet',
    availability_score: number;
    info: string;
    warning: string;
    error: string; //'Two requisites are not met (MATH10004 OR MATH10006)',
    msgs: Array<string>;
}

export enum Class {
    major = 'major', minor = 'minor'
}

export class OutcomeUtil {
    constructor(private outcome: Outcome) {
    }

    isSelected(): boolean {
        return !!this.outcome.userPref && !!this.outcome.userPref.selected ? true : false;
    }

    isFavourited(): boolean {
        return !!this.outcome.userPref && !!this.outcome.userPref.favourite ? true : false;
    }

    findSpecialisatoin(specialisations: Array<string>) {
        return ArrayUtil.findBy(this.outcome.specialisations, 'code', specialisations);
    }

    getAvailbilityAsMessage() {
        switch (this.outcome.state.availability_score) {
            case 3:
                return 'With your current plan, it may not be possible attain this major';
            case 1:
                return 'With your current plan, it is not possible to attain this major.';
            default:
                return null;
        }
    }
}

export class OutcomesUtil {
    constructor(private outcomes: Array<Outcome>) {
    }

    get(): Array<Outcome> {
        return this.outcomes;
    }

    findByCodes(outcomes: Array<Outcome>): OutcomesUtil {
        const names = ArrayUtil.extract(outcomes, 'code');
        this.outcomes = ArrayUtil.findBy(this.outcomes, 'code', names);
        return this;
    }

    findByClasses(klasses: Array<Class>): OutcomesUtil {
        this.outcomes = ArrayUtil.findBy(this.outcomes, 'class', klasses);
        return this;
    }

    findSelected(): OutcomesUtil {
        // TODO: need to filter out by class first?
        // this.outcomes = ArrayUtil.findBy(this.outcomes, 'userPref.selected', [true]);
        this.outcomes = ArrayUtil.findWith(this.outcomes, (outcome)=>new OutcomeUtil(outcome).isSelected());
        return this;
    }

    findFavourites(): OutcomesUtil {
        // TODO: need to filter out by class first?
        //this.outcomes = ArrayUtil.findBy(this.outcomes, 'userPref.favourite', [true]);
        this.outcomes = ArrayUtil.findWith(this.outcomes, (outcome)=>new OutcomeUtil(outcome).isFavourited());
        return this;
    }
}

