import {Subject, SubjectsUtil, SubjectUtil, VCESubject} from "../browse-subjects/subject";
import {Outcome, OutcomesUtil, OutcomeUtil} from "../outcomes/outcome";
import {ArrayUtil} from "../shared/util";
// TODO: remove this, mobx shouldn't be here
import {toJS} from "mobx";
import {Status} from "./../status";
import {Course} from "./course";
import {CompletionRule, CompletionRuleUtil} from "./course.completion-rule";
import {CompletionRequirement, CourseYearUtil, SemesterRequirement, YearRequirement} from "./course-plan.completion-requirement";

export interface CoursePlan {
    saveAs: string;
    coursePlanReq: CompletionRequirement;
    vceSubjects: Array<VCESubject>;
    status: CourseStatus;
    course: Course;
    msgs: Array<string>;
}

export enum CourseStatus {
    complete = 'complete',
    invalid = 'invalid',
    in_progress = 'in_progress',
    available = 'available'
}


export class Given {
    static subjects(subjects: Array<Subject>) {
        return new SubjectsUtil(subjects);
    }

    static subject(subject: Subject) {
        return new SubjectUtil(subject);
    }

    static coursePlan(coursePlan?: CoursePlan) {
        return new CoursePlanUtil(coursePlan);
    }

    static courseYear(courseYear: YearRequirement) {
        return new CourseYearUtil(courseYear);
    }

    static outcome(outcome: Outcome) {
        return new OutcomeUtil(outcome);
    }

    static outcomes(majors: Array<Outcome>) {
        return new OutcomesUtil(majors);
    }

    static courseCompletionRule(CourseCompletionRule: CompletionRule) {
        return new CompletionRuleUtil(CourseCompletionRule);
    }
}

class CoursePlanUtil {
    constructor(private coursePlan: CoursePlan) {
    }

    toUrlSavedJson(): string {
        const coursePlan = toJS(this.coursePlan);
        const json = {
            c: coursePlan.course.code,
            y: coursePlan.course.yearStart,
            // @ts-ignore
            o: [],
            // @ts-ignore
            s: [],
            // @ts-ignore
            v: [],
        };

        coursePlan.vceSubjects.forEach((vceSubject) => {
            json.v.push([vceSubject.name, vceSubject.score]);
        });

        coursePlan.course.outcomes.forEach((outcome: Outcome) => {
            json.o.push([
                outcome.code, outcome.userPref.selected ? 1 : 0,
                outcome.userPref.favourite ? 1 : 0,
                outcome.userPref.specialisation.selected ? outcome.userPref.specialisation.selected.code : undefined,
                outcome.userPref.specialisation.favourite ? outcome.userPref.specialisation.favourite.code : undefined
            ]);
        });
        coursePlan.course.subjects.forEach((subject: Subject) => {
            json.s.push([
                subject.code,
                subject.userPref.year,
                subject.userPref.semester,
                subject.userPref.selected ? 1 : 0
            ]);
        });

        return JSON.stringify(json);
    }

    findSelectedSubjects(): Array<Subject> {
        return Given.subjects(this.coursePlan.course.subjects).findSelected();
    }

    getSubjectCodes() {
        return ArrayUtil.extract(this.coursePlan.course.subjects, 'code');
    }

    getRequiredPoints() {
        return this.coursePlan
            .coursePlanReq
            .pointsReq;
    }

    whichYearAndSemesterToAdd(subject: Subject) {
        return new CourseYearAndSemesterSelector(this.coursePlan).apply(subject);
    }


    getOutcomeStatus(outcome: Outcome) {
        const {status = Status.met} = outcome.state || {status: Status.met};
        return status;
    }

    getCourseStatus(): CourseStatus {
        const coursePlan = this.coursePlan;
        if (coursePlan.status === CourseStatus.invalid) {
            return CourseStatus.invalid;
        }

        if (coursePlan.course.state.status === CourseStatus.invalid) {
            return CourseStatus.invalid;
        }

        let outcomesStatus: Array<Status> = coursePlan.course.outcomes.map((outcome) => {
            return this.getOutcomeStatus(outcome);
        });

        let specialisationStatus: Array<Status> = coursePlan.course.outcomes
            .filter((outcome: Outcome) => {
                return !!outcome.userPref.specialisation.selected;
            })
            .map((outcome: Outcome) => {
                return this.getOutcomeStatus(outcome.userPref.specialisation.selected);
            });

        const isUnmet = outcomesStatus.indexOf(Status.unmet) !== -1 || specialisationStatus.indexOf(Status.unmet) !== -1;
        if (isUnmet) {
            return CourseStatus.invalid;
        }
        return coursePlan.status;
    }

    getCourseLevelMsgs(): Array<string> {
        const coursePlan = toJS(this.coursePlan);
        let msgs: Array<string> = [];

        // add course level msgs - not yet exists
        if (coursePlan.status === CourseStatus.invalid || coursePlan.course.state.status === CourseStatus.invalid) {
            msgs = msgs.concat(coursePlan.msgs);
        }

        // add specialisation level msgs
        coursePlan.course.outcomes.forEach((outcome: Outcome) => {
            const outcomeStatus = this.getOutcomeStatus(outcome);
            if (outcomeStatus === Status.unmet) {
                msgs = msgs.concat(toJS(outcome.state.msgs));
            }
            if (!!outcome.userPref.specialisation.selected) {
                const specialisationStatus = this.getOutcomeStatus(outcome.userPref.specialisation.selected);
                if (specialisationStatus === Status.unmet) {
                    msgs = msgs.concat(toJS(outcome.userPref.specialisation.selected.state.msgs));
                }
            }
        });

        return msgs;
    }
}

class CourseYearAndSemesterSelector {

    constructor(private coursePlan: CoursePlan) {
    }

    private getCourseYearWhenSubjectIsIntensive(subject: Subject): YearRequirement {
        const courseYears: Array<YearRequirement> = this.coursePlan.coursePlanReq.yearReq;
        const targetCourseYear = courseYears.find((courseYear: YearRequirement) => courseYear.year === subject.info.level);

        // target course year not found
        if (!targetCourseYear) {
            return null;
        }

        const intensiveSemesters = targetCourseYear.semesters
            .filter((courseSemester) => !courseSemester.standard)
            .map((courseSemester) => courseSemester.name);

        if (!intensiveSemesters) {
            return null;
        }

        // if subject's availability is found not in the intensive semesters, the subject is also offered in the standard semesters
        const found = Given.subject(subject).getAvailabilityNames().filter((avail) => intensiveSemesters.indexOf(avail) === -1);
        if (found.length > 0) {
            return null;
        }

        return targetCourseYear;
    }

    private getCourseSemesterWhenSubjectIsIntensive(targetCourseYear: YearRequirement, selectedSubjects: Array<Subject>, subject: Subject, offeringSemesters: Array<string>) {
        // try to find the course semester from non-standard semesters
        const nonStandardSemesters = targetCourseYear.semesters
            .filter((courseSemester) => !courseSemester.standard);

        const defaultCourseSemester = nonStandardSemesters[nonStandardSemesters.length - 1];
        let targetCourseSemester = nonStandardSemesters
            .find(courseSemester => {
                const subjectsByYearAndSemester = Given.subjects(selectedSubjects).findByCourseSemester(targetCourseYear.year, courseSemester.name);
                const totalPoints = Given.subjects(subjectsByYearAndSemester).getTotalPoints();
                const courseSemesterAvailable = ((totalPoints + subject.info.points) <= courseSemester.points);
                return courseSemesterAvailable && (offeringSemesters.indexOf(courseSemester.name) !== -1);
            });
        if (!targetCourseSemester) {
            targetCourseSemester = defaultCourseSemester;
        }
        return targetCourseSemester;
    }

    private getCourseSemester(targetCourseYear: YearRequirement, subject: Subject, selectedSubjects: Array<Subject>, offeringSemesters: Array<string>) {
        const isSubjectCourseYearOverloaded = !!targetCourseYear && subject.info.level !== targetCourseYear.year;

        let defaultCourseSemester: SemesterRequirement = null;
        let targetCourseSemester: SemesterRequirement = null;
        if (!!targetCourseYear) {
            // try to find the course semester from standard semesters
            targetCourseSemester = targetCourseYear.semesters
                .filter((targetCourseSemester) => !!targetCourseSemester.standard)
                .find(courseSemester => {
                    const subjectsByYearAndSemester = Given.subjects(selectedSubjects).findByCourseSemester(targetCourseYear.year, courseSemester.name);
                    const totalPoints = Given.subjects(subjectsByYearAndSemester).getTotalPoints();
                    const courseSemesterAvailable = ((totalPoints + subject.info.points) <= courseSemester.points);
                    if (courseSemesterAvailable && !defaultCourseSemester) {
                        defaultCourseSemester = courseSemester;
                    }
                    return courseSemesterAvailable && (isSubjectCourseYearOverloaded || (offeringSemesters.indexOf(courseSemester.name) !== -1));
                });
        }
        if (!targetCourseSemester) {
            targetCourseSemester = defaultCourseSemester;
        }
        return targetCourseSemester;
    }

    apply(subject: Subject): { year: number, semester: string } {
        const coursePlan = this.coursePlan;
        const selectedSubjects = Given.coursePlan(coursePlan).findSelectedSubjects();
        const courseYears = coursePlan.coursePlanReq.yearReq;
        const offeringSemesters: Array<string> = Given.subject(subject).getAvailabilityNames();

        let targetCourseYear = this.getCourseYearWhenSubjectIsIntensive(subject);
        let targetCourseSemester: SemesterRequirement = null;

        // adding intensive course semester subject
        if (!!targetCourseYear) {
            targetCourseSemester = this.getCourseSemesterWhenSubjectIsIntensive(targetCourseYear, selectedSubjects, subject, offeringSemesters);
        } else {
            targetCourseYear = courseYears.find(courseYear => {
                const subjectsByYear = Given.subjects(selectedSubjects).findByCourseYears([courseYear.year]);
                const totalPoints = Given.subjects(subjectsByYear).getTotalPoints();
                return ((totalPoints + subject.info.points) <= Given.courseYear(courseYear).getRequiredPoints()) && courseYear.year >= subject.info.year;
            });

            targetCourseSemester = this.getCourseSemester(targetCourseYear, subject, selectedSubjects, offeringSemesters);
        }

        if (!!targetCourseYear && !!targetCourseSemester) {
            return {year: targetCourseYear.year, semester: targetCourseSemester.name}
        }

        const lastYear = courseYears[courseYears.length - 1];
        const courseSemesters = lastYear.semesters.filter(semester => !!semester.standard);
        const lastSemester = courseSemesters[courseSemesters.length - 1];
        return {year: lastYear.year, semester: lastSemester.name};
    }
}













