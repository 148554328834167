import * as React from "react";
import './browse-subjects.cmpt.scss';
import {AvailableSubjectsCmpt} from "./available-subjects/available-subjects.cmpt";
import {ClickableCmpt} from "../shared/components/clickable/clickable.cmpt";
import {SearchBoxCmpt} from "../shared/components/search-box/search-box.cmpt";
import {inject, observer} from "mobx-react";
import {EventBus, EventRegistrationHandle, Events} from "../shared/services/event-bus.service";
import {MyCoursePlansStore} from "../shared/stores/my-course-plans.store";
import {UIConfigStore} from "../shared/stores/ui-config.store";
import {UIConfig} from "../../configs/UIConfig";


function SubjectFilter({
                           level,
                           grouping,
                           courseCode,
                           setLevel,
                           setGrouping
                       }) {
    return (
        <div className='browse-subjects__filters'>
            <span className={`browse-subjects__filter-spacer`}>&nbsp;</span>
            <span className={`browse-subjects__filter` + (level === 0 ? ' browse-subjects__filter--selected' : '')}>
                    <ClickableCmpt content='ALL' onclick={() => setLevel(0)}/>
                </span>

            <span className={`browse-subjects__filter` + (level === 1 ? ' browse-subjects__filter--selected' : '')}>
                    <ClickableCmpt content={
                        <div className="browse-subjects__filter-wrapper">
                            <span>LEVEL 1</span>
                        </div>
                    } onclick={() => setLevel(1)}/>
                </span>
            <span className={`browse-subjects__filter` + (level === 2 ? ' browse-subjects__filter--selected' : '')}>
                    <ClickableCmpt content={
                        <div className="browse-subjects__filter-wrapper">
                            <span>LEVEL 2</span>
                        </div>
                    } onclick={() => setLevel(2)}/>
                </span>
            <span className={`browse-subjects__filter` + (level === 3 ? ' browse-subjects__filter--selected' : '')}>
                    <ClickableCmpt content={
                        <div className="browse-subjects__filter-wrapper">
                            <span>LEVEL 3</span>
                        </div>
                    } onclick={() => setLevel(3)}/>
                </span>
            <span className={`browse-subjects__filter-spacer`}>&nbsp;</span>
            <span className={
                `browse-subjects__filter browse-subjects__filter-breadth
                        ${(grouping === courseCode ? 'browse-subjects__filter--selected' : '')}`}>
                    <ClickableCmpt
                        content={
                            <div className="browse-subjects__filter-wrapper">
                                <span>{courseCode}</span>
                            </div>
                        } onclick={() => setGrouping(grouping === courseCode ? null : courseCode)}/>
                </span>
            <span className={
                `browse-subjects__filter browse-subjects__filter-breadth
                        ${(grouping === BrowseSubjectsCmpt.BREADTH_GROUPING ? 'browse-subjects__filter--selected' : '')}`}>
                    <ClickableCmpt
                        content={
                            <div className="browse-subjects__filter-wrapper">
                                <span>BREADTH</span>
                            </div>
                        }
                        onclick={() => setGrouping(grouping === BrowseSubjectsCmpt.BREADTH_GROUPING ? null : BrowseSubjectsCmpt.BREADTH_GROUPING)}/>
                </span>
        </div>
    );
}

export interface BrowseSubjectsCmptState {
    level: number;
    grouping: string;
    search: string;
    isHeaderSticky: boolean;
    headerWidth: string;

}

export interface BrowseSubjectsCmptProps {
    myCoursePlans?: MyCoursePlansStore
    uiConfig?: UIConfigStore
}

@inject('myCoursePlans', 'uiConfig')
@observer
export class BrowseSubjectsCmpt extends React.Component<BrowseSubjectsCmptProps, BrowseSubjectsCmptState> {

    static BREADTH_GROUPING: string = 'BREADTH';
    static DISCIPLINE_GROUPING: string = 'DISCIPLINE';

    private domRef: HTMLDivElement;
    private filterDomOffsetTop: number;
    private headerDomRef: HTMLElement;

    private updateFilterRegHandler: EventRegistrationHandle;

    constructor(props: BrowseSubjectsCmptProps) {
        super(props);

        this.state = {
            level: 0,
            grouping: this.props.uiConfig.get(UIConfig.available_subjects_filter_group),
            search: null,
            isHeaderSticky: false,
            headerWidth: 'auto'
        };

        this.setSearch = this.setSearch.bind(this);
        this.setLevel = this.setLevel.bind(this);
        this.setGrouping = this.setGrouping.bind(this);
    }

    componentDidMount(): void {
        this.filterDomOffsetTop = (this.headerDomRef as HTMLElement).getBoundingClientRect().top + window.pageYOffset;
        window.addEventListener('scroll', this.onWindowScroll.bind(this));

        this.updateFilterRegHandler = EventBus.register(Events.UPDATE_SUBJECTS_FILTER, ({level, grouping}) => {
            if (grouping === BrowseSubjectsCmpt.DISCIPLINE_GROUPING) {
                grouping = this.props.myCoursePlans.coursePlan.course.code.toUpperCase();
            }
            this.setFilter(level, grouping)
        })
    }

    componentWillUnmount(): void {
        window.removeEventListener('scroll', this.onWindowScroll.bind(this));
        this.updateFilterRegHandler.done();
    }

    onWindowScroll() {
        let {headerWidth, isHeaderSticky} = this.state;
        const boundingClientRect = this.headerDomRef.getBoundingClientRect();

        if (window.pageYOffset <= this.filterDomOffsetTop && isHeaderSticky) {
            headerWidth = null;
            isHeaderSticky = false;
        } else if (boundingClientRect.top < 0 && !isHeaderSticky) {
            this.filterDomOffsetTop = this.headerDomRef.offsetTop;
            headerWidth = `${boundingClientRect.width + 1}px`;
            isHeaderSticky = true;
        }

        this.setState({isHeaderSticky, headerWidth});
    }

    setFilter(level: number, grouping: string) {
        if (this.domRef.getBoundingClientRect().top > 30) {
            window.scrollTo(0, this.domRef.getBoundingClientRect().top);
        }

        this.setState({level: level || 0, grouping: grouping, search: ''});
    }

    setLevel(level: number) {
        this.setState({level});
    }

    setGrouping(grouping: string) {
        this.setState({grouping});
    }

    setSearch(search: string) {
        this.setState({level: 0, grouping: null, search});
    }

    render() {
        const {level, grouping, isHeaderSticky, headerWidth, search} = this.state;
        const courseCode = this.props.myCoursePlans.coursePlan.course.code.toUpperCase();

        return (
            <div className={`browse-subjects`} ref={(ref) => this.domRef = ref}>
                <div className={`browse-subjects__header ${isHeaderSticky ? 'browse-subjects--sticky' : false}`}
                     style={{width: headerWidth}}
                     ref={(ref) => this.headerDomRef = ref}>
                    <div className="browse-subjects__header-text">
                        SUBJECTS
                    </div>
                    {
                        <SubjectFilter courseCode={courseCode}
                                    grouping={grouping}
                                    level={level}
                                    setGrouping={this.setGrouping}
                                    setLevel={this.setLevel}/>
                    }
                    <SearchBoxCmpt onSearch={this.setSearch} value={search}/>
                </div>
                <AvailableSubjectsCmpt level={this.state.level} group={this.state.grouping} search={this.state.search}/>
            </div>
        );
    }
};
