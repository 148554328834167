import './available-major.cmpt.scss';
import {Outcome} from "../../outcome";
import * as React from "react";
import {ClickableCmpt} from "../../../shared/components/clickable/clickable.cmpt";
import ReactTooltip from 'react-tooltip';
import {Given} from "../../../course-plan/course-plan";

function BrickCmpt({major, onMajorClicked, selectMajor}) {

    const specialisations = major.specialisations || [];

    return (
        <React.Fragment>
            <ClickableCmpt content={major.info.name} onclick={() => onMajorClicked(major)} key={1}/>
            {specialisations.length === 0
                ? <ClickableCmpt content={<div className="material-icons dp48">add</div>} key={2} onclick={() => selectMajor(major)}/>
                : null}
        </React.Fragment>
    );
}


export interface AvailableMajorCmptProps {
    major: Outcome;
    selectMajor: (major: Outcome) => void;
    onMajorClicked: (target: HTMLElement, major: Outcome) => void;
}

export function AvailableMajorCmpt({major, selectMajor, onMajorClicked}: AvailableMajorCmptProps) {

    const givenOutcome = Given.outcome(major);
    const selectedClassName = givenOutcome.isSelected() ? 'available-major--selected' : '';
    const favouritedClassName = givenOutcome.isFavourited() ? 'available-major--favourited' : '';
    const tooltipText = givenOutcome.getAvailbilityAsMessage();

    let domRef: Element;
    return (
        <div className={`available-major ${selectedClassName} ${favouritedClassName}`}
             data-availability-score={major.state.availability_score}
             ref={(ref) => domRef = ref}>
            <div className="available-major__wrapper"
                 data-tip={tooltipText}
                 onMouseOver={(e) => ReactTooltip.show(e.currentTarget)}
                 onMouseOut={(e) => ReactTooltip.hide(e.currentTarget)}>
                <BrickCmpt major={major}
                           selectMajor={selectMajor}
                           onMajorClicked={(major) => onMajorClicked(domRef as HTMLInputElement, major)}/>
            </div>
        </div>
    );

}
