export const defaultCategories = [
    {codes: ['ABPL'], name: 'Architecture & the Built Environment'},
    {codes: ['ACCT'], name: 'Accounting'},
    {codes: ['ACTL'], name: 'Actuarial'},
    {codes: ['AGRI'], name: 'Agricultural'},
    {codes: ['AHIS'], name: 'Art History'},
    {codes: ['AIND'], name: 'Indigenous Arts'},
    {codes: ['ANAT'], name: 'Anatomy'},
    {codes: ['ANCW'], name: 'Ancient Worlds'},
    {codes: ['ANSC'], name: 'Animal Studies'},
    {codes: ['ANTH'], name: 'Anthropology'},
    {codes: ['ARBC'], name: 'Arabic Language & Literature'},
    {codes: ['ARCH'], name: 'Architecture'},
    {codes: ['ASIA'], name: 'Asian Society'},
    {codes: ['ATOC'], name: 'Atmosphere & Oceans'},
    {codes: ['BIOL'], name: 'Biology'},
    {codes: ['BLAW'], name: 'Business Law'},
    {codes: ['CCDP'], name: 'Electronic Games'},
    {codes: ['CHEM'], name: 'Chemistry'},
    {codes: ['CHIN'], name: 'Chinese Language & Literature'},
    {codes: ['CLAS'], name: 'Classic Languages & Literature'},
    {codes: ['COMP'], name: 'Computing & Programming'},
    {codes: ['CONS'], name: 'Construction'},
    {codes: ['CRIM'], name: 'Criminology'},
    {codes: ['CULS'], name: 'Media & Culture'},
    {codes: ['CWRI'], name: 'Creative Writing'},
    {codes: ['DASC'], name: 'Animal Management'},
    {codes: ['DENT'], name: 'Dentistry'},
    {codes: ['DEVT'], name: 'Developing World'},
    {codes: ['DNCE'], name: 'Dance'},
    {codes: ['DRAM'], name: 'Drama'},
    {codes: ['ECON'], name: 'Economics'},
    {codes: ['ECOL'], name: 'Ecology'},
    {codes: ['EDUC'], name: 'Education'},
    {codes: ['ENGL'], name: 'English Language & Literature'},
    {codes: ['ENGR'], name: 'Engineering'},
    {codes: ['ENST'], name: 'Environment & Story'},
    {codes: ['ENVS'], name: 'Environment'},
    {codes: ['ERTH'], name: 'Earth Sciences'},
    {codes: ['ESLA'], name: 'English as a Second Language'},
    {codes: ['EURO'], name: 'Eurovision'},
    {codes: ['EVSC'], name: 'Global Environment'},
    {codes: ['FINA'], name: 'Fine Arts'},
    {codes: ['FLTV'], name: 'Film & Television'},
    {codes: ['FNCE'], name: 'Finance'},
    {codes: ['FOOD'], name: 'Food & Beverage'},
    {codes: ['FREN'], name: 'French Language & Literature'},
    {codes: ['GDES'], name: 'Graphic Design'},
    {codes: ['GEND'], name: 'Gender & Culture'},
    {codes: ['GENE'], name: 'Genetics'},
    {codes: ['GEOG'], name: 'Geography'},
    {codes: ['GEOM'], name: 'Spatial Systems'},
    {codes: ['GERM'], name: 'German Language & Literature'},
    {codes: ['HEBR'], name: 'Hebrew Language & Literature'},
    {codes: ['HIST'], name: 'Western History'},
    {codes: ['HORT'], name: 'Horticulture'},
    {codes: ['HPSC'], name: 'History of Science'},
    {codes: ['INDO'], name: 'Indonesian Language & Literature'},
    {codes: ['INFO'], name: 'Information Design'},
    {codes: ['INTS'], name: 'International Politics'},
    {codes: ['ISLM'], name: 'Islamic Culture'},
    {codes: ['ISYS'], name: 'Information Systems'},
    {codes: ['ITAL'], name: 'Italian Language & Literature'},
    {codes: ['JAPN'], name: 'Japanese Language & Literature'},
    {codes: ['KORE'], name: 'Korean Language & Literature'},
    {codes: ['LARC'], name: 'Natural History'},
    {codes: ['LAWS'], name: 'Law'},
    {codes: ['LING'], name: 'Human Language'},
    {codes: ['MAST'], name: 'Maths & Statistics'},
    {codes: ['MECM'], name: 'Media & Communication'},
    {codes: ['MIIM'], name: 'Microbiology & Immunology'},
    {codes: ['MGMT'], name: 'Management'},
    {codes: ['MKTG'], name: 'Marketing'},
    {codes: ['MULT'], name: 'Multiculturalism'},
    {codes: ['MUSI'], name: 'Music'},
    {codes: ['NRMT'], name: 'Natural Resource Management'},
    {codes: ['ORAL'], name: 'Oral Health'},
    {codes: ['PADM'], name: 'Consultants & Government'},
    {codes: ['PHIL'], name: 'Philosophy'},
    {codes: ['PHYC'], name: 'Physics'},
    {codes: ['PLAN'], name: 'Urban Planning'},
    {codes: ['POLS'], name: 'Politics'},
    {codes: ['PPMN'], name: 'Policy Management'},
    {codes: ['PROP'], name: 'Land & Property'},
    {codes: ['PSYC'], name: 'Psychology'},
    {codes: ['RUSS'], name: 'Russian Language & Literature'},
    {codes: ['SCIE'], name: 'Science'},
    {codes: ['SCRN'], name: 'Screen Studies'},
    {codes: ['SOCI'], name: 'Sociology'},
    {codes: ['SOLS'], name: 'Society Law'},
    {codes: ['SOTH'], name: 'Social Theory'},
    {codes: ['SPAN'], name: 'Spanish Language & Literature'},
    {codes: ['SWEN'], name: 'Software Design'},
    {codes: ['THTR'], name: 'Theatre'},
    {codes: ['UNIB'], name: 'University Breadth'},
    {codes: ['VETS'], name: 'Veterinarian Systems'},
    {codes: ['ZOOL'], name: 'Zoology'},
    {codes: ['ARTS'], name: 'Arts'},
    {codes: ['BCMB'], name: 'Biochemistry & Molecular Biology'},
    {codes: ['BIOM'], name: 'Biomedicine'},
    {codes: ['BMEN'], name: 'Biomedical'},
    {codes: ['BOTA'], name: 'Botany'},
    {codes: ['BTCH'], name: 'Biotechnology'},
    {codes: ['CEDB'], name: 'Cell & Developmental Biology'},
    {codes: ['CHEN'], name: 'Chemical Engineering'},
    {codes: ['CVEN'], name: 'Civil Engineering'},
    {codes: ['ECOM'], name: 'Econometrics'},
    {codes: ['ELEN'], name: 'Electrical Networks'},
    {codes: ['ENEN'], name: 'Environmental Engineering'},
    {codes: ['FRST'], name: 'Forest Management'},
    {codes: ['GEOL'], name: 'Geology'},
    {codes: ['IBUS'], name: 'International Business'},
    {codes: ['JEWI'], name: 'Jewish Studies'},
    {codes: ['MCEN'], name: 'Mechatronics'},
    {codes: ['MUST'], name: 'Music Theatre'},
    {codes: ['NEUR'], name: 'Neuroscience'},
    {codes: ['OPTO'], name: 'Optometry'},
    {codes: ['PATH'], name: 'Pathology'},
    {codes: ['PHRM'], name: 'Pharmacology'},
    {codes: ['PHYS'], name: 'Physiology'},
    {codes: ['POPH'], name: 'Population Health'},
    {codes: ['SINF'], name: 'Science Informatics'},
    {codes: ['DPSS'], name: 'Design & Production'},
    {codes: ['BUSA'], name: 'Business'},
]