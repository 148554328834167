import './collapsible-list-item.cmpt.scss';

import * as React from "react";

export interface CollapsibleListItemCmptProps {
    children: JSX.Element;
}

export class CollapsibleListItemCmpt extends React.Component<CollapsibleListItemCmptProps> {

    render() {
        const {children} = this.props;
        return (
            <div className="collapsible-list-item" key={children.key}>
                {children}
            </div>
        );
    }
}