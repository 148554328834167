import {ArrayUtil} from "../shared/util";
import {Outcome} from "../outcomes/outcome";
import {Type as Suggestion_Type} from "../course-plan/suggestions";
import {Status} from "./../status";
import {Availability, AvailabilityUtil} from "./availability";


export interface Subject {
    readonly code: string;
    readonly info: Info;
    state: State;
    userPref: UserPref;
}

export interface VCESubject {
    name: string;
    score: number;
}


export interface Info {
    name: string;
    points: number;
    year: number;
    level: number;
    overview_text: string;
    requisites_html: string;
    categories: Array<string>;
    handbook_url?: string;
    coreFor: Array<string>;
    debug?: any;
    availability: Array<string>;
    intensives: any; // this is a JS array map, which cannot be represented in typescript??
}

export interface State {
    type: string; //'breadth|science'
    status: Status //'met|open|unmet',
    info: string;
    warning: string;
    error: string; //'Two requisites are not met (MATH10004 OR MATH10006)',
    msgs: Array<string | Msg>;
}

export interface UserPref {
    year: number;
    semester: string;
    selected: boolean;
    favourite: boolean;
}

export class Msg {
    heading: string;
    msgs: Array<string>;
}

export interface ByCategory {
    category: string;
    subjects: Array<Subject>;
    uuid: string;
    type: Suggestion_Type;
}

export enum Category {
    compulsory = 'compulsory',
    elective = 'elective',
    breadth = 'breadth'
}

export class SubjectUtil {

    private availabilityUtil: AvailabilityUtil;

    constructor(private subject: Subject) {
        this.availabilityUtil = new AvailabilityUtil(this.subject);
    }

    isSelected(): boolean {
        return (!!this.subject.userPref && !!this.subject.userPref.selected) ? true : false;
    }

    isFavourited(): boolean {
        return (!!this.subject.userPref && !!this.subject.userPref.favourite) ? true : false;
    }

    isCompulsory(outcomes: Array<Outcome>) {
        const subject = this.subject;
        const isCompulsory = outcomes.filter((major) => {
            const isCompulsoryForOutcome = subject.info.coreFor.indexOf(major.code) !== -1;

            let isCompulsoryForSpecialisation = false;
            if (!!major.userPref.specialisation.selected) {
                isCompulsoryForSpecialisation = subject.info.coreFor.indexOf(major.userPref.specialisation.selected.code) !== -1;
            }

            return isCompulsoryForOutcome || isCompulsoryForSpecialisation;
        }).length !== 0;

        return isCompulsory;
    }

    isBreadth(breadthId: string, outcomes: Array<Outcome>): boolean {
        if (this.isCompulsory(outcomes)) {
            return false;
        }
        return (!!this.subject.info.categories) ? this.subject.info.categories.indexOf(breadthId) !== -1 : false;
    }

    getAvailabilityNames() {
        return this.availabilityUtil.getAvailabilityNames();
    }

    getAvailabilitySortValue() {
        return this.availabilityUtil.getAvailabilitySortValue();
    }

    getMappedAvailability(): any {
        return this.availabilityUtil.getMappedAvailability();
    }

    isYearLong() {
        return this.availabilityUtil.isYearLong();
    }

    /* instanbul ignore next */
    cleanseEligibilityAndRequirements() {
        const req = this.subject.info.requisites_html.replace(new RegExp('<p/>', 'g'), '')
            .replace(new RegExp('<p> </p>', 'g'), '')
            .replace(new RegExp('<p></p>', 'g'), '')
            .replace(new RegExp('<p>OR</p>', 'g'), '<p class="OR">OR</p>')
            .replace(new RegExp('<p>both:</p>', 'g'), '<p class="BOTH">both:</p>')
            .replace(new RegExp('<p>\\(', 'g'), '<p class="INDENT">(')
            .replace(new RegExp('<ul>', 'g'), '<ul class="INDENT">')
            .replace(new RegExp('<ul><li><table', 'g'), '<table')
            .replace(new RegExp('</table></li></ul>', 'g'), '</table>')
            .replace(new RegExp('</table></li>', 'g'), '</table>');
        return req;
    }

    getSubjectCategory(breadthId: string, selectedMajors: Array<Outcome>) {
        let catregory = Category.elective;
        if (this.isBreadth(breadthId, selectedMajors)) {
            catregory = Category.breadth;
        }

        if (!!selectedMajors && !!this.isCompulsory(selectedMajors)) {
            catregory = Category.compulsory;
        }
        return catregory;
    }

    isScheduleValid(availability:string) {

        let validSemester = true;

        // check subject is in scheduled semester (or term)
        const schedule: {[scheduled: string]: Array<Availability>} = this.getMappedAvailability()[availability];
        if (!schedule) {
            validSemester = false;
        }

        return validSemester
    }

    handbookUrl() {
        return `https://handbook.unimelb.edu.au/subjects/${this.subject.code}`
    }
}

export class SubjectsUtil {
    constructor(private subjects: Array<Subject>) {
    }

    areAllSelected() {
        return this.subjects.filter((s) => !new SubjectUtil(s).isSelected()).length === 0;
    }

    filterByCodes(codes: Array<string>) {
        return ArrayUtil.filterBy(this.subjects, 'code', codes);
    }

    findByCodes(codes: Array<string>) {
        return ArrayUtil.findBy(this.subjects, 'code', codes);
    }

    findByLevels(levels: Array<number>) {
        return ArrayUtil.findBy(this.subjects, 'info.level', levels);
    }

    excludeBreadth(breadthId: string, outcomes: Array<Outcome>) {
        return ArrayUtil.filterWith(this.subjects, (subject) => !new SubjectUtil(subject).isBreadth(breadthId, outcomes));
    }

    findByCourseYears(years: Array<number>) {
        return ArrayUtil.findBy(this.findSelected(), 'userPref.year', years);
    }

    groupByLevels() {
        return ArrayUtil.groupBy(this.subjects, 'info.level');
    }

    findByCourseSemester(year: number, semester: string) {
        const subjects = ArrayUtil.findBy(this.findSelected(), 'userPref.year', [year]);
        return ArrayUtil.findBy(subjects, 'userPref.semester', [semester]);
    }

    findSelected() {
        return ArrayUtil.findBy(this.subjects, 'userPref.selected', [true]);
    }

    findFavourite() {
        return ArrayUtil.findBy(this.subjects, 'userPref.favourite', [true]);
    }

    getTotalPoints() {
        return ArrayUtil.sum(this.subjects, 'info.points');
    }

    filterBy(filterFn: (subject: Subject) => boolean) {
        return ArrayUtil.filterWith(this.subjects, filterFn);
    }

    /**
     * This function might have to go after the suggestion
     * engined is implemented.
     */

    /* istanbul ignore next */
    groupByStudyArea(): Map<string, Array<Subject>> {
        const byStudyArea: Map<string, Array<Subject>> = new Map();

        this.subjects.forEach(subject => {
            const subjectGroupCode = subject.code.substring(0, 4);
            let subjects = byStudyArea.get(subjectGroupCode);
            if (!subjects) {
                subjects = [];
                byStudyArea.set(subjectGroupCode, subjects);
            }
            subjects.push(subject);
        });
        return byStudyArea;
    }

    getAdded(newList:Array<Subject>):Array<string> {
        const curList = this.subjects;
        if (newList.length > curList.length) {
            const newCodes = newList.map(s => s.code);
            const curCodes = curList.map(s => s.code);
            return newCodes.filter((c) => curCodes.indexOf(c) === -1);
        }
        return [];
    }
}
