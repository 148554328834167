import {EventBus, Events} from "./event-bus.service";
import {CoursePlan} from "../../course-plan/course-plan";

export class MyCoursePlansService {

    constructor(private api_url: string) {
    }

    fetchSubjects(courseCode: string) {
        EventBus.fire({name: Events.APP_LOADING_DATA, payload: true});
        return fetch(`${this.api_url}/subjects?course=${encodeURIComponent(courseCode)}`,
            {
                method: "GET", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, cors, *same-origin
            })
            .then((resp: Response) => {
                EventBus.fire({name: Events.APP_LOADING_DATA, payload: false});
                return resp.json();
            })
            .catch((error) => {
                EventBus.fire({name: Events.APP_LOADING_DATA, payload: false});
                console.error('Unable to retrieve subjects.', error);
            });
    }

    fetchOutcomes(courseCode: string) {
        EventBus.fire({name: Events.APP_LOADING_DATA, payload: true});
        return fetch(`${this.api_url}/outcomes?course=${encodeURIComponent(courseCode)}`,
            {
                method: "GET", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, cors, *same-origin
            })
            .then((resp: Response) => {
                EventBus.fire({name: Events.APP_LOADING_DATA, payload: false});
                return resp.json();
            })
            .catch((error) => {
                EventBus.fire({name: Events.APP_LOADING_DATA, payload: false});
                console.error('Unable to retrieve subjects.', error);
            });
    }

    fetchSubjectDetails(subjectCode: string) {

        EventBus.fire({name: Events.SERVER_ERROR, payload: false});

        return fetch(`${this.api_url}/subjects/${subjectCode}`,
            {
                method: "GET", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, cors, *same-origin
            })
            .then((resp: Response) => {
                return resp.json();
            })
            .catch(error => {
                EventBus.fire({name: Events.SERVER_ERROR, payload: true});
                console.error('Unable to retrieve subject details.', error);
            });
    }

    validate(coursePlan: CoursePlan) {

        EventBus.fire({name: Events.SERVER_ERROR, payload: false});

        const subjects = coursePlan.course.subjects.map((subject) => {
            subject.info.overview_text = null;
            return subject;
        });

        return fetch(`${this.api_url}/validate`,
            {
                method: "POST",
                mode: "cors",
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify({simulate: false, plan: {
                    course: coursePlan.course, subjects: subjects, outcomes: coursePlan.course.outcomes, vce_targets: coursePlan.vceSubjects
                }})
            })
            .then((resp: Response) => {
                return resp.json();
            })
            .catch((error: Response) => {
                EventBus.fire({name: Events.SERVER_ERROR, payload: true});
                return error;
            });
    }

    simulate(coursePlan: CoursePlan) {

        EventBus.fire({name: Events.SERVER_ERROR, payload: false});

        const subjects = coursePlan.course.subjects.map((subject) => {
            subject.info.overview_text = null;
            return subject;
        });

        return fetch(`${this.api_url}/simulate`,
            {
                method: "POST",
                mode: "cors",
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify({plan: {
                    course: coursePlan.course, subjects: subjects, outcomes: coursePlan.course.outcomes, vce_targets: coursePlan.vceSubjects
                }})
            })
            .then((resp: Response) => {
                return resp.json();
            })
            .catch((error: Response) => {
                EventBus.fire({name: Events.SERVER_ERROR, payload: true});
                return error;
            });
    }
}
