import './favourited-subjects.cmpt.scss';

import * as React from "react";
import {inject, observer} from "mobx-react";
import {SelectedSubjectCmpt} from "../selected-subject/selected-subject.cmpt";
import {Droppable} from "react-beautiful-dnd";
import {SelectedSubjectsCmpt} from "../selected-subjects.cmpt";
import {FeatureSupportStore} from "../../../shared/stores/feature-support.store";
import {Subject} from "../../../browse-subjects/subject";
import {ClickableCmpt} from "../../../shared/components/clickable/clickable.cmpt";
import {ArrayUtil, StringUtil} from "../../../shared/util";
import {EventBus, EventRegistrationHandle, Events} from "../../../shared/services/event-bus.service";
import {MyCoursePlansStore} from "../../../shared/stores/my-course-plans.store";
import {UiStateStore} from "../../../shared/stores/ui/ui-state.store";
import {Given} from "../../course-plan";


export interface FavouriteSubjectsCmptProps {
    myCoursePlans?: MyCoursePlansStore;
    featureSupport?: FeatureSupportStore;
    uiState?: UiStateStore;
}

interface FavouritedSubjectsCmptState {
    isDragOver: boolean;
}

@inject('myCoursePlans', 'featureSupport', 'uiState')
@observer
export class FavouritedSubjectsCmpt extends React.Component<FavouriteSubjectsCmptProps, FavouritedSubjectsCmptState> {

    private dragEnterCounter: number;
    private reorderSubjectEventRegHandler: EventRegistrationHandle;
    private favouritedSubjects:Array<Subject> = [];

    constructor(props: FavouriteSubjectsCmptProps) {
        super(props);
        this.reorderSubjectEventRegHandler = EventBus.register(Events.REORDERING_SELECTED_SUBJECT, () => {
            this.onHtmlHeaderDragExit();
        });

        this.state = {isDragOver: false};
    }

    unfavouriteSubject(subject: Subject) {
        this.props.myCoursePlans.unFavouriteSubject(subject);
    }

    unfavouriteAll(favouritedSubjects: Array<Subject>) {
        this.props.myCoursePlans.unFavouriteSubjects(favouritedSubjects);
    }

    onHtmlHeaderDragOver(event: DragEvent) {
        event.preventDefault();
        this.dragEnterCounter++;
        this.setState({isDragOver: true});
    }

    onHtmlHeaderDragLeave(event: DragEvent) {
        event.preventDefault();
        this.dragEnterCounter--;
        if (this.dragEnterCounter <= 0) {
            this.dragEnterCounter = 0;
            this.setState({isDragOver: false});
        }
    }

    onHtmlHeaderDragExit() {
        this.dragEnterCounter = 0;
        this.setState({isDragOver: false});
    }

    onHtmlDrop() {
        let {uiState, myCoursePlans} = this.props;
        myCoursePlans.moveSubjectToFavourite(uiState.dragData.dragSubject.code);

        uiState.dragData = null;
        this.setState({isDragOver: false});
        EventBus.fire({name: Events.REORDERING_SELECTED_SUBJECT, payload: false});
    }

    onHtmlDragOver(event: DragEvent) {
        event.preventDefault();
        // event.stopPropagation();
        event.dataTransfer.dropEffect = 'copy';
        return false;
    }

    onHtmlDragEnter(event: DragEvent) {
        event.preventDefault();
        // event.stopPropagation();
        event.dataTransfer.dropEffect = 'copy';
        return false;
    }

    render() {
        const {isDragOver} = this.state;
        const {myCoursePlans, featureSupport} = this.props;
        const browserName = featureSupport.browserName;
        const isHtmlDnD = featureSupport.htmlDnD;

        const sortedFavouritedSubjects = ArrayUtil.sortBy(myCoursePlans.favouritedSubjects, 'code');
        const newSubjectCodes = Given.subjects(this.favouritedSubjects).getAdded(sortedFavouritedSubjects);
        const html5HeaderDnD = isHtmlDnD ? {
            onDragEnter: this.onHtmlHeaderDragOver.bind(this),
            onDragLeave: (browserName === 'Firefox' ? () => {} : this.onHtmlHeaderDragLeave.bind(this)),
            onDragExit: (browserName === 'Firefox' ? this.onHtmlHeaderDragExit.bind(this) : () => {})
        } : {};

        const html5Dnd = isHtmlDnD ? {
            onDrop: this.onHtmlDrop.bind(this),
            onDragOver: this.onHtmlDragOver.bind(this),
            onDragEnter: this.onHtmlDragEnter.bind(this),
        } : {};

        let className = `favourited-subjects
                         ${sortedFavouritedSubjects.length === 0 ? 'favourited-subjects--no-subjects' : ''}
                         ${isDragOver || !isHtmlDnD? 'favourited-subjects--drag-over' : ''}`;

        this.favouritedSubjects = sortedFavouritedSubjects;

        return (
            <div className={StringUtil.ensureOneLine(className)}
                 {...html5HeaderDnD}>
                <div className="favourited-subjects__header">
                    <span className="favourited-subjects__header__text">Favourite Subjects</span>
                    <span className="favourited-subjects__header__clear">
                        <ClickableCmpt
                            content={
                                <div className="favourited-subjects__header__clear__wrapper">
                                    <div className="favourited-subjects__header__clear__text">CLEAR</div>
                                    <div className="material-icons dp48">delete</div>
                                </div>
                            }
                            onclick={this.unfavouriteAll.bind(this, sortedFavouritedSubjects)}/>
                    </span>
                </div>
                <Droppable droppableId="favourite"
                           type={SelectedSubjectsCmpt.DRAG_AND_DROP_TYPE}
                           isDropDisabled={isHtmlDnD}
                           ignoreContainerClipping={true}>
                    {
                        (provided, snapshot) => {
                            const draggableHoverStyle = snapshot.isDraggingOver ? 'favourited-subjects--subjects--draggable-over' : '';
                            return (
                                <div className={`favourited-subjects__subjects ${draggableHoverStyle}`}
                                     ref={provided.innerRef}
                                     {...provided.droppableProps}
                                     {...html5Dnd}>
                                     {
                                         sortedFavouritedSubjects.map((favouritedSubject, index) =>
                                            <SelectedSubjectCmpt selectedSubject={favouritedSubject}
                                                                 selectionClass={'favourite'}
                                                                 index={index} key={index}
                                                                 onUnSelect={this.unfavouriteSubject.bind(this)}
                                                                 semesterRequirement={null}
                                                                 yearRequirement={null}
                                                                 newSubjectCodes={newSubjectCodes}/>
                                         )
                                     }
                                     {provided.placeholder}
                                </div>
                            )
                        }
                    }
                </Droppable>
            </div>
        );
    }
}