import './tools.cmpt.scss';
import * as React from "react";
import {ClickableCmpt} from "../shared/components/clickable/clickable.cmpt";
import {Given} from "../shared/html-util";
import {RenderingMode} from "../../app";


interface ToolsCmptState {
    printPressed: boolean;
    copyPressed: boolean;
    sharePressed: boolean;
}

export class ToolsCmpt extends React.Component<{}, ToolsCmptState> {

    constructor(props: {}, context: any) {
        super(props, context);

        this.state = {printPressed: false, copyPressed: false, sharePressed: false};
    }

    copyUrl() {
        // temporary create an input element inject into dom
        let tempInput:HTMLInputElement = document.createElement('input');
        try {
            tempInput.style.position = 'absolute';
            tempInput.style.top = '0px';
            tempInput.style.left = '0px';
            tempInput.style.marginTop = '-100px';
            tempInput.style.marginLeft = '-100px';
            document.body.appendChild(tempInput);

            // sets url into the input field and copy to clipboard
            tempInput.value = window.location.href;
            tempInput.select();
            window.document.execCommand("copy");
        } finally {
            // remove from dom when done
            document.body.removeChild(tempInput);
        }
    }

    iconClicking(iconPressed: any) {
        this.setState(iconPressed);

        setTimeout(() => {
            this.setState({printPressed: false, copyPressed: false, sharePressed: false});
        }, 150);
    }

    printCoursePlan() {
        Given.window().putQueryParam('mode', RenderingMode.print);
        window.open(window.location.href, '_blank');
        Given.window().putQueryParam('mode', '');
    }

    updateMailtoHref(event: Event) {
        (event.currentTarget as HTMLHRElement)
            .setAttribute(
                'href',
                `mailto:?subject=My Course Plan&body=${window.location.href.replace('&', '%26')}`
            );
        return true;
    }

    render() {
        const {printPressed, copyPressed, sharePressed} = this.state;
        return (
            <div className="tools">
                <div className="tools__icons">
                    <ClickableCmpt content={
                        <div className={`tools__icons__icon ${printPressed ? 'tools__icons__icon--pressed' : ''}`}
                             onMouseDown={() => this.iconClicking({printPressed: true})}>
                            <div className="material-icons dp48">print</div>
                            <div className="tools__tools__icons__icon-text">print</div>
                        </div>
                    } onclick={this.printCoursePlan.bind(this)}/>
                    <ClickableCmpt content={
                        <div className={`tools__icons__icon ${copyPressed ? 'tools__icons__icon--pressed' : ''}`}
                             onMouseDown={() => this.iconClicking({copyPressed: true})}>
                            <div className="material-icons dp48">link</div>
                            <div className="tools__tools__icons__icon-text">copy link</div>
                        </div>
                    } onclick={this.copyUrl.bind(this)}/>

                    <a href={`mailto:?subject=My Course Plan&body=${window.location.href.replace('&', '%26')}`} onClick={this.updateMailtoHref.bind(this)}>
                        <div className={`tools__icons__icon ${sharePressed ? 'tools__icons__icon--pressed' : ''}`}
                             onMouseDown={() => this.iconClicking({sharePressed: true})}>
                            <div className="material-icons dp48">share</div>
                            <div className="tools__tools__icons__icon-text">share</div>
                        </div>
                    </a>
                </div>
            </div>
        );
    }
}
