export const MajorImages = {
    'b-arts-major-1': require('./assets/b-arts-major-1_LR.jpg'),
    'b-arts-major-3': require('./assets/b-arts-major-3_LR.jpg'),
    'b-arts-major-5': require('./assets/b-arts-major-5_LR.jpg'),
    'b-arts-major-9': require('./assets/b-arts-major-9_LR.jpg'),
    'b-arts-major-10': require('./assets/b-arts-major-10_LR.jpg'),
    'b-sci-major-1': require('./assets/b-sci-major-1_LR.jpg'),
    'b-sci-major-2': require('./assets/b-sci-major-2_LR.jpg'),
    'b-sci-major-3': require('./assets/b-sci-major-3_LR.jpg'),
    'b-sci-major-4': require('./assets/b-sci-major-4_LR.jpg'),
    'b-sci-major-5': require('./assets/b-sci-major-5_LR.jpg'),
    'b-sci-major-6': require('./assets/b-sci-major-6_LR.jpg'),
    'b-sci-major-7': require('./assets/b-sci-major-7_LR.jpg'),
    'b-sci-major-8': require('./assets/b-sci-major-8_LR.jpg'),
    'b-sci-major-9': require('./assets/b-sci-major-9_LR.jpg'),
    'b-sci-major-10': require('./assets/b-sci-major-10_LR.jpg'),
    'b-sci-major-11': require('./assets/b-sci-major-11_LR.jpg'),
    'b-sci-major-12': require('./assets/b-sci-major-12_LR.jpg'),
    'b-sci-major-13': require('./assets/b-sci-major-13_LR.jpg'),
    'b-sci-major-14': require('./assets/b-sci-major-14_LR.jpg'),
    'b-sci-major-15': require('./assets/b-sci-major-15_LR.jpg'),
    'b-sci-major-16': require('./assets/b-sci-major-16_LR.jpg'),
    'b-sci-major-17': require('./assets/b-sci-major-17_LR.jpg'),
    'b-sci-major-18': require('./assets/b-sci-major-18_LR.jpg'),
    'b-sci-major-19': require('./assets/b-sci-major-19_LR.jpg'),
    'b-sci-major-20': require('./assets/b-sci-major-20_LR.jpg'),
    'b-sci-major-21': require('./assets/b-sci-major-21_LR.jpg'),
    'b-sci-major-22': require('./assets/b-sci-major-22_LR.jpg'),
    'b-sci-major-23': require('./assets/b-sci-major-23_LR.jpg'),
    'b-sci-major-24': require('./assets/b-sci-major-24_LR.jpg'),
    'b-sci-major-25': require('./assets/b-sci-major-25_LR.jpg'),
    'b-sci-major-26': require('./assets/b-sci-major-26_LR.jpg'),
    'b-sci-major-27': require('./assets/b-sci-major-27_LR.jpg'),
    'b-sci-major-28': require('./assets/b-sci-major-28_LR.jpg'),
    'b-sci-major-29': require('./assets/b-sci-major-29_LR.jpg'),
    'b-sci-major-30': require('./assets/b-sci-major-30_LR.jpg'),
    'b-sci-major-31': require('./assets/b-sci-major-31_LR.jpg'),
    'b-sci-major-32': require('./assets/b-sci-major-32_LR.jpg'),
    'b-sci-major-33': require('./assets/b-sci-major-33_LR.jpg'),
    'b-sci-major-34': require('./assets/b-sci-major-34_LR.jpg'),
    'b-sci-major-35': require('./assets/b-sci-major-35_LR.jpg'),
    'b-sci-major-36': require('./assets/b-sci-major-36_LR.jpg'),
    'b-sci-major-37': require('./assets/b-sci-major-37_LR.jpg'),
    'b-sci-major-38': require('./assets/b-sci-major-38_LR.jpg'),
    'b-sci-major-39': require('./assets/b-sci-major-39_LR.jpg'),
    'b-sci-major-40': require('./assets/b-sci-major-40_LR.jpg'),
    'b-sci-major-41': require('./assets/b-sci-major-41_LR.jpg'),
    'b-sci-major-42': require('./assets/b-sci-major-42_LR.jpg'),
    'b-sci-major-43': require('./assets/b-sci-major-43_LR.jpg'),
    'bcom-accounting': require('./assets/bcom-accounting_LR.jpg'),
    'bcom-actuarial-studies': require('./assets/bcom-actuarial-studies_LR.jpg'),
    'bcom-business': require('./assets/bcom-business_LR.jpg'),
    'bcom-economics': require('./assets/bcom-economics_LR.jpg'),
    'bcom-finance': require('./assets/bcom-finance_LR.jpg'),
    'bcom-management': require('./assets/bcom-management_LR.jpg'),
    'bcom-marketing': require('./assets/bcom-marketing_LR.jpg'),
    'Microeconomics': require('./assets/Microeconomics_LR.jpg'),
};