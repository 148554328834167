import './input-field.cmpt.scss'
import * as React from "react";
import {Given} from "../../html-util";

export interface InputFieldCmptProps {
    type?: string;
    value?: string|number;
    maxLength?: number;
    validate?: (value: string) => boolean;
}

export class InputFieldCmpt extends React.Component<InputFieldCmptProps> {

    private domRef: HTMLElement;
    private inputDomRef: HTMLInputElement;
    private readonly validate: (value: string) => boolean;
    private value: string = "";

    constructor(props: InputFieldCmptProps) {
        super(props);

        this.validate = this.props.validate;
        if (!this.validate) {
            this.validate = () => true;
        }
    }

    onChange() {

        Given.anElement(this.domRef).removeClass('input-field--error');

        const newValue = this.inputDomRef.value;
        const isValid = this.props.validate(newValue);
        if (isValid) {
            this.value = newValue;
        } else {
            Given.anElement(this.domRef).addClass('input-field--error');
        }
        return isValid;
    }

    getValue() {
        return this.value;
    }

    setValue(newValue: string) {
        this.value = newValue;
    }

    render() {
        const extraAttr: any = {};
        const props = this.props;
        if (!!props.maxLength) {
            extraAttr.maxLength = props.maxLength;
        }
        if(props.value === undefined) {
            extraAttr.value = '';
        }
        if(!!props.value) {
            extraAttr.value = props.value;
        }



        return (
            <div className="input-field" ref={(ref) => this.domRef = ref}>
                <input type={!props.type ? 'text' : props.type}
                       ref={(ref) => this.inputDomRef = ref}
                       onChange={this.onChange.bind(this)}
                       {...extraAttr}/>
            </div>
        );
    }
}