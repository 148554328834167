import './clickable.cmpt.scss';

import * as React from "react";


export class ClickableCmptProps {
    content: string | JSX.Element;
    context?: any;
    className?: string = '';
    onclick?: (...args: any) => void;
}

export class ClickableCmpt extends React.Component<ClickableCmptProps> {
    private static NOOP = () => {};

    render() {
        const onclick = this.props.onclick || ClickableCmpt.NOOP;
        return (
            /* eslint-disable */
            <a className={`clickable ${this.props.className}`} href="javascript:void(0);"
            /* eslint-disable */
               onClick={onclick}>
                {this.props.content}
            </a>
        );
    }
}