import * as React from "react";
import {MessageCmptProps, MessageType} from "../shared/components/message/message.cmpt";
import {Outcome} from "../outcomes/outcome";
import {EventBus, Events} from "../shared/services/event-bus.service";
import {MyCoursePlansStore} from "../shared/stores/my-course-plans.store";
import {CourseHandbookStore} from "../shared/stores/course-handbook.store";


export abstract class MyMessage {
    protected display: boolean;

    clear: boolean;
    messageProp: MessageCmptProps;

    abstract show(args?: any): boolean;
}

export class AnyMajorSelected extends MyMessage {

    constructor() {
        super();
        this.display = true;
        this.clear = false;
    }

    messageProp = {
        message: <span><b>Majors :</b> Do you know which major you would like to complete? Select or favourite it below.</span>,
        type: MessageType.info,
    };

    show({myCoursePlans}: { myCoursePlans: MyCoursePlansStore }): boolean {
        const selectedMajors = myCoursePlans.selectedMajors;
        const favouriteMajors = myCoursePlans.favouritedMajors;

        // find out any major is selected
        const anyMajorSelected = (selectedMajors.length > 0 || favouriteMajors.length > 0) ? true : false;
        this.display = !anyMajorSelected;

        return this.display && !this.clear;
    }
}

export class AnyMajorBecameUnavailable extends MyMessage {
    constructor() {
        super();
        this.display = false;
        this.clear = false;
    }

    messageProp = {
        message: <span><b>Information :</b> Based on your subject selections, some majors are no longer possible.</span>,
        type: MessageType.alert,
    };

    show({courseHandBook}: { courseHandBook: CourseHandbookStore }): boolean {
        // find out any major has became unavailable
        this.display = !!courseHandBook.outcomes.find((outcome: Outcome) => outcome.state.availability_score !== 5);
        return this.display && !this.clear;
    }
}

export class MajorHasReplaced extends MyMessage {

    private majorReplaced: { from: Outcome, to: Outcome };

    constructor() {
        super();
        this.display = false;
        this.clear = false;

        // there is no point store deregistration handler, as the object last as long as app itself.
        EventBus.register(Events.MAJOR_HAS_BEEN_REPLACED, (majorReplaced: { from: Outcome, to: Outcome }) => {
            // if any major replace happened, clear is set to false, and set display to true
            this.clear = false;
            this.display = true;
            this.majorReplaced = majorReplaced;
            this.messageProp = {
                message: (
                    <div>
                    <span><b>Majors :</b> You have replaced selected major <span style={{textDecoration: 'underline'}}>{this.majorReplaced.from.info.name}</span> with <span
                style={{textDecoration: 'underline'}}>{this.majorReplaced.to.info.name}</span>.
                    </span>
                    { this.majorReplaced.to.code.startsWith('bcom') && this.majorReplaced.to.info.name != 'Double Major' && 
                        <span><br />If you would like to complete a Double Major, select the "Double Major" option below.</span>
                    }
                    </div>
                ),
                type: MessageType.warn,
            };
        })
    }

    show({myCoursePlans}: { myCoursePlans: MyCoursePlansStore }): boolean {
        // if selectedMajors < the majorReq.selection.max, set display = false, majorReplaced = null
        const selectedMajors = myCoursePlans.selectedMajors;
        const selection = myCoursePlans.coursePlan.coursePlanReq.majorReq.selection;
        if (selectedMajors.length < selection.max) {
            this.clear = false;
            this.display = false;
            this.majorReplaced = undefined;
        }
        return this.display && !this.clear;
    }
}

export class InvalidCoursePlan extends MyMessage {
    constructor() {
        super();
        this.display = false;
        this.clear = false;
        this.messageProp = {
            message: <span><b>Invalid Plan :</b> Please correct course errors before continuing</span>,
            type: MessageType.error,
        };
    }

    show({myCoursePlans}: { myCoursePlans: MyCoursePlansStore }): boolean {
        const isValidCoursePlan = myCoursePlans.isValidCoursePlan;
        if (!!isValidCoursePlan) {
            this.clear = false;
        }
        this.display = !isValidCoursePlan;
        return this.display && !this.clear;
    }
}


export class ServerError extends MyMessage {
    constructor() {
        super();
        this.display = false;
        this.clear = false;
        this.messageProp = {
            message: <span><b>Server Error :</b> There is a problem with the server, please refresh your browser and try again. If problem persists, please contact administrator.</span>,
            type: MessageType.error,
        };

        // there is no point store deregistration handler, as the object last as long as app itself.
        EventBus.register(Events.SERVER_ERROR, (payload:boolean) => {
            // if any major replace happened, clear is set to false, and set display to true
            if(!!payload) {
                this.clear = false;
                this.display = true;
            } else {
                this.clear = false;
                this.display = false;
            }
        })
    }

    show(): boolean {
        return this.display;
    }
}















