import {ArrayUtil} from "../util";

export type EventListener = (payload?: any) => void
export type Event = { name: string, payload?: any };

export enum Events {
    // TODO: couple the payload type with the Event
    APP_READY = 'app.ready',
    SERVER_ERROR = 'app.server.return.error',
    APP_COLLAPSIBLE_SHOW = 'app.global.collapsible.show',  // app level collapsible
    APP_LOADING_DATA = 'app.loading.data',  // event for app is loading data and should block the app
    APP_DATA_LOADED = 'app.data.loaded',  // event for once all app data are loaded

    VALIDATING_COURSE_PLAN = 'app.validating.course-plan',
    SIMULATE_OUTCOMES = 'app.simulate.outcomes',
    VALIDATING_COURSE_PLAN_UPDATE_UI = 'app.validating.course-plan.update-ui',
    REORDERING_SELECTED_SUBJECT = 'app.reorder.selected-subject',
    MAJOR_HAS_BEEN_REPLACED = 'app.major.has.been.replaced',
    UPDATE_SUBJECTS_FILTER = 'app.available-subjects.filter.update'
}

export class EventBus {
    private static registry: Map<any, Array<EventListener>> = new Map<any, Array<EventListener>>();

    private static get(eventName: string): Array<EventListener> {
        let eventListeners = this.registry.get(eventName);
        if (!eventListeners) {
            eventListeners = [];
            this.registry.set(eventName, eventListeners);
        }
        return eventListeners;
    }

    static register(eventName: string, listener: EventListener): EventRegistrationHandle {
        this.get(eventName).push(listener);
        return new EventRegistrationHandle(eventName, listener);
    }

    static deregister(eventName: string, listener: EventListener) {
        ArrayUtil.remove(this.get(eventName), listener);
    }

    static fire(event: Event) {
        this.get(event.name).forEach((listener => {
            listener(event.payload);
        }));
    }

}

export class EventRegistrationHandle {

    constructor(private eventName: string,
                private listener: EventListener) {
    }

    done() {
        EventBus.deregister(this.eventName, this.listener);
    }
}