import * as React from "react";

import './course-year.cmpt.scss';
import {Given as CoursePlan_Given} from "../../course-plan"
import {YearRequirement} from "../../course-plan.completion-requirement";
import {CourseSemesterCmpt} from "./course-semester/course-semester.cmpt";
import {inject, observer} from "mobx-react/custom";
import {FeatureSupportStore} from "../../../shared/stores/feature-support.store";
import {EventBus, EventRegistrationHandle, Events} from "../../../shared/services/event-bus.service";
import {MyCoursePlansStore} from "../../../shared/stores/my-course-plans.store";
import {StringUtil} from "../../../shared/util";


function EmptyMessageCmpt() {
    return (
        <div className="course-semester__no-subject-message" key={'no-subject-message'}>
            Your course plan is currently empty.
            <br/>
            <br/>
            To get started, browse and select subjects
            <br/>
            of interest or explore outcomes.
            <div className="course-semester__no-subject-message__icons">
                <div className="course-semester__no-subject-message__icon">
                    <div className="course-semester__no-subject-message__icon-select">
                        <div className="course-semester__no-subject-message__icon-pic material-icons dp48">add_circle</div>
                        <div className="course-semester__no-subject-message__icon-description">
                            Click this icon to add to course plan
                        </div>
                    </div>
                </div>
                <div className="course-semester__no-subject-message__icon-spacer"></div>
                <div className="course-semester__no-subject-message__icon">
                    <div className="course-semester__no-subject-message__icon-favourite">
                        <div className="course-semester__no-subject-message__icon-pic material-icons dp48">favorite_border</div>
                        <div className="course-semester__no-subject-message__icon-description">
                            Click this icon to add to favourites
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export interface CourseYearProps {
    yearRequirement: YearRequirement;
    myCoursePlans?: MyCoursePlansStore
    featureSupport?: FeatureSupportStore;
}

interface CourseYearCmptState {
    isDragOver: boolean;
}

@inject('myCoursePlans', 'featureSupport')
@observer
export class CourseYearCmpt extends React.Component<CourseYearProps, CourseYearCmptState> {

    private dragEnterCounter: number = 0;
    private reorderSubjectEventRegHandler: EventRegistrationHandle;


    constructor(props: CourseYearProps) {
        super(props);
        this.reorderSubjectEventRegHandler = EventBus.register(Events.REORDERING_SELECTED_SUBJECT, () => {
            this.onHtmlDragExit();
        });

        this.state = {isDragOver: false}
    }

    onHtmlDragEnter(event: DragEvent) {
        event.preventDefault();
        this.dragEnterCounter++;
        // console.error(`enter=${this.dragEnterCounter}`);
        // console.error(`${(event.target as HTMLElement).innerHTML}`);
        this.setState({isDragOver:true})
    }

    onHtmlDragLeave(event: DragEvent) {
        event.preventDefault();
        this.dragEnterCounter--;
        // console.error(`leave=${this.dragEnterCounter}`);
        // console.error(`${(event.target as HTMLElement).innerHTML}`);
        if (this.dragEnterCounter <= 0) {
            this.dragEnterCounter = 0;
            this.setState({isDragOver: false});
        }
    }

    onHtmlDragExit() {
        this.dragEnterCounter = 0;
        //Given.anElement(this.domRef).removeClass('course-year--drag-over');
        this.setState({isDragOver: false});
    }

    render() {
        const {isDragOver} = this.state;
        const {myCoursePlans, featureSupport, yearRequirement} = this.props;
        const courseYear = yearRequirement;
        const selectedSubjects = myCoursePlans.selectedSubjects;
        const subjects = CoursePlan_Given.subjects(selectedSubjects).findByCourseYears([courseYear.year]);
        const courseSemestersEle = courseYear.semesters.map((semester, index) => <CourseSemesterCmpt yearRequirement={courseYear} semesterRequirement={semester} key={index}/>);

        const browserName = featureSupport.browserName;
        const isHtmlDnD = featureSupport.htmlDnD;
        const htmlDnD = {
            onDragEnter: this.onHtmlDragEnter.bind(this),
            onDragEnd: this.onHtmlDragExit.bind(this),
            onDragLeave: (browserName === 'Firefox' ? () => {} : this.onHtmlDragLeave.bind(this)),
            onDragExit: (browserName === 'Firefox' ? this.onHtmlDragExit.bind(this) : () => {})
        };

        const noSubjectSelectedForYear = subjects.length === 0;
        const noSubjectSelectedForCourse = selectedSubjects.length === 0;
        const isFirstYear = CoursePlan_Given.courseYear(courseYear).isFirstYear();
        if (noSubjectSelectedForCourse && isFirstYear) {
            courseSemestersEle.push(<EmptyMessageCmpt key={'empty-message'}/>);
        }

        let className = `course-year
                         ${isHtmlDnD ? '' : 'course-year--drag-over' }
                         ${isDragOver ? 'course-year--drag-over' : ''}
                         ${noSubjectSelectedForCourse ? 'course-year--no-subjects-selected-for-course' : ''}
                         ${noSubjectSelectedForYear ? 'course-year--no-subjects-selected-for-year' : ''}
                         ${isFirstYear ? 'course-year--first-year' : ''}`;
        return (
            <div className={StringUtil.ensureOneLine(className)}
                 {...htmlDnD}>
                <div className="course-year__wrapper">
                    <div className="course-year__header">{`Year ${yearRequirement.year}`}</div>
                    {courseSemestersEle}
                </div>
            </div>
        )
    }
}