import './outcomes.cmpt.scss';

import * as React from "react";
import {inject, observer} from "mobx-react";
import {MyMajorsCmpt} from "./my-majors/my-majors.cmpt";
import {AvailableMajorsCmpt} from "./available-majors/available-majors.cmpt";
import {EventBus, EventRegistrationHandle, Events} from "../shared/services/event-bus.service";
import {AnyMajorBecameUnavailable, AnyMajorSelected, InvalidCoursePlan, MajorHasReplaced, MyMessage, ServerError} from "../my-messages/my-messages.vm";
import {MessageCmpt, MessageType} from "../shared/components/message/message.cmpt";
import {IdempotentScheduler} from "../shared/services/scheduler-service";
import {MyCoursePlansStore} from "../shared/stores/my-course-plans.store";
import {CourseHandbookStore} from "../shared/stores/course-handbook.store";


function MyMessagesCmpt({shownMessages, clearMessage}) {
    return (
        <div className="outcomes__plan-messages">
            {
                shownMessages.map((message) => {
                    let onClose = (message.messageProp.type === MessageType.error) ? {} : {onClose: ()=>clearMessage(message)};
                    return <MessageCmpt {...message.messageProp} key={message.constructor.name} {...onClose}/>
                })
            }
        </div>
    );
}


export interface OutcomesCmptProps {
    myCoursePlans?: MyCoursePlansStore;
    courseHandBook?: CourseHandbookStore
}

interface OutcomesCmptState {
    loading: boolean;  // triggers animation
    showSpinner: boolean;  // ensure the spinner is ready for animation
}

@inject('myCoursePlans', 'courseHandBook')
@observer
export class OutcomesCmpt extends React.Component<OutcomesCmptProps, OutcomesCmptState> {

    private simulationRegHandler: EventRegistrationHandle;
    private loadingScheduler: IdempotentScheduler<() => void, any> = new IdempotentScheduler();
    private readonly messages: Array<MyMessage>;

    constructor(props: OutcomesCmptProps) {
        super(props);

        this.simulationRegHandler = EventBus.register(Events.SIMULATE_OUTCOMES, this.onLoadingEvent.bind(this));

        this.messages = [
            new InvalidCoursePlan(),
            new AnyMajorSelected(),
            new AnyMajorBecameUnavailable(),
            new MajorHasReplaced(),
            new ServerError()
        ]

        this.state = {loading: false, showSpinner: false}

        this.clearMessage = this.clearMessage.bind(this);
    }

    componentWillUnmount(): void {
        this.simulationRegHandler.done();
    }

    private onLoadingEvent(loading: boolean) {
        if (loading) {
            this.loadingScheduler.schedule((): any => {
                this.setState({loading: false});
            });
            this.setState({loading: true, showSpinner: true});
        } else {
            this.loadingScheduler.execute();
        }
    }

    private hideSpinner = () => {
        // last defence to ensure we don't dismiss spinner, this is because of animation delay
        if (this.loadingScheduler.isEmpty()) {
            this.setState({loading: false, showSpinner: false});
        }
    };

    clearMessage(message: MyMessage) {
        // @ts-ignore
        message.clear = true;
        this.setState({});
    }

    getShownMessages(myCoursePlans, courseHandBook) {
        let shownMessages = this.messages.filter((message) => message.show({myCoursePlans, courseHandBook}));
        let errorMessages = shownMessages.filter((msg) => msg.messageProp.type === MessageType.error);
        if (errorMessages.length > 0) {
            shownMessages = errorMessages;
        }
        return shownMessages;
    }


    render() {
        const {loading, showSpinner} = this.state;
        const {myCoursePlans, courseHandBook} = this.props;
        const shownMessages = this.getShownMessages(myCoursePlans, courseHandBook);
        //const hasError = (shownMessages.length > 0 && shownMessages[0].messageProp.type === MessageType.error);

        return (
            <div className={`outcomes ${loading ? 'outcomes--loading' : ''}`}>
                <div className="outcomes__header">
                    <div className="outcomes__header-text">OUTCOMES</div>
                </div>
                <MyMessagesCmpt shownMessages={shownMessages} clearMessage={this.clearMessage}/>
                <div className="outcomes__panel-wrapper">
                    <div className="outcomes__my-majors-panel">
                        <MyMajorsCmpt/>
                    </div>
                    <div className="outcomes__select-major-panel">
                        <AvailableMajorsCmpt myCoursePlans={myCoursePlans} courseHandBook={courseHandBook}/>
                    </div>
                </div>
                <div className='outcomes__spinner'
                     style={{display: (showSpinner ? 'block' : null)}}
                     onTransitionEnd={(showSpinner ? () => this.hideSpinner() : null)}>
                    <div className='outcomes__spinner-overlay'></div>
                    <div className="outcomes__spinner-icon lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        );
    }
}