import * as React from "react";
import './collapsible.cmpt.scss';
import {EventBus, EventRegistrationHandle} from "../../services/event-bus.service";

export interface CollapsibleHeaderProps {
    showIndicator?: boolean;
    content: string | JSX.Element | ((isExpand: boolean) => JSX.Element);
    actions?: JSX.Element | ((isExpand: boolean) => JSX.Element);
}

export interface CollapsibleCmptProps {
    header: CollapsibleHeaderProps;
    content: JSX.Element;
    onContentVisibility?: { callback?: (contentVisible: boolean) => void, updateMaxDimension?: boolean };
    contentVisible?: boolean | string;
    toggleVisibilityEvent?: { eventName: string, resultMatcher: (payload, visible) => boolean };
}

export interface CollapsibleCmptState {
    contentVisible: boolean;
}

export class CollapsibleCmpt extends React.Component<CollapsibleCmptProps, CollapsibleCmptState> {

    private contentResizerDom: HTMLDivElement;
    private contentDom: HTMLDivElement;
    private toggleVisibilityEventRegHandle:EventRegistrationHandle;

    constructor(props: CollapsibleCmptProps) {
        super(props);
        let contentVisible = props.contentVisible || false;
        this.state = {contentVisible: (typeof contentVisible === 'string') ? false : contentVisible};

        const {toggleVisibilityEvent} = this.props;
        if(!!toggleVisibilityEvent) {
            EventBus.register(toggleVisibilityEvent.eventName, (payload) => {
                if(toggleVisibilityEvent.resultMatcher(payload, this.state.contentVisible)) {
                    this.toggleContentVisibility();
                }
            })
        }
    }

    componentWillUnmount(): void {
        if(!!this.toggleVisibilityEventRegHandle) {
            this.toggleVisibilityEventRegHandle.done();
        }
    }

    private toggleContentVisibility() {
        const contentVisible = !this.state.contentVisible;
        if (!!this.props.onContentVisibility && !!this.props.onContentVisibility.callback) {
            this.props.onContentVisibility.callback(contentVisible);
        }
        this.setState({contentVisible});
    }

    componentWillReceiveProps(nextProps: Readonly<CollapsibleCmptProps>): void {
        // TODO: need to unwind this, using ideally using props
        if(typeof nextProps.contentVisible === 'string') {
            return ;
        }

        if (nextProps.contentVisible !== this.state.contentVisible) {
            this.setState({contentVisible: nextProps.contentVisible});
        }
    }

    render() {
        const isExpanded = this.state.contentVisible;
        const {content, actions, showIndicator = true} = this.props.header;
        const contentClassName = `collapsible__content ${this.state.contentVisible ? '' : 'collapsible__content--hide'}`;
        const headerTextClassName = `collapsible__header__text ${this.state.contentVisible ? '' : 'collapsible__header__text--hide'}`;

        return (
            <div className='collapsible'>
                <div className={`collapsible__header ${!showIndicator ? 'collapsible__header_custom-indicator' : ''}`}>
                    <div className={headerTextClassName} onClick={this.toggleContentVisibility.bind(this)}>
                        {(content instanceof Function) ? content(isExpanded) : content}
                    </div>
                    <div className="collapsible__header__actions">
                        {actions instanceof Function ? actions(isExpanded) : actions}
                    </div>
                </div>
                <div className={contentClassName} ref={(ref) => this.contentDom = ref}>
                    <div className="collapsible__content-resizer" ref={(ref) => this.contentResizerDom = ref}>
                        {isExpanded ? this.props.content : null}
                    </div>
                </div>
            </div>
        );
    }
}