import {observable} from "mobx";
import {Subject} from "../../browse-subjects/subject";
import {Outcome} from "../../outcomes/outcome";
import {MyCoursePlansService} from "../services/my-course-plans.service";
import {EventBus, Events} from "../services/event-bus.service";
import {IdempotentScheduler} from "../services/scheduler-service";
import {Given} from "../html-util";
import {ArrayUtil} from "../util";
import {Status} from "../../status";


enum KEY {
    outcomes, subjects
}

export class CourseHandbookStore {

    @observable handbookData: Map<KEY, Array<Subject | Outcome>> = new Map<KEY, Array<Subject | Outcome>>();

    constructor(private myCoursePlansService: MyCoursePlansService) {

        this.handbookData.set(KEY.outcomes, []);
        this.handbookData.set(KEY.subjects, []);

        EventBus.register(Events.APP_READY, () => {
            const scheduler: IdempotentScheduler<any, any> = new IdempotentScheduler();
            const dataLoaded = (): any => {
                EventBus.fire({name: Events.APP_DATA_LOADED});
            };

            scheduler.schedule(dataLoaded);
            const courseCode = Given.window().getQueryParam('course');
            this.myCoursePlansService
                .fetchSubjects(courseCode)
                .then(this.prepareSubjects(scheduler));
            scheduler.schedule(dataLoaded);
            this.myCoursePlansService
                .fetchOutcomes(courseCode)
                .then(this.prepareOutcomes(scheduler));
        });
    }

    private prepareOutcomes(scheduler: IdempotentScheduler<any, any>) {
        return (response: any) => {
            const outcomes = (ArrayUtil.sortBy(response.data, 'info.name') as Array<Outcome>)
                .filter((outcome) => !!outcome.info.name)
                .map(outcome => {
                        outcome.specialisations = outcome.specialisations || [];
                        outcome.userPref = {selected: false, favourite: false, specialisation: {selected: null, favourite: null}};
                        outcome.state = {status: Status.met, info: '', warning: '', error: '', msgs: [], availability_score: 5};
                        outcome.specialisations.forEach(specialistion => {
                            specialistion.userPref = {selected: false, favourite: false, specialisation: {selected: null, favourite: null}};
                            specialistion.state = {status: Status.met, info: '', warning: '', error: '', msgs: [], availability_score: 5};
                        });
                        return outcome;
                    }
                );
            this.handbookData.set(KEY.outcomes, outcomes);
            scheduler.execute();
        };
    }

    private prepareSubjects(scheduler: IdempotentScheduler<any, any>) {
        return (response: any) => {
            const subjects: Array<Subject> = (ArrayUtil.sortBy(response.data, 'code') as Array<Subject>)
                .map(subject => {
                        subject.info.overview_text = subject.info.overview_text || "";
                        subject.info.requisites_html = subject.info.requisites_html || "";
                        subject.info.coreFor = subject.info.coreFor || [];
                        subject.info.debug = {};
                        subject.userPref = {year: null, semester: null, selected: false, favourite: false};
                        subject.state = {type: '', status: Status.unknown, info: '', warning: '', error: '', msgs: []};
                        return subject;
                    }
                );

            this.handbookData.set(KEY.subjects, subjects);
            scheduler.execute();
        };
    }

    get outcomes(): Array<Outcome> {
        return this.handbookData.get(KEY.outcomes) as Array<Outcome>;
    }

    get subjects(): Array<Subject> {
        return this.handbookData.get(KEY.subjects) as Array<Subject>;
    }
}
