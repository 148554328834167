import "./major-details-specialisations.cmpt.scss"

import {SelectedMajorsCategory} from "../../my-majors/selected-majors/selected-majors.cmpt";
import {Outcome} from "../../outcome";
import {ClickableCmpt} from "../../../shared/components/clickable/clickable.cmpt";
import * as React from "react";


function SpecialisationCmpt({major, selectedMajorsCategory, onSelect, onUnSelect, onFavourite, onUnFavourite}) {

    let specialisations = major.specialisations;
    switch (selectedMajorsCategory) {
        case SelectedMajorsCategory.selected:
            specialisations = [major.userPref.specialisation.selected];
            break;
        case SelectedMajorsCategory.favourited:
            specialisations = [major.userPref.specialisation.favourite];
            break;
        default:
            break;
    }

    return specialisations.map((special: Outcome) => {
        const isSelected = major.userPref.specialisation.selected === special;
        const isFavourited = major.userPref.specialisation.favourite === special;
        return (
            <span className=
                      {
                          `major-details-specialisations-specialisation
                           ${isSelected ? 'major-details-specialisations-specialisation--selected' : ''}
                           ${isFavourited ? 'major-details-specialisations-specialisation--favourited' : ''}`
                      }
                  key={`${special.code}-selected`}>
                    <span className="major-details-specialisations-specialisation-name">
                        {special.info.name}
                    </span>
                {!selectedMajorsCategory
                    ? <ClickableCmpt
                        content={<span className="major-details-specialisations-specialisation__favourited-icon material-icons dp48">{isFavourited ? 'favorite' : 'favorite_border'}</span>}
                        onclick={isFavourited ? () => onUnFavourite(special) : () => onFavourite(special)}/>
                    : null}
                {!selectedMajorsCategory
                    ? <ClickableCmpt
                        content={<span className="major-details-specialisations-specialisation__selected-icon material-icons dp48">{isSelected ? 'remove_circle_outline' : 'add_circle'}</span>}
                        onclick={isSelected ? () => onUnSelect(special) : () => onSelect(special)}/>
                    : null}
                </span>
        );
    });
}


export function MajorDetailsSpecialisationsCmpt({major, selectedCategory, onSelect, onUnSelect, onFavourite, onUnFavourite}) {

    const specialisations = major.specialisations || [];
    if (specialisations.length === 0) {
        return null;
    }

    return (
        <div className="major-details-specialisations">
            <div className="major-details-specialisations-header">
                SPECIALISATIONS
            </div>
            <div className="major-details-specialisations-wrapper">
                <SpecialisationCmpt major={major}
                                    selectedMajorsCategory={selectedCategory}
                                    onSelect={onSelect}
                                    onUnSelect={onUnSelect}
                                    onFavourite={onFavourite}
                                    onUnFavourite={onUnFavourite}/>
            </div>
        </div>
    );
}