import './my-majors.cmpt.scss';

import * as React from "react";
import {inject, observer} from "mobx-react";
import {SelectedMajorsCategory, SelectedMajorsCmpt} from "./selected-majors/selected-majors.cmpt";
import {Outcome} from "../outcome";
import {MajorDetailsCmpt} from "../major-details/major-details.cmpt";
import {Given} from "../../shared/html-util";
import ReactDOM from 'react-dom';
import {MyCoursePlansStore} from "../../shared/stores/my-course-plans.store";


export interface MyMajorsCmptProps {
    myCoursePlans?: MyCoursePlansStore;
}

export interface MyMajorsCmptState {
    expandedMajor?: Outcome;
    majorDom?: HTMLElement;
    selectedMajorsCategory: SelectedMajorsCategory;
    favouritedMajorDetailShown: boolean;
}

@inject('myCoursePlans')
@observer
export class MyMajorsCmpt extends React.Component<MyMajorsCmptProps, MyMajorsCmptState> {

    static UNSELECT_MAJOR: MyMajorsCmptState = {expandedMajor: null, majorDom: null, selectedMajorsCategory: null, favouritedMajorDetailShown: false};

    private selectedMajorsDom: SelectedMajorsCmpt;
    private favouritedMajorsDom: SelectedMajorsCmpt;

    constructor(props: MyMajorsCmptProps) {
        super(props);
        this.state = MyMajorsCmpt.UNSELECT_MAJOR;

    }

    unselectMajor(major: Outcome) {
        this.closeMajorDetails(
            () => this.props.myCoursePlans.unSelectMajor(major)
        );
    }

    unfavouriteMajor(major: Outcome) {
        this.closeMajorDetails(
            () => this.props.myCoursePlans.unFavouriteMajor(major)
        );
    }

    private closeMajorDetails(callback: () => void = () => {
    }) {
        this.setState(MyMajorsCmpt.UNSELECT_MAJOR);
        callback();
    }

    private unsetExpandedMajor() {
        this.setState(MyMajorsCmpt.UNSELECT_MAJOR);
    }

    private toggleMajorDetails(newSelectedMajorsCategory: SelectedMajorsCategory, newMajorDom: HTMLElement, newExpandedMajor: Outcome) {

        const updateStateToShowMajorDetails = () => {
            let targetOffsetTop = newMajorDom.offsetTop;
            let targetedSibling = newMajorDom as Node;
            while ((targetedSibling = targetedSibling.nextSibling) != null) {
                if ((targetedSibling as HTMLElement).offsetTop > targetOffsetTop) {
                    break;
                }
            }

            this.setState({
                expandedMajor: newExpandedMajor,
                majorDom: newMajorDom,
                selectedMajorsCategory: newSelectedMajorsCategory,
                favouritedMajorDetailShown: this.isMajorShwonWithin(newMajorDom, this.favouritedMajorsDom)
            });
        };

        const {expandedMajor, selectedMajorsCategory} = this.state;
        if (expandedMajor === newExpandedMajor && selectedMajorsCategory === newSelectedMajorsCategory) {
            this.closeMajorDetails();
        } else {
            // show another major, so hide the current one and show newly selected
            updateStateToShowMajorDetails();
        }
    }

    isMajorShwonWithin(majorDom: HTMLElement, majorsCmpt: SelectedMajorsCmpt) {
        if (!majorDom) {
            return false;
        }
        const majorsDom = ReactDOM.findDOMNode(majorsCmpt);
        return Given.anElement(majorDom).isChildOf(majorsDom);
    }

    render() {
        const {expandedMajor, selectedMajorsCategory, favouritedMajorDetailShown} = this.state;
        const myCoursePlans = this.props.myCoursePlans;
        const selectedMajors = myCoursePlans.selectedMajors;
        const favouriteMajors = myCoursePlans.favouritedMajors;
        const anyMajorSelected = (selectedMajors.length > 0 || favouriteMajors.length > 0) ? true : false;
        const headerText = myCoursePlans.coursePlan.coursePlanReq.majorReq.selection.max > 1 ? 'SELECTED MAJORS' : 'SELECTED MAJOR';
        return (
            <div className="my-majors">
                <div className={`my-majors__my-major-group my-majors__selected-major ${!anyMajorSelected ? 'my-majors__my-major-group--no-major-selected' : ''}`}>
                    <SelectedMajorsCmpt header={headerText}
                                        category={SelectedMajorsCategory.selected}
                                        selectedMajors={selectedMajors} unselectMajor={this.unselectMajor.bind(this)}
                                        selectedMajorClickedCallback={this.toggleMajorDetails.bind(this, SelectedMajorsCategory.selected)}
                                        ref={(ref) => this.selectedMajorsDom = ref}/>
                </div>
                <div className={`my-majors__my-major-group my-majors__favourited-majors
                                ${favouritedMajorDetailShown ? 'my-majors__favourited-majors--detail-shown-within' : ''}
                                ${!anyMajorSelected ? 'my-majors__my-major-group--no-major-selected' : ''}`}>
                    <SelectedMajorsCmpt header='FAVOURITED MAJORS'
                                        category={SelectedMajorsCategory.favourited}
                                        selectedMajors={favouriteMajors} unselectMajor={this.unfavouriteMajor.bind(this)}
                                        selectedMajorClickedCallback={this.toggleMajorDetails.bind(this, SelectedMajorsCategory.favourited)}
                                        ref={(ref) => this.favouritedMajorsDom = ref}/>
                </div>
                <div className="my-majors__my-major-details">
                    {(!!this.state.expandedMajor)
                        ? <MajorDetailsCmpt major={expandedMajor} majorDom={this.state.majorDom}
                                            favouriteMajorCallback={this.unsetExpandedMajor.bind(this)}
                                            selectedMajorsCategory={selectedMajorsCategory}
                                            selectMajorCallback={this.unsetExpandedMajor.bind(this)}
                                            closeCallback={this.unsetExpandedMajor.bind(this)}
                                            key={`selected-major-detail-${expandedMajor.code}}`}/>
                        : null}
                </div>
            </div>
        );
    }
}