import {ArrayUtil} from "../shared/util";
import {Subject} from "./subject";


const ALL_MAPPED_TERMS = [
    "Semester 1", "Winter Term", "Semester 2", "Summer Term", "Year Long"
];

const SORT_ORDER = [
    'semester 1',
    'winter term',
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'semester 2',
    'summer term',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
    'year long'
];

export interface Availability {
    name: string;
    shortText: string;
    sortValue: number;
}

export class AvailabilityUtil {

    constructor(private subject: Subject) {
    }

    getAvailabilityNames() {
        const {availability = []} = this.subject.info;
        let flattened: Array<string> = [];
        availability.forEach(a => {
            // TODO: should be able to remove this later.
            if (!Array.isArray(a)) {
                flattened = flattened.concat(a.split(",").map((b => b.trim())));
            } else {
                flattened.push(a.trim());
            }
        });
        return flattened;
    }

    getAvailability() {
        const mappedAvailability = this.getMappedAvailability();
        return ArrayUtil.flatten(
            Object.keys(mappedAvailability).map(semester => mappedAvailability[semester])
        );
    }

    getAvailabilitySortValue() {
        let sortValue = this.getAvailability()
            .map(avail => avail.sortValue)
            .reduce((total, curr) => total + curr, 0);
        return !sortValue
            ? 0 // 0 mean no availability, it should just return 0
            : sortValue + 0.5;  // plus 0.5 to ensure its guaranteed to be greater than the previous sortValue
    }

    private calcSortValue(availability: string) {
        const iter = [];
        for (let index = 0; index <= SORT_ORDER.indexOf(availability); index++) {
            iter.push(index + 1);
        }
        return iter.reduce((total, curr) => total + curr, 0);
    }

    private buildAvailability(availability: string): Availability {

        let avail = availability.toLowerCase();
        let calcSortValue = this.calcSortValue(avail);
        switch (avail) {
            case 'semester 1':
                return {name: availability, shortText: 'S1', sortValue: calcSortValue};
            case 'winter term':
                return {name: availability, shortText: 'Winter', sortValue: calcSortValue};
            case 'january':
                return {name: availability, shortText: 'Jan', sortValue: calcSortValue};
            case 'february':
                return {name: availability, shortText: `Feb`, sortValue: calcSortValue};
            case 'march':
                return {name: availability, shortText: 'Mar', sortValue: calcSortValue};
            case 'april':
                return {name: availability, shortText: 'Apr', sortValue: calcSortValue};
            case 'may':
                return {name: availability, shortText: 'May', sortValue: calcSortValue};
            case 'june':
                return {name: availability, shortText: 'Jun', sortValue: calcSortValue};
            case 'semester 2':
                return {name: availability, shortText: 'S2', sortValue: calcSortValue};
            case 'summer term':
                return {name: availability, shortText: 'Summer', sortValue: calcSortValue};
            case 'july':
                return {name: availability, shortText: `Jul`, sortValue: calcSortValue};
            case 'august':
                return {name: availability, shortText: 'Aug', sortValue: calcSortValue};
            case 'september':
                return {name: availability, shortText: 'Sep', sortValue: calcSortValue};
            case 'october':
                return {name: availability, shortText: 'Oct', sortValue: calcSortValue};
            case 'november':
                return {name: availability, shortText: 'Nov', sortValue: calcSortValue};
            case 'december':
                return {name: availability, shortText: 'Dec', sortValue: calcSortValue};
            case 'year long':
                return {name: availability, shortText: 'Year', sortValue: calcSortValue};
            default:
                return null;
        }
    }

    getMappedAvailability(): { [scheduled: string]: Array<Availability> } {
        const semesters = this.getAvailabilityNames();
        const intensives: any = this.subject.info.intensives || {};
        semesters.forEach(semester => {
            if (!(semester in intensives)) {
                intensives[semester] = [semester];
            }
        });

        const sortedAvailability: { [scheduled: string]: Array<Availability> } = {};
        ALL_MAPPED_TERMS.forEach(mappedTo => {
            if (!!intensives[mappedTo]) {
                sortedAvailability[mappedTo] =
                    intensives[mappedTo].map(termName => this.buildAvailability(termName));
            }
        });
        return sortedAvailability
    }


    isYearLong() {
        const availability = this.subject.info.availability || [];
        return availability.indexOf("Year Long") !== -1;
    }
}