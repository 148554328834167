import * as React from "react";
import './subject-details.cmpt.scss';
import {inject, observer} from "mobx-react";
import {Subject} from "../../subject";
import {ClickableCmpt} from "../../../shared/components/clickable/clickable.cmpt";
import {EventBus, Events} from "../../../shared/services/event-bus.service";
import {Given} from "../../../course-plan/course-plan";
import {StringUtil} from "../../../shared/util";
import {MyCoursePlansStore} from "../../../shared/stores/my-course-plans.store";


export interface SubjectDetailsCmptProps {
    subject: Subject;
    myCoursePlans?: MyCoursePlansStore;
};

@inject("myCoursePlans")
@observer
export class SubjectDetailsCmpt extends React.Component<SubjectDetailsCmptProps> {

    static MAX_OVERVIEW_LENGTH = 900;

    componentDidMount(): void {
        this.props.myCoursePlans.loadSubjectDetails(this.props.subject.code);
    }

    collapse() {
        // TODO: need to think about how to collapse the opening list properly.
        EventBus.fire({name: Events.APP_COLLAPSIBLE_SHOW, payload: null});
    }


    private buildMoreLink(subject: Subject) {
        return <a href={Given.subject(subject).handbookUrl()}
                  // eslint-disable-next-line react/jsx-no-target-blank
                  target="_blank"
                  className="subject-details__subject-details__more">more information</a>;
    }

    private buildOverviewPanel(overviewText: string) {
        return (
            <>
                {overviewText.length > SubjectDetailsCmpt.MAX_OVERVIEW_LENGTH ? `${StringUtil.extract(overviewText, SubjectDetailsCmpt.MAX_OVERVIEW_LENGTH, ' ')} ...` : overviewText}
                <br/>
                {/*{this.buildDebugPanel(this.props.subject)}*/}
            </>
        );
    }

    render() {
        const subject: Subject = this.props.subject;
        const overviewText = subject.info.overview_text || '';
        const overview = this.buildOverviewPanel(overviewText);
        const moreEle = this.buildMoreLink(subject);

        return (
            <div className="subject-details">
                <div className="subject-details__indicator"></div>
                <div className="subject-details__subject-content-wrapper">
                    <div className="subject-details__subject-header">
                        <div className="subject-details__subject-header__code-and-name">
                            <span className="subject-details__subject-details__code">{subject.code}</span>
                            <span className="subject-details__subject-details__name">{subject.info.name}</span>
                            <div className="subject-details__subject-header__icons">
                                <ClickableCmpt content={
                                    <div className="subject-details__subject-header__icons__icon__close material-icons dp48">
                                        close
                                    </div>
                                } onclick={this.collapse.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="subject-details__subject-content">
                        <div className="subject-details__subject-details__overview">
                            <div className="subject-details__subject-details__overview-title">OVERVIEW</div>
                            {overview}
                            {moreEle}
                        </div>
                        <div className="subject-details__subject-content-divider"></div>
                        <div className="subject-details__subject-eligibility-and-requirements">
                            <div className="subject-details__subject-eligibility-and-requirements-title">ELIGIBILITY & REQUIREMENTS</div>
                            <div dangerouslySetInnerHTML={{__html: subject.info.requisites_html}}></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};