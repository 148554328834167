import * as React from "react";
import './available-subjects.cmpt.scss';
import {inject, observer} from "mobx-react";
import {SubjectsByCategoryCmpt} from "./subjects-by-category/subjects-by-category.cmpt";

import {ProgressiveLoadingService} from "../../shared/services/progressive-loading.service";
import {ByCategory, ByCategory as SubjectsByCategory, Subject} from "../subject";
import {ArrayUtil} from "../../shared/util";
import {Suggestion, Suggestions, Type as Suggestion_Type} from "../../course-plan/suggestions";
import {BrowseSubjectsCmpt} from "../browse-subjects.cmpt";
import {Given} from "../../course-plan/course-plan";
import {EventBus, EventRegistrationHandle, Events} from "../../shared/services/event-bus.service";
import {defaultCategories} from './available-subjects-categories';
import {CourseHandbookStore} from "../../shared/stores/course-handbook.store";
import {MyCoursePlansStore} from "../../shared/stores/my-course-plans.store";
import {UiStateStore} from "../../shared/stores/ui/ui-state.store";
import ReactTooltip from "react-tooltip";

const crypto = require('crypto');

/**
 * IMPORTANT: review shouldComponentUpdate() if you need to modify the props.
 */
interface SubjectSuggestionsCmptProps {
    subjectsByCategory: Array<ByCategory>;
    expandSubject: string;
    myCoursePlans: MyCoursePlansStore;
    courseHandBook: CourseHandbookStore;
    uiState: UiStateStore;
    onSubjectExpandChange: (category_uuid: string, subjectCode: string) => void;
    level: string | number;
}

class SubjectSuggestionsCmpt extends React.Component<SubjectSuggestionsCmptProps> {

    subjectCount(subjectsByCategory: Array<ByCategory>): number {
        return subjectsByCategory
            .map(sbc => sbc.subjects.length)
            .reduce((sum, curr) => sum + curr, 0);
    }

    categoryUUIDsAsStr(subjectsByCategory: Array<ByCategory>): string {
        return crypto.createHash('md5').update(
            subjectsByCategory
                .map(sbc => sbc.uuid + sbc.subjects.map(s => s.code).reduce((sum, cur) => `${sum}-${cur}`, ''))
                .reduce((sumUUID, currUUID) => `${sumUUID}-${currUUID}`, '')
        ).digest("hex");
    }


    /**
     * IMPORTANT: Be careful if you need to modify this function!!!
     *
     * @param nextProps
     * @param nextState
     * @param nextContext
     */
    // eslint-disable-next-line
    shouldComponentUpdate(nextProps: Readonly<SubjectSuggestionsCmptProps>, nextState: Readonly<{}>, nextContext: any): boolean {
        const {subjectsByCategory, expandSubject, level} = this.props;
        if (this.subjectCount(subjectsByCategory) !== this.subjectCount(nextProps.subjectsByCategory)) {
            return true;
        }
        if (this.categoryUUIDsAsStr(subjectsByCategory) !== this.categoryUUIDsAsStr(nextProps.subjectsByCategory)) {
            return true;
        }
        if (expandSubject !== nextProps.expandSubject) {
            return true;
        }
        if (level !== nextProps.level) {
            return true;
        }
        return false;
    }

    render() {
        const {subjectsByCategory, expandSubject, onSubjectExpandChange, level} = this.props;
        const {myCoursePlans, courseHandBook} = this.props;
        return (
            <>
                {
                    subjectsByCategory.map((subjectsByCategory) => {
                        if (subjectsByCategory.subjects.length === 0) {
                            return null;
                        }
                        return <SubjectsByCategoryCmpt subjectsByCategory={subjectsByCategory}
                                                       category_uuid={subjectsByCategory.uuid}
                                                       expandSubject={expandSubject}
                                                       onSubjectExpandChange={onSubjectExpandChange}
                                                       myCoursePlans={myCoursePlans}
                                                       courseHandBook={courseHandBook}
                                                       key={`${subjectsByCategory.uuid}-${level}`}/>;
                    })
                }
            </>
        );
    }
}


export interface AvailableSubjectsCmptProps {
    level: number;
    group: string;
    search: string;
    myCoursePlans?: MyCoursePlansStore;
    courseHandBook?: CourseHandbookStore;
    uiState?: UiStateStore;
}

interface AvailableSubjectsCmptState {
    lastLoaded: Date;
    expandSubject: string;
}

@inject('myCoursePlans', 'courseHandBook', 'uiState')
@observer
export class AvailableSubjectsCmpt extends React.Component<AvailableSubjectsCmptProps, AvailableSubjectsCmptState> {

    static loadingArea: number = 10;

    private domRef: HTMLDivElement;
    private lastScrollPos: number;
    private progressiveLoader: ProgressiveLoadingService<SubjectsByCategory>;
    private allSubjectsCollapseRegHandle: EventRegistrationHandle;

    constructor(props: AvailableSubjectsCmptProps) {
        super(props);

        this.state = {lastLoaded: new Date(), expandSubject: null};
        // TODO: ensure progressive loading works for different levels
        this.progressiveLoader = new ProgressiveLoadingService(this.getDefaultCategorySets.bind(this), 10);

        this.onSubjectExpandChange = this.onSubjectExpandChange.bind(this);
    }

    componentDidMount(): void {
        setTimeout(() => {
            window.addEventListener('scroll', this.onWindowScroll.bind(this), false);
        });

        this.allSubjectsCollapseRegHandle = EventBus.register(
            Events.APP_COLLAPSIBLE_SHOW,
            (payload: any) => {
                if (payload === this) {
                    return;
                }
                this.setState({expandSubject: null});
            });
    }

    componentWillUnmount(): void {
        window.removeEventListener('scroll', this.onWindowScroll.bind(this), false);
        this.allSubjectsCollapseRegHandle.done();
    }

    private onWindowScroll() {
        const currScrollPos = (window.innerHeight + window.scrollY);
        const scrollOffSetHeightMinusLoadingArea = (document.body.offsetHeight - AvailableSubjectsCmpt.loadingArea);

        const enteringLoadingArea = () => {

            // is from outside of loading area
            const isFromNonLoadingArea = this.lastScrollPos < scrollOffSetHeightMinusLoadingArea;

            // is in the loading area
            const isInLoadingArea = currScrollPos >= scrollOffSetHeightMinusLoadingArea;

            return isInLoadingArea && isFromNonLoadingArea;
        };
        const isLoading = () => {
            return this.progressiveLoader.inProgress === false;
        };
        const hasMore = () => {
            return this.progressiveLoader.hasMore();
        };

        if (enteringLoadingArea() && isLoading() && hasMore()) {
            this.progressiveLoader.start();
            this.setState({lastLoaded: new Date()});
            this.progressiveLoader.done();
        }
        this.lastScrollPos = window.innerHeight + window.scrollY;
    }

    private getFilterResult() {
        const {group = null, search = null, level, myCoursePlans, courseHandBook} = this.props;
        const breadthId = myCoursePlans.coursePlan.coursePlanReq.breadthId;

        let subjectsByLevel = level === 0
            ? courseHandBook.subjects
            : Given.subjects(courseHandBook.subjects).findByLevels([level]);

        // filter by whether or not is breadth subject
        if (group !== null) {
            subjectsByLevel = Given.subjects(subjectsByLevel).filterBy((subject) => {
                const isBreadthSubject = Given.subject(subject).isBreadth(breadthId, myCoursePlans.selectedMajors);
                if (group === BrowseSubjectsCmpt.BREADTH_GROUPING) {
                    return isBreadthSubject === true;
                } else {
                    return isBreadthSubject === false;
                }
            });
        }

        // filter by search
        if (!!search) {
            const searchFilter = search.toLowerCase();
            subjectsByLevel = Given.subjects(subjectsByLevel).filterBy((subject: Subject) => {
                const code = subject.code.toLowerCase();
                const name = subject.info.name.toLowerCase();
                return code.indexOf(searchFilter) !== -1 || name.indexOf(searchFilter) !== -1
            });
        }

        return subjectsByLevel;
    }

    private getDefaultCategorySets(): Array<SubjectsByCategory> {

        const result = this.getFilterResult();

        const groupByStudyArea = Given.subjects(result).groupByStudyArea();
        if (groupByStudyArea.size === 0) {
            return [];
        }

        const groupByCategory = defaultCategories.map(group => {
            const reGroup: SubjectsByCategory = {category: group.name, subjects: [], uuid: group.name, type: Suggestion_Type.defaults};
            group.codes.forEach(code => {
                if (!!groupByStudyArea.get(code)) {
                    reGroup.subjects = reGroup.subjects.concat(groupByStudyArea.get(code));
                }
            });
            return reGroup;
        });

        // console.error('all subjects before grouping = ' + this.props.courseHandBook.subjects.length);
        // console.error('all subjects after grouping = ' + groupByCategory.map(group => group.subjects.length).reduce((p, c) => p + c, 0));

        ArrayUtil.sort(groupByCategory, (a, b) => a.category > b.category ? 1 : -1);

        return groupByCategory.filter((groupBy) => groupBy.subjects.length !== 0);
    }

    private getSuggestionsCategorySets(suggestionsSets: Suggestions, subjects: Array<Subject>): Array<SubjectsByCategory> {
        let suggestions: Array<SubjectsByCategory> = [];
        if (!!suggestionsSets.sets) {
            const givenSubjects = Given.subjects(subjects);
            const duplicateChecker = new Map<string, any>();
            suggestions = suggestionsSets.sets.map((suggestion: Suggestion) => {
                if (duplicateChecker.has(suggestion.uuid)) {
                    return {category: suggestion.name, subjects: [], uuid: suggestion.uuid, type: suggestion.type};
                }
                duplicateChecker.set(suggestion.uuid, []);

                const groupSubjects = givenSubjects.findByCodes(suggestion.subjects);
                return {category: suggestion.name, subjects: groupSubjects, uuid: suggestion.uuid, type: suggestion.type};
            });
        }
        return suggestions;
    }

    private onSubjectExpandChange(category_uuid: string, subjectCode: string) {
        this.setState({expandSubject: `${category_uuid}-${subjectCode}`});
        EventBus.fire({name: Events.APP_COLLAPSIBLE_SHOW, payload: this});
    }

    private getSubjectActionTooltip() {
        const showTooltip = this.props.myCoursePlans.selectedSubjects.length === 0;
        return showTooltip ? `Click to add subject to Course Plan` : null;
    }

    render() {
        const {expandSubject} = this.state;
        const {myCoursePlans, courseHandBook, uiState, level} = this.props;
        const suggestionCategory = this.getSuggestionsCategorySets(myCoursePlans.suggestions, this.getFilterResult());
        const defaultCategory = this.progressiveLoader.getItems();

        return (
            <div className="available-subjects" ref={(domRef) => this.domRef = domRef}>

                <SubjectSuggestionsCmpt subjectsByCategory={suggestionCategory}
                                        myCoursePlans={myCoursePlans}
                                        courseHandBook={courseHandBook}
                                        uiState={uiState}
                                        expandSubject={expandSubject}
                                        level={level}
                                        onSubjectExpandChange={this.onSubjectExpandChange}/>

                <SubjectSuggestionsCmpt subjectsByCategory={defaultCategory}
                                        myCoursePlans={myCoursePlans}
                                        courseHandBook={courseHandBook}
                                        uiState={uiState}
                                        expandSubject={expandSubject}
                                        level={level}
                                        onSubjectExpandChange={this.onSubjectExpandChange}/>

                <div className="available-subjects__loading-icon lds-dual-ring"></div>
                <ReactTooltip id={`subject-action-tooltip`} getContent={()=>this.getSubjectActionTooltip()}/>
            </div>
        );
    }
};