import {toJS} from "mobx";
import {DebounceScheduler} from "./scheduler-service";
import {MyCoursePlansService} from "./my-course-plans.service";
import {CoursePlan} from "../../course-plan/course-plan";
import {EventBus, Events} from "./event-bus.service";

export class PlanSimulatorService {

    private scheduler: DebounceScheduler<() => void, any> = new DebounceScheduler();

    constructor(private myCoursePlansService: MyCoursePlansService) {
    }

    simulate(coursePlan: CoursePlan, onSuccess, onFailure: (e) => void = () => {}) {

        const fireUpdateEvent = () =>
            setTimeout(() => {
                    EventBus.fire({name: Events.VALIDATING_COURSE_PLAN_UPDATE_UI});
                    EventBus.fire({name: Events.SIMULATE_OUTCOMES, payload: false});
                }
            );

        const coursePlanJson = toJS(coursePlan);

        this.scheduler.schedule((): any => {
            EventBus.fire({name: Events.SIMULATE_OUTCOMES, payload: true});
            this.myCoursePlansService
                .simulate(coursePlanJson)
                .then(onSuccess)
                .then(() => {
                    fireUpdateEvent();
                    this.scheduler.next();
                })
                .catch((e) => {
                    onFailure(e);
                    this.scheduler.next();
                    fireUpdateEvent();
                });
        });
        this.scheduler.execute();
    }
}