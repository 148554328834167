import './message.cmpt.scss';

import * as React from "react";


export class MessageCmpt extends React.Component<MessageCmptProps> {

    render() {
        return (
            <div className={`message message--${this.props.type}`}>
                <div className="message__text">{this.props.message}</div>
                {!!this.props.onClose ?
                    <div className="message__close" onClick={this.props.onClose}>
                        <div className="material-icons dp48">
                            close
                        </div>
                    </div> :
                    null}
            </div>
        );
    }
}

export interface MessageCmptProps {
    message: string | JSX.Element;
    type: MessageType;
    onClose?: () => boolean;
}


export enum MessageType {
    info = 'info',
    alert = 'alert',
    warn = 'warn',
    error = 'error'
}
