const _ = require('lodash');

export class ArrayUtil {
    static filterBy<T>(array: Array<T>, filterByKey: string, filters: Array<any>) {
        return array.filter((ele: T) => filters.indexOf(_.get(ele, filterByKey)) === -1);
    }

    static filterWith<T>(array: Array<T>, fn: (ele: T) => boolean) {
        return array.filter(fn);
    }

    static findBy<T>(array: Array<T>, filterByKey: string, toMatch: Array<any>) {
        return array.filter((ele: T) => toMatch.indexOf(_.get(ele, filterByKey)) >= 0);
    }

    static findWith<T>(array: Array<T>, fn: (ele: T) => boolean) {
        return array.filter((ele: T) => fn(ele));
    }

    static extract(array: Array<any>, keyPath: string) {
        return array.map((ele) => _.get(ele, keyPath));
    }

    static sum(array: Array<any>, key: string) {
        return array.reduce((a, c) => a + _.get(c, key), 0);
    }

    static groupBy<T>(array: Array<T>, mapKeyPath: string): Map<any, Array<T>> {
        const groupByMajor: Map<string, Array<T>> = new Map<string, Array<T>>();
        array.forEach((item: T) => {
            const mapKey = _.get(item, mapKeyPath);
            let groupedBy = groupByMajor.get(mapKey);
            if (!groupedBy) {
                groupedBy = new Array<T>();
                groupByMajor.set(mapKey, groupedBy);
            }
            groupedBy.push(item);
        });
        return groupByMajor;
    }

    static remove<T>(array: Array<T>, item: T) {
        const index = array.indexOf(item);
        if (index !== -1) {
            array.splice(index, 1);
        }
    }

    static sortBy<T>(array: Array<T>, keyPath: string) {
        return array.sort((a: T, b: T) => {
            const aKey = _.get(a, keyPath);
            const bKey = _.get(b, keyPath);
            return aKey > bKey ? 1 : -1;
        });
    }

    static sort<T>(array: Array<T>, compare: (a: T, b: T) => number) {
        return array.sort(compare);
    }

    static flatten(array: Array<any> | any): Array<any> {
        if (!Array.isArray(array)) {
            return [array];
        }
        if(array.length === 0) {
            return [];
        }
        return this.flatten(array[0]).concat(this.flatten(array.splice(1)))
    }
}

export class StringUtil {
    static extract(text: string, length: number, lastSeqChar: string): string {
        let extracted = text;
        if (extracted.length > length) {
            extracted = text.substring(0, length);
        }
        if (extracted.lastIndexOf(lastSeqChar) !== -1) {
            return extracted.substring(0, extracted.lastIndexOf(lastSeqChar));
        }
        return extracted;
    }

    static fromHexString(hexString: string): Buffer {
        return new Buffer(hexString.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));
    }

    static toHexString(bytes: Buffer) {
        return bytes.reduce((str: string, byte: any) => str + byte.toString(16).padStart(2, '0'), '');
    }


    static ensureOneLine(className:string) {
        return className
        // remove multiple newlines
            .replace(/(\n|\r)/gm, '')
        // remove multiple spaces
        .replace(/\s\s+/gm, ' ');
    }
}

export class ObjectUtil {
    static clone<T>(obj: T): T {
        return Object.assign({}, obj);
    }
}

