import {Status} from "./../status";

export interface CompletionRule {
    name: string;
    query: { subject_type: string, level: number };
    show: boolean;
    status: Status;
    target: number | Array<number>;
    type: Type
    value: number
}


export enum Type {
    points_max = 'points_max',
    points_min = 'points_min',
    points_eq = 'points_eq',
    points_range = 'points_range'
}

export class CompletionRuleUtil {
    constructor(private completionRule: CompletionRule) {
    }

    isMet() {
        return this.completionRule.status === Status.met;
    }

    isUnmet() {
        return this.completionRule.status === Status.unmet
    }

    isOpen() {
        return this.completionRule.status === Status.open;
    }

    getSubjectTypeAsString() {
        const query = this.completionRule.query;
        if (!query || (!query.subject_type && !query.level)) {
            return null;
        }

        let type = query.subject_type || '';
        let level = (!!query.level) ? `Level ${query.level}` : '';

        if (this.isDiscipline()) {
            type = type.replace('_discipline', '');
        } else if (this.isBreadth()) {
            type = type.replace('_breadth', ' breadth');
        }
        return `${level} ${type}`.trim();
    }

    isBreadth() {
        const query = this.completionRule.query;
        let type = query.subject_type || '';
        return type.indexOf('_breadth') !== -1
    }

    isDiscipline() {
        const query = this.completionRule.query;
        let type = query.subject_type || '';
        return type.indexOf('_discipline') !== -1;
    }
}