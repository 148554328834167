import './course-planner.cmpt.scss';

import * as React from "react";
import {BrowseSubjectsCmpt} from "./browse-subjects/browse-subjects.cmpt";
import {MyCoursePlanCmpt} from "./course-plan/my-course-plan.cmpt";
import {OutcomesCmpt} from "./outcomes/outcomes.cmpt";
import {EventBus, EventRegistrationHandle, Events} from "./shared/services/event-bus.service";
import {inject} from "mobx-react";
import {FeatureSupportStore} from "./shared/stores/feature-support.store";
import ReactTooltip from "react-tooltip";
import {Given} from "./shared/html-util";
import {IdempotentScheduler} from "./shared/services/scheduler-service";
import {MyCoursePlansStore} from "./shared/stores/my-course-plans.store";
import {CourseHandbookStore} from "./shared/stores/course-handbook.store";
import headerImage from "../theme/img/menu.png";
import { MessageCmpt, MessageType } from './shared/components/message/message.cmpt';


interface CoursePlannerCmptProps {
    myCoursePlans?: MyCoursePlansStore;
    courseHandBook?: CourseHandbookStore;
    featureSupport?: FeatureSupportStore
}

interface CoursePlannerCmptState {
    showBanner: boolean;
}

@inject('myCoursePlans', 'courseHandBook', 'featureSupport')
export class CoursePlannerCmpt extends React.Component<CoursePlannerCmptProps, CoursePlannerCmptState> {

    private domRef: HTMLElement;
    private spinnerDomRef: HTMLElement;
    private loadingRegHandler: EventRegistrationHandle;
    private validateRegHandler: EventRegistrationHandle;
    private loadingScheduler: IdempotentScheduler<() => void, any> = new IdempotentScheduler();

    constructor(props: CoursePlannerCmptProps) {
        super(props);

        this.loadingRegHandler = EventBus.register(Events.APP_LOADING_DATA, this.onLoadingEvent.bind(this));
        this.validateRegHandler = EventBus.register(Events.VALIDATING_COURSE_PLAN, this.onLoadingEvent.bind(this));

        this.state = {
            showBanner: true
        }
    }

    componentWillUnmount(): void {
        this.loadingRegHandler.done();
        this.validateRegHandler.done();
    }

    componentDidMount() {
        // setTimeout(() => window.scrollTo(0, 0));
        EventBus.fire({name: Events.APP_READY});
    }

    getBannerMessage() {
        if (this.props.myCoursePlans.coursePlan.course.code == 'bsci') {
            return (
                <div>
                    <p><h2>Bachelor of Science</h2></p>
                    <p>The Course Planner for 2020 is currently a pilot program that can be used to assist planning your course.</p>
                    <p>Please refer to <a href='https://handbook.unimelb.edu.au/2020/courses/b-sci/course-structure' target='_blank'>this page</a> of the handbook for comprehensive information about the course structure and core requirements.</p>
                    <p>If you require further assistance, please contact Student Services.</p>
                </div>
            )
        } else if (this.props.myCoursePlans.coursePlan.course.code == 'bcom') {
            return (
                <div>
                    <p><h2>Bachelor of Commerce</h2></p>
                    <p>The Course Planner for 2020 is currently a pilot program that can be used to assist planning your course.</p>
                    <p>Please refer to <a href='https://handbook.unimelb.edu.au/2020/courses/b-com/course-structure' target='_blank'>this page</a> of the handbook for comprehensive information about:</p>
                    <ul>
                        <li>Course Structure</li>
                        <li>Breadth Requirements</li>
                        <li>Compulsory Commerce Subject Requirements</li>
                        <li>Compulsory Quantitative Requirements</li>
                    </ul>
                    <p>If you require further assistance, please contact Student Services.</p>
                </div>
            )
        }
    }

    dismissBanner() {
        this.setState({showBanner: false});
        return true;
    }

    onLoadingEvent(loading: boolean) {
        if (loading) {
            this.spinnerDomRef.style.display = 'block';
            Given.anElement(this.domRef).addClass('course-planner--loading');
            this.loadingScheduler.schedule((): any => {
                const hideSpinner = () => {
                    // last defence to ensure we don't dismiss spinner, this is because of animation delay
                    if (this.loadingScheduler.isEmpty()) {
                        this.spinnerDomRef.style.display = null;
                    }
                    this.spinnerDomRef.removeEventListener('transitionend', hideSpinner);
                };
                Given.anElement(this.domRef).removeClass('course-planner--loading');
                this.spinnerDomRef.addEventListener('transitionend', hideSpinner);

            });
        } else {
            this.loadingScheduler.execute();
        }
    }

    render() {
        const bannerMessage = this.getBannerMessage()
        
        return (
            <div>
                <div className="header-banner">
                    <img src={headerImage} height="100%" useMap="#header-map" alt="Header Banner" />
                    <map name="header-map">
                        <area shape="rect" coords="0,0,100,103" href="https://www.unimelb.edu.au/" alt="Homepage" />
                        <area shape="rect" coords="130,0,300,103" href="https://students.unimelb.edu.au/admin/course-planning" alt="Course Planning" />
                        <area shape="rect" coords="320,0,470,103" href="https://students.unimelb.edu.au/admin/course-planning/resources" alt="Resources" />
                        <area shape="rect" coords="500,0,650,103" href="https://students.unimelb.edu.au/admin/enrol-in-subjects" alt="Enrol in Subjects" />
                        <area shape="rect" coords="680,0,900,103" href="https://students.unimelb.edu.au/stop1/course-advice-and-enrolment-assistance-at-stop-1" alt="Stop 1 Assistance" />
                        <area shape="rect" coords="930,0,1020,103" href="https://handbook.unimelb.edu.au" alt="Handbook" />
                        <area shape="rect" coords="1050,0,1150,103" href="https://melbourneuni.au1.qualtrics.com/jfe/form/SV_3TQpiyJkOvJ5rWl" alt="Feedback" />
                    </map>
                </div>

                {
                    this.state.showBanner &&
                        <div className="header-banner header-banner--notifications">
                            <MessageCmpt
                                type={MessageType.warn}
                                message={bannerMessage}
                                onClose={ () => this.dismissBanner() }/>
                        </div>
                }

                <div className={`course-planner course-planner--layout-main course-planner--${this.props.featureSupport.browserName}`} ref={(ref) => this.domRef = ref}>
                    <div className='course-planner__section course-planner__course-plans'>
                        <MyCoursePlanCmpt/>
                        {/*<SavedPlansCmpt/>*/}
                    </div>
                    <div className="course-planner__section course-planner__outcomes-and-subjects">
                        <div className='course-planner__section course-planner__outcomes'>
                            <OutcomesCmpt/>
                        </div>
                        <div className='course-planner__section course-planner__browse-subjects'>
                            <BrowseSubjectsCmpt/>
                        </div>
                    </div>
                    <div className='course-planner__spinner' ref={(ref) => this.spinnerDomRef = ref}>
                        <div className='course-planner__spinner-overlay'></div>
                        <div className="course-planner__spinner-icon lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                    <ReactTooltip/>
                </div>
            </div>
        );
    }
}






//
//
// /* eslint-disable no-console */
// import _ from 'underscore';
//
// /*
// Drop this mixin into a component that wastes time according to Perf.getWastedTime() to find
// out what state/props should be preserved. Once it says "Update avoidable!" for {state, props},
// you should be able to drop in React.addons.PureRenderMixin
//
// React.createClass {
//   mixins: [WhyDidYouUpdateMixin]
// }
// */
// function isRequiredUpdateObject(o) {
//     return Array.isArray(o) || (o && o.constructor === Object.prototype.constructor);
// }
//
// function deepDiff(o1, o2, p) {
//     const notify = (status) => {
//         console.warn('Update %s', status);
//         console.log('%cbefore', 'font-weight: bold', o1);
//         console.log('%cafter ', 'font-weight: bold', o2);
//     };
//     if (!_.isEqual(o1, o2)) {
//         console.group(p);
//         if ([o1, o2].every(_.isFunction)) {
//             notify('avoidable?');
//         } else if (![o1, o2].every(isRequiredUpdateObject)) {
//             notify('required.');
//         } else {
//             const keys = _.union(_.keys(o1), _.keys(o2));
//             for (const key of keys) {
//                 deepDiff(o1[key], o2[key], key);
//             }
//         }
//         console.groupEnd();
//     } else if (o1 !== o2) {
//         console.group(p);
//         notify('avoidable!');
//         if (_.isObject(o1) && _.isObject(o2)) {
//             const keys = _.union(_.keys(o1), _.keys(o2));
//             for (const key of keys) {
//                 deepDiff(o1[key], o2[key], key);
//             }
//         }
//         console.groupEnd();
//     }
// }
//
// const WhyDidYouUpdateMixin = {
//     componentDidUpdate(prevProps, prevState) {
//         deepDiff({props: prevProps, state: prevState},
//             {props: this.props, state: this.state},
//             this.constructor.displayName);
//     },
// };
//
// export default WhyDidYouUpdateMixin;















