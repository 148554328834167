import './selected-subject.cmpt.scss';

import * as React from "react";
import {Subject} from "../../../browse-subjects/subject";
import {inject, observer} from "mobx-react";
import {Draggable} from 'react-beautiful-dnd';
import {SelectedSubjectsCmpt} from "../selected-subjects.cmpt";
import {FeatureSupportStore} from "../../../shared/stores/feature-support.store";
import {CollapsibleCmpt} from "../../../shared/components/collapsible/collapsible.cmpt";
import {EventBus, EventRegistrationHandle, Events} from "../../../shared/services/event-bus.service";
import {Status} from "../../../status";
import {Given as CoursePlan_Given} from "../../../course-plan/course-plan";
import {SemesterRequirement, YearRequirement} from "../../course-plan.completion-requirement";
import {From, Position} from "./../../../shared/stores/ui/drag-data.vm";
import {Given} from "../../../shared/html-util";
import {SelectedSubjectInfoPanelCmpt} from "./selected-subject-info-panel/selected-subject-info-panel.cmpt";
import {SelectedSubjectHeaderCmpt} from "./selected-subject-header/selected-subject-header.cmpt";
import {MyCoursePlansStore} from "../../../shared/stores/my-course-plans.store";
import {toJS} from "mobx";
import {Availability} from "../../../browse-subjects/availability";
import {StringUtil} from "../../../shared/util";
import {UiStateStore} from "../../../shared/stores/ui/ui-state.store";


export interface SelectedSubjectCmptProps {
    index: number;
    selectionClass: string;
    selectedSubject: Subject;
    onUnSelect: (subject: Subject) => void;
    yearRequirement: YearRequirement;
    semesterRequirement: SemesterRequirement;
    newSubjectCodes: Array<string>;
    myCoursePlans?: MyCoursePlansStore;
    featureSupport?: FeatureSupportStore;
    uiState?: UiStateStore;
}

interface SelectedSubjectCmptState {
    isDragging: boolean;
    dragOverPositionClassName: string;
    mounted: boolean;
}

@inject('myCoursePlans', 'featureSupport', 'uiState')
@observer
export class SelectedSubjectCmpt extends React.Component<SelectedSubjectCmptProps, SelectedSubjectCmptState> {

    private domRef: HTMLElement;
    // private contentDomRef: CollapsibleCmpt;
    private reorderSubjectEventRegHandle: EventRegistrationHandle;


    constructor(props: SelectedSubjectCmptProps, context: any) {
        super(props, context);

        this.state = {isDragging: false, dragOverPositionClassName: '', mounted: false};

        this.unSelectSubject = this.unSelectSubject.bind(this);
    }

    componentDidMount(): void {
        this.reorderSubjectEventRegHandle = EventBus.register(Events.REORDERING_SELECTED_SUBJECT, (payload) => {
            if (!payload) {
                this.setState({isDragging: false, dragOverPositionClassName: ''});
            }
        });
        // need a delay so we can see the animation
        setTimeout(() => this.setState({mounted: true}));
    }

    componentWillUnmount(): void {
        this.reorderSubjectEventRegHandle.done();
    }

    private getStatusClass(status: Status, selectionClass: string) {
        if (selectionClass === 'favourite') {
            return 'selected-subject--met';
        }

        switch (status) {
            case Status.unmet:
                return 'selected-subject--unmet';
            case Status.met:
                return 'selected-subject--met';
            case Status.open:
                return 'selected-subject--open';
            default:
                return 'selected-subject--unknown';
        }
    }

    getAvailability(subject: Subject): string {
        const {selectionClass, semesterRequirement} = this.props;
        const givenSubject = CoursePlan_Given.subject(toJS(subject));
        if (selectionClass === 'favourite') {
            return "";
        }
        if (givenSubject.isYearLong()) {
            return "Year"
        }
        if (givenSubject.getAvailabilityNames().indexOf(subject.userPref.semester) === -1) {
            return "";
        }
        const mappedAvailability: Array<Availability> = givenSubject.getMappedAvailability()[subject.userPref.semester];
        if (!mappedAvailability) {
            return "";
        }

        const isThisSemester = mappedAvailability.filter(term => term.name === semesterRequirement.name).length !== 0
        if (isThisSemester) {
            return "";
        }

        return mappedAvailability.map((term) => term.shortText).join("/");
    }

    private unSelectSubject(selectedSubject: Subject) {
        // TODO: use state or something other than DOM manipulations...
        Given.anElement(this.domRef).addClass('selected-subject--unselecting');

        const unSelectSubject = () => {
            this.props.onUnSelect(selectedSubject);
            if (!!this.domRef) {
                this.domRef.removeEventListener('transitionend', unSelectSubject);
            }
        };
        this.domRef.addEventListener('transitionend', unSelectSubject);
    }

    private onHtmlDragLeaveCleanup() {
        this.setState({dragOverPositionClassName: ''})
    }

    onHtmlDragStart(event: DragEvent) {

        let {uiState, semesterRequirement} = this.props;
        const from = !!semesterRequirement ? From.semester : From.favourite;

        uiState.dragData = {
            dragSubject: this.props.selectedSubject,
            insertSubject: null,
            position: null,
            from,
            currDraggingDomRef: this.domRef,
        };

        event.dataTransfer.setData('dummy', `${this.props.selectedSubject.code}`);

        const startDragging = () => {
            // IMPORTANT: Fire the 'reordering-selected-subjects' event, first.
            EventBus.fire({name: Events.REORDERING_SELECTED_SUBJECT, payload: true});
            this.setState({isDragging: true});
        };

        // give browser a chance to take the current screenshot before the item collapsed.
        setTimeout(startDragging);
    }

    onHtmlDragEnd() {
        this.props.uiState.dragData = null;
        this.setState({isDragging: false});
        EventBus.fire({name: Events.REORDERING_SELECTED_SUBJECT, payload: false});
    }

    onHtmlDragEnter(event: DragEvent) {
        event.preventDefault();
        this.onHtmlDragOver(event);
    }

    onHtmlDragOver(event: DragEvent) {
        const {uiState, selectedSubject} = this.props;
        const givenElement = Given.anElement(this.domRef);
        const currDraggingDomRef = uiState.dragData.currDraggingDomRef;
        const isFirstVisibleItem = () => {
            return givenElement.getCurrentIndex() === 0 ||
                (givenElement.getCurrentIndex() === 1 &&
                    (uiState.dragData.from !== From.all_subjects && Given.anElement(currDraggingDomRef).getCurrentIndex() === 0));
        };

        event.preventDefault();
        // event.stopPropagation();

        if (currDraggingDomRef !== this.domRef) {

            const mouseY = event.clientY;
            const domTop = this.domRef.getBoundingClientRect().top;

            // TODO: refactor this to use % other than hardcoded px
            const mouseOnDomDiff = mouseY - domTop;
            if (mouseOnDomDiff <= 15) {
                uiState.dragData.insertSubject = selectedSubject;
                uiState.dragData.position = Position.before;

                // if the mouse position is on the top 33%, we open up with item at the bottom
                if (isFirstVisibleItem()) {
                    this.setState({dragOverPositionClassName: 'selected-subject--html-drag-over-top-first-visible-on-list'});
                } else {
                    this.setState({dragOverPositionClassName: 'selected-subject--html-drag-over-top'});
                }
            }
            if (mouseOnDomDiff >= 30) {
                uiState.dragData.insertSubject = selectedSubject;
                uiState.dragData.position = Position.after;

                // if the mouse position is on the bottom 33%, we open up with item at the top
                if (isFirstVisibleItem()) {
                    this.setState({dragOverPositionClassName: 'selected-subject--html-drag-over-bottom-first-visible-on-list'});
                } else {
                    this.setState({dragOverPositionClassName: 'selected-subject--html-drag-over-bottom'});
                }
            }
        }
    }

    onHtmlDragLeave(event: DragEvent) {
        event.preventDefault();
        // event.stopPropagation();
        this.onHtmlDragLeaveCleanup();
    }

    shouldCollapseInfoPanel(payload: any, visible: boolean) {
        if (visible) {
            return true;
        }
        return false;
    }

    render() {
        const {isDragging, dragOverPositionClassName, mounted} = this.state;
        const {selectedSubject, selectionClass, featureSupport, index, myCoursePlans, newSubjectCodes} = this.props;
        const selectedMajors = myCoursePlans.selectedMajors;
        const breadthId = myCoursePlans.coursePlan.coursePlanReq.breadthId;
        const isYearLong = CoursePlan_Given.subject(selectedSubject).isYearLong();
        const status = selectedSubject.state.status;
        const playSelectAnimation = newSubjectCodes.indexOf(selectedSubject.code) !== -1;

        const html5dnd = featureSupport.htmlDnD ? {
            draggable: true,
            onDragStart: this.onHtmlDragStart.bind(this),
            onDragEnd: this.onHtmlDragEnd.bind(this),
            onDragEnter: this.onHtmlDragEnter.bind(this),
            onDragLeave: this.onHtmlDragLeave.bind(this),
            onDragOver: this.onHtmlDragOver.bind(this),
        } : {};


        return (
            <Draggable draggableId={`${selectionClass}-${selectedSubject.code}`}
                       index={index}
                       type={SelectedSubjectsCmpt.DRAG_AND_DROP_TYPE}
                       isDragDisabled={featureSupport.htmlDnD}>
                {
                    (provided, snapshot) => {
                        const className = `selected-subject
                                           ${dragOverPositionClassName}
                                           ${!!mounted ? '' : `selected-subject--not-mounted`}
                                           ${this.getStatusClass(status, selectionClass)}
                                           ${snapshot.isDragging ? 'selected-subject--dragging' : ''}
                                           ${isDragging ? 'selected-subject--html-dragging' : ''}
                                           ${isYearLong ? 'selected-subject--year-long' : ''}
                                           ${playSelectAnimation ? '' : 'select-subject--select-animation-off'}`;
                        return (
                            <div className={StringUtil.ensureOneLine(className)}
                                 ref={(ref) => {
                                     this.domRef = ref;
                                     provided.innerRef(ref)
                                 }}
                                 {...provided.draggableProps}
                                 {...provided.dragHandleProps}
                                 {...html5dnd}>
                                <CollapsibleCmpt
                                    header={
                                        {
                                            content: <SelectedSubjectHeaderCmpt selectedSubject={selectedSubject}
                                                                                selectionClass={selectionClass}
                                                                                mappedAvailability={this.getAvailability(selectedSubject)}
                                                                                breadthId={breadthId}
                                                                                selectedMajors={selectedMajors}
                                                                                unSelectSubject={this.unSelectSubject}/>
                                        }
                                    }
                                    contentVisible={'initial.false'}
                                    content={<SelectedSubjectInfoPanelCmpt subject={selectedSubject} selectionClass={selectionClass}/>}
                                    toggleVisibilityEvent={{eventName: Events.REORDERING_SELECTED_SUBJECT, resultMatcher: this.shouldCollapseInfoPanel.bind(this)}}/>
                            </div>

                        )
                    }
                }
            </Draggable>
        );
    }
}
