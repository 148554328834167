import {Subject} from "../../../browse-subjects/subject";

export interface DragData {
    dragSubject: Subject;
    insertSubject: Subject;
    position: Position;
    from: From,
    currDraggingDomRef: HTMLElement;
}

export enum From {
    semester = 'semester',
    favourite = 'favourite',
    all_subjects = 'all_subjects'
}

export enum Position {
    before = 'before',
    after = 'after'
}
