export class Given {
    static anElement(htmlElement: HTMLElement) {
        return new HtmlElementUtil(htmlElement);
    }

    static window() {
        return new WindowUtil();
    }
}

export class HtmlElementUtil {
    constructor(private element: HTMLElement) {
    }

    addClass(newClassName: string): HtmlElementUtil {
        const elementClassNames = this.element.className;
        if (elementClassNames.indexOf(newClassName) === -1) {
            this.element.className = `${elementClassNames} ${newClassName}`;
        }
        return this;
    }

    removeClass(removeClassName: string): HtmlElementUtil {
        const elementClassNames = this.element.className;
        if (elementClassNames.indexOf(removeClassName) !== -1) {
            this.element.className = elementClassNames.replace(removeClassName, '').trim();
        }
        return this;
    }

    hasClassName(className: string): boolean {
        const classNames = this.element.className.split(" ");
        return classNames.indexOf(className) !== -1
    }

    getCurrentIndex(): number {
        let element = this.element as Node;
        let index = 0;
        while ((element = element.previousSibling) != null) {
            index++;
        }
        return index;
    }

    findFirstScrollingParent() {
        return this._findFirstScrollingParent(this.element, (this.element.offsetParent as HTMLElement));
    }

    private _findFirstScrollingParent(node: HTMLElement, offsetParent: HTMLElement): HTMLElement {
        if (node == null || node === offsetParent) {
            return null;
        }

        if (node.scrollLeft > 0) {
            return node;
        } else {
            return this._findFirstScrollingParent((node.parentNode as HTMLElement), offsetParent);
        }

    }

    isChildOf(parent: Node) {
        let node = this.element as Node;
        while (node !== null) {
            if (node === parent) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    }

    toggleDisplay(displayAs?: string) {
        const display = this.element.style.display;
        if (display === 'none') {
            this.element.style.display = displayAs || '';  //se to default display
        } else {
            this.element.style.display = 'none';
        }
    }
}

export class WindowUtil {

    getQueryParam(key: string) {
        let data = new URL(window.location.href).searchParams.get(key);
        return data;
    }

    putQueryParam(key: string, data: string) {
        const searchParams = new URL(window.location.href).searchParams;
        if (searchParams.has(key)) {
            searchParams.delete(key);
        }
        if (!!data) {
            searchParams.append(key, data);
        }
        // @ts-ignore
        global.history.pushState(``, ``, `?${searchParams.toString()}`);
    }
}
