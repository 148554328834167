import {UI_CONFIGS as BSCI_CONFIG} from "../../../configs/bsci/ui.config";
import {UI_CONFIGS as BCOM_CONFIG} from "../../../configs/bcom/ui.config";
import {UI_CONFIGS as BA_CONFIG} from "../../../configs/ba/ui.config";
import {UIConfig} from "../../../configs/UIConfig";


const CLIENT_CONFIG = {
    "BSCI": BSCI_CONFIG,
    "BCOM": BCOM_CONFIG,
    "BA": BA_CONFIG
};

export class UIConfigStore {

    private readonly configs: Map<UIConfig, string>;

    constructor(courseCode: string = "") {
        this.configs = CLIENT_CONFIG[courseCode.toUpperCase()];
        if (!this.configs) {
            throw new Error(`Error, unsupported course code ${courseCode}`);
        }
    }

    get(config: UIConfig) {
        return this.configs.get(config);
    }
}