import './selected-subject-info-panel.cmpt.scss';
import {Msg, Subject} from "../../../../browse-subjects/subject";
import {Status} from "../../../../status";
import {StringUtil} from "../../../../shared/util";
import {Given as CoursePlan_Given} from "../../../course-plan";
import * as React from "react";

const MAX_OVERVIEW_LENGTH = 180;

function buildNestedMessages(msg: Msg, pIndex: number) {
    const msgEles: Array<JSX.Element> = [<span className="selected-subject-info-panel__details__item-heading" key={`${pIndex}-heading`}>{msg.heading}</span>];
    msg.msgs.forEach((m: string, index: number) => {
        msgEles.push(
            <span className="selected-subject-info-panel__details__item-sub" key={`${pIndex}-${index}`}>{m}</span>
        );
    });
    return msgEles;
}

function buildMessages(msgs: Array<string | Msg>) {
    return msgs.map((msg, index) =>
        <li className="selected-subject-info-panel__details__item" key={index}>
            {(typeof msg === 'string') ? msg : buildNestedMessages(msg, index)}
        </li>)
}

function UnmetInfoCmpt({subject, msgs}) {
    return (
        <div className="selected-subject-info-panel selected-subject-info-panel--unmet">
            <div className="selected-subject-info-panel__headline">{subject.state.error}</div>
            <div className="selected-subject-info-panel__details">
                <ul className="selected-subject-info-panel__details__items">
                    {buildMessages(msgs)}
                </ul>
            </div>
        </div>
    );
}

function MetInfoCmpt({subject}) {
    const overviewText = subject.info.overview_text;
    const overview = <>{overviewText.length > MAX_OVERVIEW_LENGTH ? `${StringUtil.extract(overviewText, MAX_OVERVIEW_LENGTH, ' ')} ...` : overviewText}</>;
    const moreEle =
        <>
            {
                overviewText.length > MAX_OVERVIEW_LENGTH
                    // eslint-disable-next-line
                    ? <a href={CoursePlan_Given.subject(subject).handbookUrl()} target="_blank" className="selected-subject-info-panel__details__more">more information</a>
                    : ''
            }
        </>;
    return (
        <div className="selected-subject-info-panel selected-subject-info-panel--met">
            <div className="selected-subject-info-panel__details">
                {overview}
                {moreEle}
            </div>
        </div>
    );
}

function OpenInfoCmpt({subject, msgs}) {

    return (
        <div className="selected-subject-info-panel selected-subject-info-panel--open">
            <div className="selected-subject-info-panel__headline">{subject.state.warning}</div>
            <div className="selected-subject-info-panel__details">
                <ul className="selected-subject-info-panel__details__items">
                    {buildMessages(msgs)}
                </ul>
            </div>
        </div>
    );
}

export interface SelectedSubjectInfoPanelCmptProps {
    subject: Subject;
    selectionClass: string;
}


export function SelectedSubjectInfoPanelCmpt({subject, selectionClass}: SelectedSubjectInfoPanelCmptProps) {

    if (selectionClass === 'favourite') {
        return null;
    }

    const {status, msgs = []} = subject.state;
    switch (status) {
        case Status.unmet:
            return <UnmetInfoCmpt subject={subject} msgs={msgs}/>;
        case Status.met:
            return <MetInfoCmpt subject={subject}/>;
        case Status.open:
            return <OpenInfoCmpt subject={subject} msgs={msgs}/>;
        default:
            break;
    }
    return null;
}