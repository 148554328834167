import {CoursePlan, CourseStatus} from "../../course-planner/course-plan/course-plan";

const COURSE_TEMPLATE: CoursePlan = {
    saveAs: 'UNTITLED',
    status: CourseStatus.in_progress,
    vceSubjects: [],
    msgs: [],
    coursePlanReq: {
        breadthId: 'commerce_breadth',
        vceSubjects: [
            "Algorithmics",
            "Biology",
            "Chemistry",
            "English",
            "English (ESL)",
            "Further Mathematics",
            "Latin",
            "LOTE: Italian",
            "LOTE: French",
            "LOTE:Indonesian First Lang",
            "LOTE:Indonesian Second Lang",
            "Mathematical Methods",
            "Mathematical Methods (CAS)",
            "Physics",
            "Specialist Mathematics"
        ],
        pointsReq: 300,
        majorReq: {selection: {min: 1, max: 1}},
        yearReq: [
            {
                year: 1,
                semesters: [
                    {name: 'Semester 1', points: 50, standard: true},
                    {name: 'Winter Term', points: 25, standard: false},
                    {name: 'Semester 2', points: 50, standard: true},
                    {name: 'Summer Term', points: 25, standard: false}
                ]
            },
            {
                year: 2,
                semesters: [
                    {name: 'Semester 1', points: 50, standard: true},
                    {name: 'Winter Term', points: 25, standard: false},
                    {name: 'Semester 2', points: 50, standard: true},
                    {name: 'Summer Term', points: 25, standard: false},
                ]
            },
            {
                year: 3,
                semesters: [
                    {name: 'Semester 1', points: 50, standard: true},
                    {name: 'Winter Term', points: 25, standard: false},
                    {name: 'Semester 2', points: 50, standard: true},
                    {name: 'Summer Term', points: 25, standard: false}
                ]
            }
        ]
    },
    course: {
        code: 'bcom',
        name: 'Bachelor of Commerce',
        yearStart: (new Date().getMonth() >= 11 ? new Date().getFullYear() + 1 : new Date().getFullYear()),
        state: {status: CourseStatus.in_progress, warning: null, msgs: []},
        subjects: [],
        outcomes: []
    }
}
export {COURSE_TEMPLATE};

