export class ProgressiveLoadingService<T> {

    private readonly loading: Loading;

    constructor(private _itemFetcher: () => Array<T>,
                private _initialIndex: number,
                private _pageSize: number = 5) {
        this.loading = new Loading(false, new Date());
    }

    get inProgress(): boolean {
        return this.loading.inProgress;
    }

    start() {
        if (this.hasMore()) {
            this._initialIndex += this._pageSize;
        }
        this.loading.start();
    }

    done() {
        this.loading.done();
    }

    hasMore(): boolean {
        return this._initialIndex < this._itemFetcher().length;
    }

    getItems(): Array<T> {
        // NOTE: javascript does not blow up when the 'end' param is greater than array size
        return this._itemFetcher().slice(0, this._initialIndex);
    }
}


class Loading {
    constructor(private _inProgress: boolean,
                private _lastUpdate: Date) {
    }

    get inProgress(): boolean {
        return this._inProgress;
    }

    start() {
        this._inProgress = true;
    }

    done() {
        this._inProgress = false;
        this._lastUpdate = new Date();
    }
}