import './collapsible-list.cmpt.scss';

import * as React from "react";
import {CollapsibleListItemCmpt} from "./collapsible-list-item/collapsible-list-item.cmpt";

export interface CollapsibleListCmptProps {
    renderingItems: () => Array<JSX.Element>
}

export function CollapsibleListCmpt({renderingItems}: CollapsibleListCmptProps) {
    return (
        <div className="collapsible-list">
            <div className="collapsible-list__list-items">
                {renderingItems().map(item => <CollapsibleListItemCmpt children={item} key={item.key}/>)}
            </div>
        </div>
    );
}