export interface CompletionRequirement {
    breadthId: string;
    vceSubjects: Array<string>;
    pointsReq: 300;
    yearReq: Array<YearRequirement>;
    majorReq: MajorRequirement;
}

export interface YearRequirement {
    year: number;
    semesters: Array<SemesterRequirement>;
}

export interface SemesterRequirement {
    name: string;
    points: number;
    standard: boolean;
}

export interface MajorRequirement {
    selection: { min: number, max: number };
}

export class CourseYearUtil {
    constructor(private courseYear: YearRequirement) {

    }

    getRequiredPoints() {
        return this.courseYear.semesters
            .filter(semester => !!semester.standard)
            .map(semester => semester.points)
            .reduce((total, required) => total + required)
    }

    isFirstYear() {
        return this.courseYear.year === 1;
    }
}