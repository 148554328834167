import {CoursePlan, Given} from "./course-plan/course-plan";
import {Outcome} from "./outcomes/outcome";
import {EventBus, Events} from "./shared/services/event-bus.service";


export class MyMajors {

    constructor(private coursePlan: CoursePlan) {
    }

    private removeOutcome(major: Outcome) {
        let outcomes = this.coursePlan.course.outcomes;
        let removalIndex = outcomes.indexOf(major);
        if (removalIndex !== -1) {
            outcomes.splice(removalIndex, 1);
        }
    }

    selectMajor(major: Outcome) {
        if (!!major.userPref.selected) {
            return false;
        }
        const coursePlan = this.coursePlan;
        const selectedMajors = Given.outcomes(coursePlan.course.outcomes).findSelected().get();
        const majorReq = coursePlan.coursePlanReq.majorReq;

        if ((selectedMajors.length + 1) > majorReq.selection.max) {
            this.unSelectMajor(selectedMajors[0]);
            // TODO: move this event into store object
            EventBus.fire({name: Events.MAJOR_HAS_BEEN_REPLACED, payload: {from: selectedMajors[0], to: major}});
        }

        major.userPref.selected = true;
        if (coursePlan.course.outcomes.indexOf(major) === -1) {
            coursePlan.course.outcomes.push(major);
        }
        return true;
    }

    unSelectMajor(major: Outcome) {
        if (!major.userPref.selected) {
            return false;
        }

        major.userPref.selected = false;
        major.userPref.specialisation.selected = null;
        if (!major.userPref.favourite) {
            this.removeOutcome(major);
        }

        return true;
    }

    selectSpecialisation(outcome: Outcome, special: Outcome) {
        const userPref = outcome.userPref;
        const index = outcome.specialisations.indexOf(special);
        if (index !== -1) {
            this.selectMajor(outcome);
            userPref.specialisation.selected = special;
            return true;
        }
        return true;
    }

    unSelectSpecialisation(outcome: Outcome, special: Outcome) {
        const index = outcome.specialisations.indexOf(special);
        if (index !== -1) {
            this.unSelectMajor(outcome);
            return true;
        }
        return false;
    }

    favouriteMajor(major: Outcome) {
        if (major.userPref.favourite === true) {
            return false;
        }

        major.userPref.favourite = true;
        if (this.coursePlan.course.outcomes.indexOf(major) === -1) {
            this.coursePlan.course.outcomes.push(major);
        }
        return true;
    }

    unFavouriteMajor(major: Outcome) {
        major.userPref.favourite = false;
        major.userPref.specialisation.favourite = null;
        if (!major.userPref.selected) {
            this.removeOutcome(major);
            return true;
        }
        return false;
    }

    favouriteSpecialisation(outcome: Outcome, special: Outcome) {
        const userPref = outcome.userPref;
        const index = outcome.specialisations.indexOf(special);
        if (index !== -1) {
            if (!outcome.userPref.favourite) {
                this.favouriteMajor(outcome);
            }
            userPref.specialisation.favourite = special;
            return true;
        }
        return false;
    }

    unFavouriteSpecialisation(outcome: Outcome, special: Outcome) {
        const index = outcome.specialisations.indexOf(special);
        if (index !== -1) {
            this.unFavouriteMajor(outcome);
            return true;
        }
        return false;
    }
}