import {CoursePlan, Given} from "./course-plan/course-plan";
import {CourseHandbookStore} from "./shared/stores/course-handbook.store";
import {Subject} from "./browse-subjects/subject";
import {Status} from "./status";


export class MySubjects {

    constructor(private coursePlan: CoursePlan,
                private courseHandbook: CourseHandbookStore) {

    }

    private removeSubject(selectedSubject: Subject) {
        const coursePlan = this.coursePlan;
        let selectedSubjects = coursePlan.course.subjects;
        let removalIndex = selectedSubjects.indexOf(selectedSubject);
        if (removalIndex !== -1) {
            selectedSubjects.splice(removalIndex, 1);
            selectedSubject.userPref.semester = null;
            selectedSubject.userPref.year = null;
            selectedSubject.userPref.selected = false;
            selectedSubject.userPref.favourite = false;
        }
    }

    selectSubject(selectedSubject: Subject) {
        const coursePlan = this.coursePlan;
        const selectedSubjects = coursePlan.course.subjects;
        const exists = selectedSubjects.filter((s) => s.code === selectedSubject.code).length !== 0;
        const whichYearAndSemester = Given.coursePlan(coursePlan).whichYearAndSemesterToAdd(selectedSubject);

        if (!selectedSubject.userPref.selected) {
            selectedSubject.userPref.selected = true;
            selectedSubject.userPref.year = whichYearAndSemester.year;
            selectedSubject.userPref.semester = whichYearAndSemester.semester;
        }
        if (!exists) {
            selectedSubjects.push(selectedSubject);
        }
    }

    selectSubjects(selectedSubjects: Array<Subject>) {
        const clone = selectedSubjects.map((s) => s);
        clone.forEach(subject => this.selectSubject(subject));
    }

    unSelectSubject(selectedSubject: Subject) {
        selectedSubject.userPref.selected = false;
        selectedSubject.userPref.year = null;
        selectedSubject.state.status = Status.unknown;
        if (!selectedSubject.userPref.favourite) {
            this.removeSubject(selectedSubject);
        }
    }

    unSelectSubjects(selectedSubjects: Array<Subject>) {
        const clone = selectedSubjects.map((s) => s);
        clone.forEach(subject => this.unSelectSubject(subject));
    }

    clearAllSubjects() {
        const clone = this.coursePlan.course.subjects.map((s) => s);
        clone.forEach(subject => {
            this.unSelectSubject(subject);
            this.unFavouriteSubject(subject);
        });
    }

    favouriteSubject(favouriteSubject: Subject) {
        const coursePlan = this.coursePlan;
        let selectedSubjects = coursePlan.course.subjects;
        let exists = selectedSubjects.filter((s) => s.code === favouriteSubject.code).length !== 0;

        favouriteSubject.userPref.favourite = true;

        if (!exists) {
            selectedSubjects.push(favouriteSubject);
        }
    }

    unFavouriteSubject(favouriteSubject: Subject) {
        favouriteSubject.userPref.favourite = false;
        if (!favouriteSubject.userPref.selected) {
            this.removeSubject(favouriteSubject);
        }
    }

    unFavouriteSubjects(favouritedSubjects: Array<Subject>) {
        const clone = favouritedSubjects.map((s) => s);
        clone.forEach(subject => {
            this.unFavouriteSubject(subject);
        });
    }

    moveSubjectToYear(subjectCode: string, toYear: number, toSemester: string, insertSubjectCode: string, position: string) {
        const coursePlan = this.coursePlan;
        let movingSubject = Given.subjects(coursePlan.course.subjects).findByCodes([subjectCode])[0];
        if (!movingSubject) {
            movingSubject = Given.subjects(this.courseHandbook.subjects).findByCodes([subjectCode])[0];
            this.selectSubject(movingSubject);
        }
        movingSubject.userPref.year = toYear;
        movingSubject.userPref.semester = toSemester;
        movingSubject.userPref.selected = true;

        if (!!insertSubjectCode && !!position) {
            coursePlan.course.subjects.splice(coursePlan.course.subjects.indexOf(movingSubject), 1);

            const insertSubject = Given.subjects(coursePlan.course.subjects).findByCodes([insertSubjectCode])[0];
            let insertIndex = coursePlan.course.subjects.indexOf(insertSubject);
            insertIndex = (position === 'before') ? insertIndex : insertIndex + 1;
            if (insertIndex === 0) {
                coursePlan.course.subjects.splice(insertIndex, 0, movingSubject);
            } else {
                coursePlan.course.subjects.splice(insertIndex, 0, movingSubject);
            }
        }
    }

    moveSubjectToFavourite(subjectCode: string) {
        const coursePlan = this.coursePlan;
        let selectedSubject = Given.subjects(coursePlan.course.subjects).findByCodes([subjectCode])[0];
        if (!selectedSubject) {
            selectedSubject = Given.subjects(this.courseHandbook.subjects).findByCodes([subjectCode])[0];
            this.favouriteSubject(selectedSubject);

        } else {
            if (!!selectedSubject.userPref.favourite) {
                return;
            }
            selectedSubject.userPref.year = null;
            selectedSubject.userPref.selected = false;
            selectedSubject.userPref.favourite = true;
            selectedSubject.userPref.semester = null;
        }
    }

    load(subjectCode: string, resp) {
        const subjectDetails: Subject = resp.data;
        const subject = Given.subjects(this.courseHandbook.subjects).findByCodes([subjectDetails.code])[0];
        // update subject details
        subject.info.overview_text = subjectDetails.info.overview_text;
        subject.info.requisites_html = Given.subject(subjectDetails).cleanseEligibilityAndRequirements();
        subject.info.debug = subjectDetails.info.debug;
    }
}