import './subjects-by-category.cmpt.scss';
import {ByCategory, Subject} from "../../subject";
import * as React from "react";
import {ClickableCmpt} from "../../../shared/components/clickable/clickable.cmpt";
import {Given} from "../../../course-plan/course-plan";
import {SubjectsByLevelsCmpt} from "./subjects-by-levels/subjects-by-levels.cmpt";
import {MyCoursePlansStore} from "../../../shared/stores/my-course-plans.store";
import {CourseHandbookStore} from "../../../shared/stores/course-handbook.store";
import {Type as SuggestionType} from "../../../course-plan/suggestions";


export interface SubjectsByCategoryCmptProps {
    subjectsByCategory: ByCategory;
    expandSubject: string;
    category_uuid: string;
    onSubjectExpandChange: (category_uuid: string, subjectCode: string) => void;
    myCoursePlans: MyCoursePlansStore;
    courseHandBook: CourseHandbookStore;
}


function selectAllSubjects(myCoursePlans: MyCoursePlansStore, subjects: Array<Subject>): void {
    myCoursePlans.selectSubjects(subjects);
}

function unSelectAllSubjects(myCoursePlans: MyCoursePlansStore, subjects: Array<Subject>): void {
    myCoursePlans.unSelectSubjects(subjects);
}

function areAllSubjectSelected(subjects: Array<Subject>) {
    return Given.subjects(subjects).areAllSelected();
}


function translateHeaderText(category: string, courseHandBook: CourseHandbookStore) {
    if (category.indexOf('(Associated)') !== -1) {
        category = category.replace('(Associated)', '(Other Potential Pre-Requisites)');
    }
    try {
        if (category.indexOf('(Selective for ') !== -1) {
            let code = /(\(Selective for )(.*)(\))/g.exec(category)[2];
            let name = Given.subjects(courseHandBook.subjects).findByCodes([code])[0].info.name;
            category = category.replace(`(Selective for ${code})`, `(Selective for ${name})`);
        }
    } catch (e) {
        console.error(`unable to find subject match, headerText=${category}`, e);
    }
    return category
}

function toDisplay(category: string) {
    const displayValues = category.split(" (");
    if (displayValues.length === 1) {
        displayValues.push("");
    } else {
        displayValues[1] = ` (${displayValues[1]}`
    }
    return displayValues;
}

export function SubjectsByCategoryCmpt(args: SubjectsByCategoryCmptProps) {

    // TODO: refactor this to use proper logic from backend
    const {subjectsByCategory, category_uuid, expandSubject, onSubjectExpandChange} = args;
    const {category, subjects, type} = subjectsByCategory;
    const {myCoursePlans, courseHandBook} = args;
    const categoryParts = toDisplay(translateHeaderText(category, courseHandBook));
    const insertSelectAllOption = type === SuggestionType.compulsory;
    const areAllSelected = areAllSubjectSelected(subjects);

    return (
        <div className="subjects-by-category">
            <div className="subjects-by-category__header">
                {categoryParts[0]} <span className="subjects-by-category__header__info">{categoryParts[1]}</span>
                {insertSelectAllOption ?
                    <ClickableCmpt content={
                        <div className={`subjects-by-category__header__add-all ${areAllSelected ? 'subjects-by-category__header__add-all--selected' : ''}`}>
                                <span className="subjects-by-category__header__add-all-text">
                                    {areAllSelected ? 'REMOVE ALL' : 'ADD ALL'}
                                </span>
                            <span className="material-icons dp48">
                                    {areAllSelected ? 'remove' : 'add'}
                                </span>
                        </div>
                    } onclick={areAllSelected ? ()=>unSelectAllSubjects(myCoursePlans, subjects) : ()=>selectAllSubjects(myCoursePlans, subjects)}/>
                    : null}
            </div>
            <SubjectsByLevelsCmpt subjects={subjects}
                                  category_uuid={category_uuid}
                                  expandSubject={expandSubject}
                                  onSubjectExpandChange={onSubjectExpandChange} key={category_uuid}/>
        </div>
    );
}






