import {action, computed, observable} from "mobx";


import {COURSE_TEMPLATE as BSCI_COURSE} from './../../../configs/bsci/course-plan.config';
import {COURSE_TEMPLATE as BCOM_COURSE} from './../../../configs/bcom/course-plan.config';
import {COURSE_TEMPLATE as BA_COURSE} from './../../../configs/ba/course-plan.config';
import {PlanSimulatorService} from "./../services/plan-simulator.service";
import {PlanValidatorService} from "./../services/plan-validator.service";
import {CoursePlan, CourseStatus, Given} from "../../course-plan/course-plan";
import {Suggestions} from "../../course-plan/suggestions";
import {MyCoursePlansService} from "../services/my-course-plans.service";
import {CourseHandbookStore} from "./course-handbook.store";
import {Outcome} from "../../outcomes/outcome";
import {Subject, VCESubject} from "../../browse-subjects/subject";
import {MyCoursePlan} from "./../../my-course-plan";
import {SubjectLoaderService} from "../services/subject-loader.service";

export const COURSE_TEMPLATES = {
    "BSCI": BSCI_COURSE,
    "BCOM": BCOM_COURSE,
    "BA": BA_COURSE
};

export class MyCoursePlansStore {

    @observable coursePlan: CoursePlan = null;
    @observable suggestions: Suggestions = {outcomes: [], sets: []};

    private myCoursePlan: MyCoursePlan;

    private planSimulator: PlanSimulatorService;
    private planValidator: PlanValidatorService;
    private subjectLoader: SubjectLoaderService;

    /**
     * TODO: split functionality of my-course-plans and course-plan
     */
    constructor(private myCoursePlansService: MyCoursePlansService,
                private courseHandbook: CourseHandbookStore,
                private courseCode: string) {

        let course_template = COURSE_TEMPLATES[courseCode.toUpperCase()];
        if (!course_template) {
            throw new Error(`Error, unsupported course code ${courseCode}`);
        }
        this.coursePlan = Object.assign({}, course_template);

        this.planValidator = new PlanValidatorService(myCoursePlansService);
        this.planSimulator = new PlanSimulatorService(myCoursePlansService);
        this.subjectLoader = new SubjectLoaderService(myCoursePlansService);
        this.myCoursePlan = new MyCoursePlan(this.courseCode, this.coursePlan, this.courseHandbook);
    }

    private validateAndSimulate() {
        this.validate();
        this.planSimulator.simulate(this.coursePlan, this.updateSimulation, (e) => console.error(e));
    }

    private validate() {
        this.planValidator.validate(this.coursePlan, this.updateCoursePlan, (e) => console.error(e));
    }

    @computed
    get isValidCoursePlan() {
        return Given.coursePlan(this.coursePlan).getCourseStatus() !== CourseStatus.invalid;
    }

    @computed
    get courseTotalPoints() {
        const selectedSubjects = Given.subjects(this.coursePlan.course.subjects).findSelected();
        return Given.subjects(selectedSubjects).getTotalPoints();
    }

    @action.bound
    updateCoursePlan(resp: any) {
        this.myCoursePlan.updateCoursePlan(resp);
        this.suggestions = observable.object(resp.data.suggestions);
    }

    @action.bound
    updateSimulation(resp: any) {
        this.myCoursePlan.updateSimulation(resp);
    }

    @action
    loadSubjectDetails(subjectCode: string) {

        const subject = Given.subjects(this.courseHandbook.subjects).findByCodes([subjectCode])[0];
        if (!!subject.info.overview_text && !!subject.info.requisites_html) {
            return;
        }

        this.subjectLoader.load(
            subjectCode,
            (resp) => this.myCoursePlan.mySubjects.load(subjectCode, resp),
            (error) => {
                console.error(error);
            });
    }

    @computed
    get vceSubjects(): Array<VCESubject> {
        return this.myCoursePlan.vceSubjects
    }

    @computed
    get majors(): Array<Outcome> {
        return this.myCoursePlan.majors;
    }

    @computed
    get selectedMajors(): Array<Outcome> {
        return this.myCoursePlan.selectedMajors;
    }

    @computed
    get favouritedMajors(): Array<Outcome> {
        return this.myCoursePlan.favouritedMajors;
    }

    @computed
    get selectedSubjects(): Array<Subject> {
        return this.myCoursePlan.selectedSubjects;
    }

    @computed
    get favouritedSubjects(): Array<Subject> {
        return this.myCoursePlan.favouritedSubjects;
    }

    @action
    addVCESubject(vceSubject: VCESubject) {
        if (this.myCoursePlan.myEligibility.add(vceSubject)) {
            this.validateAndSimulate();
        }
    }

    @action
    removeVCESubject(vceSubject: VCESubject) {
        if (this.myCoursePlan.myEligibility.remove(vceSubject)) {
            this.validateAndSimulate();
        }
    }

    @action
    selectMajor(major: Outcome) {
        if (this.myCoursePlan.myMajors.selectMajor(major)) {
            this.validate();
        }
    }

    @action
    unSelectMajor(major: Outcome) {
        if (this.myCoursePlan.myMajors.unSelectMajor(major)) {
            this.validate();
        }
    }

    @action
    selectSpecialisation(outcome: Outcome, special: Outcome) {
        if (this.myCoursePlan.myMajors.selectSpecialisation(outcome, special)) {
            this.validate();
        }
    }

    @action
    unSelectSpecialisation(outcome: Outcome, special: Outcome) {
        if (this.myCoursePlan.myMajors.unSelectSpecialisation(outcome, special)) {
            this.validate();
        }
    }

    @action
    favouriteMajor(major: Outcome) {
        if (this.myCoursePlan.myMajors.favouriteMajor(major)) {
            this.validate();
        }
    }

    @action
    unFavouriteMajor(major: Outcome) {
        if (this.myCoursePlan.myMajors.unFavouriteMajor(major)) {
            this.validate();
        }
    }

    @action
    favouriteSpecialisation(outcome: Outcome, special: Outcome) {
        if (this.myCoursePlan.myMajors.favouriteSpecialisation(outcome, special)) {
            this.validate();
        }
    }

    @action
    unFavouriteSpecialisation(outcome: Outcome, special: Outcome) {
        if (this.myCoursePlan.myMajors.unFavouriteSpecialisation(outcome, special)) {
            this.validate();
        }

    }

    @action
    selectSubject(selectedSubject: Subject) {
        this.myCoursePlan.mySubjects.selectSubject(selectedSubject);
        this.validateAndSimulate();
    }

    @action
    selectSubjects(selectedSubjects: Array<Subject>) {
        this.myCoursePlan.mySubjects.selectSubjects(selectedSubjects)
        this.validateAndSimulate();
    }

    @action
    unSelectSubject(selectedSubject: Subject) {
        this.myCoursePlan.mySubjects.unSelectSubject(selectedSubject);
        this.validateAndSimulate();
    }

    @action
    unSelectSubjects(selectedSubjects: Array<Subject>) {
        this.myCoursePlan.mySubjects.unSelectSubjects(selectedSubjects);
        this.validateAndSimulate();
    }

    @action
    clearAllSubjects() {
        this.myCoursePlan.mySubjects.clearAllSubjects();
        this.validateAndSimulate();
    }

    @action
    favouriteSubject(favouriteSubject: Subject) {
        this.myCoursePlan.mySubjects.favouriteSubject(favouriteSubject);
        //this.validate(this.currentCoursePlan);
    }

    @action
    unFavouriteSubject(favouriteSubject: Subject) {
        this.myCoursePlan.mySubjects.unFavouriteSubject(favouriteSubject);
        //this.validate(this.currentCoursePlan);
    }

    @action
    unFavouriteSubjects(favouritedSubjects: Array<Subject>) {
        this.myCoursePlan.mySubjects.unFavouriteSubjects(favouritedSubjects);
        //this.validate(this.currentCoursePlan);
    }

    @action
    moveSubjectToYear(subjectCode: string, toYear: number, toSemester: string, insertSubjectCode: string, position: string, simulate: boolean) {
        this.myCoursePlan.mySubjects.moveSubjectToYear(subjectCode, toYear, toSemester, insertSubjectCode, position);

        if (simulate) {
            this.validateAndSimulate();
        } else {
            this.validate();
        }
    }

    @action
    moveSubjectToFavourite(subjectCode: string) {
        const coursePlan = this.coursePlan;
        let selectedSubject = Given.subjects(coursePlan.course.subjects).findByCodes([subjectCode])[0];
        this.myCoursePlan.mySubjects.moveSubjectToFavourite(subjectCode);
        if (!!selectedSubject) {
            this.validateAndSimulate();
        }
    }

    @action
    loadSave(savedHex: any) {
        this.myCoursePlan.loadSave(savedHex);
        this.validateAndSimulate();
    }

    @action
    generateSave(): string {
        return this.myCoursePlan.generateSave();
    }
}


