import {MyCoursePlansService} from "./my-course-plans.service";


export class SubjectLoaderService {

    constructor(private myCoursePlansService: MyCoursePlansService) {
    }

    load(subjectCode: string, onSuccess, onFailure) {
        this.myCoursePlansService
            .fetchSubjectDetails(subjectCode)
            .then(onSuccess)
            .catch(onFailure);
    }
}