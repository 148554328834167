import {toJS} from "mobx";
import {MyCoursePlansService} from "./my-course-plans.service";
import {CoursePlan} from "../../course-plan/course-plan";
import {EventBus, Events} from "./event-bus.service";

export class PlanValidatorService {

    constructor(private myCoursePlansService: MyCoursePlansService) {
    }

    validate(coursePlan: CoursePlan, onSuccess, onFailure: (e) => void = () => {}) {

        const fireUpdateEvent = () =>
            setTimeout(() =>
                EventBus.fire({name: Events.VALIDATING_COURSE_PLAN_UPDATE_UI}));

        const coursePlanJson = toJS(coursePlan);

        this.myCoursePlansService
            .validate(coursePlanJson)
            .then(onSuccess)
            .then(fireUpdateEvent)
            .catch((e) => {
                onFailure(e);
                fireUpdateEvent();
            });
    }
}