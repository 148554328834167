import './course-semester.cmpt.scss';

import {Subject} from "../../../../browse-subjects/subject";
import {SelectedSubjectCmpt} from "../../selected-subject/selected-subject.cmpt";
import * as React from "react";
import {Given, Given as CoursePlan_Given} from "../../../course-plan";
import {FeatureSupportStore} from "../../../../shared/stores/feature-support.store";
import {inject, observer} from "mobx-react";
import {Droppable} from "react-beautiful-dnd";
import {SelectedSubjectsCmpt} from "../../selected-subjects.cmpt";
import {ClickableCmpt} from "../../../../shared/components/clickable/clickable.cmpt";
import {SemesterRequirement, YearRequirement} from "../../../course-plan.completion-requirement";
import {EventBus, EventRegistrationHandle, Events} from "../../../../shared/services/event-bus.service";
import {MyCoursePlansStore} from "../../../../shared/stores/my-course-plans.store";
import {CourseHandbookStore} from "../../../../shared/stores/course-handbook.store";
import {StringUtil} from "../../../../shared/util";
import {UiStateStore} from "../../../../shared/stores/ui/ui-state.store";
import {From} from "../../../../shared/stores/ui/drag-data.vm";


function SubjectsBySemesterCmpt({subjects, yearRequirement, semesterRequirement, unSelectSubject, newSubjectCodes,}) {
    return (
        <>
            {
                subjects.map((subject, index) =>
                    <SelectedSubjectCmpt selectedSubject={subject}
                                         selectionClass={`year_${semesterRequirement.name}`}
                                         index={index}
                                         key={`${subject.code}`}
                                         onUnSelect={unSelectSubject}
                                         yearRequirement={yearRequirement}
                                         semesterRequirement={semesterRequirement}
                                         newSubjectCodes={newSubjectCodes}/>
                )
            }
        </>
    );
}


export interface CourseSemesterCmptProps {
    yearRequirement: YearRequirement;
    semesterRequirement: SemesterRequirement;
    myCoursePlans?: MyCoursePlansStore;
    featureSupport?: FeatureSupportStore;
    courseHandBook?: CourseHandbookStore;
    uiState?: UiStateStore;
}

interface CourseSemesterCmptState {
    isDragOver: boolean;
    isDroppable: boolean;
}

@inject('myCoursePlans', 'featureSupport', 'courseHandBook', 'uiState')
@observer
export class CourseSemesterCmpt extends React.Component<CourseSemesterCmptProps, CourseSemesterCmptState> {

    private subjectsBySemester: Array<Subject> = [];
    private reorderSubjectEventRegHandler: EventRegistrationHandle;


    constructor(props: CourseSemesterCmptProps, context: any) {
        super(props, context);

        this.reorderSubjectEventRegHandler = EventBus.register(Events.REORDERING_SELECTED_SUBJECT, (payload) => {
            if (!!payload) {
                const {uiState, semesterRequirement} = this.props;
                if (!Given.subject(uiState.dragData.dragSubject).isScheduleValid(semesterRequirement.name)) {
                    this.setState({isDroppable: false});
                }
            } else {
                this.setState({isDragOver: false, isDroppable: true});
            }
        });

        this.state = {isDragOver: false, isDroppable: true};

        this.unSelectSubject = this.unSelectSubject.bind(this);
    }

    private unSelectSubject(subject: Subject) {
        this.props.myCoursePlans.unSelectSubject(subject);
    }

    private unSelectAll() {
        this.props.myCoursePlans.unSelectSubjects(this.subjectsBySemester);
    }

    onHtmlDrop() {
        const dragData = this.props.uiState.dragData;
        const subjectCode = dragData.dragSubject.code;
        const insertSubject = dragData.insertSubject || {code: undefined};

        this.props.myCoursePlans.moveSubjectToYear(subjectCode,
            this.props.yearRequirement.year,
            this.props.semesterRequirement.name,
            insertSubject.code,
            dragData.position,
            dragData.from === From.favourite ? true : false
        );

        this.props.uiState.dragData = null;
        this.setState({isDragOver: false, isDroppable: true});
        EventBus.fire({name: Events.REORDERING_SELECTED_SUBJECT, payload: false});
    }

    onHtmlDragOver(event: DragEvent) {
        event.preventDefault();
        const {isDroppable} = this.state;

        event.dataTransfer.dropEffect = 'none';
        event.dataTransfer.effectAllowed = 'none';
        if (isDroppable) {
            event.dataTransfer.dropEffect = 'copy';
            event.dataTransfer.effectAllowed = 'copy';
        }

        this.setState({isDragOver: true});
        return false;
    }

    onHtmlDragLeave(event: DragEvent) {
        event.preventDefault();
        this.setState({isDragOver: false});
    }

    onHtmlDragEnter(event: DragEvent) {
        event.preventDefault();
        this.onHtmlDragOver(event);
        return false;
    }


    private getSubjectsBySemester(subjects, yearRequirement, semesterRequirement) {
        return CoursePlan_Given
            .subjects(subjects)
            .findByCourseSemester(
                yearRequirement.year, semesterRequirement.name
            );
    }

    render() {
        const {isDragOver, isDroppable} = this.state;
        const {semesterRequirement, yearRequirement, featureSupport, myCoursePlans} = this.props;
        const newSubjectsBySemester = this.getSubjectsBySemester(myCoursePlans.selectedSubjects, yearRequirement, semesterRequirement);
        const newSubjectCodes = Given.subjects(this.subjectsBySemester).getAdded(newSubjectsBySemester);
        const noSubjectSelectedForSemester = newSubjectsBySemester.length === 0;

        this.subjectsBySemester = newSubjectsBySemester;

        const html5Dnd = featureSupport.htmlDnD ? {
            onDrop: this.onHtmlDrop.bind(this),
            onDragOver: this.onHtmlDragOver.bind(this),
            onDragEnter: this.onHtmlDragEnter.bind(this),
            onDragLeave: this.onHtmlDragLeave.bind(this)
        } : {};

        let className = `course-semester
                         ${noSubjectSelectedForSemester ? 'course-semester--no-subjects' : ''}
                         ${!semesterRequirement.standard ? 'course-semester--non-standard-semester' : ''}
                         ${!isDroppable ? 'course-semester--not-droppable' : ''}`;
        return (
            <div className={StringUtil.ensureOneLine(className)}>
                <div className="course-semester__header">
                    <span className="course-semester__header__semester">{semesterRequirement.name.toUpperCase()}</span>
                    <span className="course-semester__clear">
                        <ClickableCmpt
                            content={
                                <div className="course-semester__clear__wrapper">
                                    <div className="course-semester__clear__text">CLEAR</div>
                                    <div className="material-icons dp48">delete</div>
                                </div>
                            }
                            onclick={this.unSelectAll.bind(this)}/>
                    </span>
                </div>
                <Droppable droppableId={`${yearRequirement.year}-${semesterRequirement.name}`}
                           type={SelectedSubjectsCmpt.DRAG_AND_DROP_TYPE}
                           isDropDisabled={featureSupport.htmlDnD}
                           ignoreContainerClipping={true}>
                    {
                        (provided, snapshot) => {
                            const dragHoverStyle = snapshot.isDraggingOver || isDragOver ? 'course-semester-subjects--draggable-over' : '';
                            const droppableStyle = !isDroppable ? 'course-semester-subjects--not-droppable' : '';
                            return (
                                <div className={`course-semester-subjects ${dragHoverStyle} ${droppableStyle}`}
                                     ref={(ref) => provided.innerRef(ref)}
                                     {...provided.droppableProps}
                                     {...html5Dnd}>
                                    {
                                        <SubjectsBySemesterCmpt subjects={this.subjectsBySemester}
                                                                yearRequirement={yearRequirement}
                                                                semesterRequirement={semesterRequirement}
                                                                unSelectSubject={this.unSelectSubject}
                                                                newSubjectCodes={newSubjectCodes}/>
                                    }
                                    {provided.placeholder}
                                </div>);
                        }
                    }
                </Droppable>
            </div>
        );
    }
}