import './../theme/style/reset.scss';
import './print-view.cmpt.scss';

import * as React from "react";
import {inject, observer} from "mobx-react";
import {EventBus, Events} from "../course-planner/shared/services/event-bus.service";
import {CoursePlan, Given} from "../course-planner/course-plan/course-plan";
import {Subject} from "../course-planner/browse-subjects/subject";
import {Outcome} from "../course-planner/outcomes/outcome";
import {SemesterRequirement, YearRequirement} from "../course-planner/course-plan/course-plan.completion-requirement";
import {MyCoursePlansStore} from "../course-planner/shared/stores/my-course-plans.store";


function LegendCmpt() {
    return (
        <div className="print-view__legends">
            <div className="print-view__legends__compulsory">
                <div className="print-view__legends__color-indicator"/>
                <div className="print-view__legends__text">Compulsory</div>
            </div>
            <div className="print-view__legends__elective">
                <div className="print-view__legends__color-indicator"/>
                <div className="print-view__legends__text">Elective</div>
            </div>
            <div className="print-view__legends__breadth">
                <div className="print-view__legends__color-indicator"/>
                <div className="print-view__legends__text">Breadth</div>
            </div>
        </div>
    );
}

function HeaderCmpt({
                        myCoursePlans
                    }: { myCoursePlans: MyCoursePlansStore }) {
    function getSpecialisation(selectedMajor: Outcome) {
        return !!selectedMajor && !!selectedMajor.userPref.specialisation.selected ? ` - ${selectedMajor.userPref.specialisation.selected.info.name}` : '';
    }

    function getMajorTitle(selectedMajor: Outcome) {
        const major = !!selectedMajor ? selectedMajor.info.name : '';
        return `${major}${getSpecialisation(selectedMajor)}.`
    }

    return (
        <div className="print-view__header">
            <div className="print-view__header__logo-and-legend">
                <div className="print-view__header__logo"></div>
                <LegendCmpt/>
            </div>
            <div className="print-view__header__course-title">
                <div className="print-view__header__course-title__course-name">
                    {myCoursePlans.coursePlan.course.name}
                </div>
                {
                    myCoursePlans.majors.map(selectedMajor =>
                        <div className="print-view__header__course-title__major" key={selectedMajor.code}>
                            {getMajorTitle(selectedMajor)}
                        </div>
                    )
                }
            </div>
        </div>
    );
}


function SemesterCmpt({
                          majors,
                          subjects,
                          breadthId,
                          year,
                          semester
                      }: { majors: Array<Outcome>, subjects: Array<Subject>, breadthId: string, year: YearRequirement, semester: SemesterRequirement }) {


    const subjectsByCourseSemester =
        Given.subjects(subjects)
            .findByCourseSemester(year.year, semester.name);

    if (subjectsByCourseSemester.length === 0 && semester.standard === false) {
        return null;
    }

    return (
        <div className="print-view__course-year__course-semester" key={`${year.year}-${semester.name}`}>
            <div className="print-view__course-year__course-semester__title">{semester.name}</div>
            <div>
                {
                    subjectsByCourseSemester
                        .map((subject: Subject) =>
                            <SubjectCmpt subject={subject} majors={majors} breadthId={breadthId} key={subject.code}/>
                        )
                }
            </div>
        </div>
    );
}


function YearCmpt({
                      coursePlan,
                      majors,
                      subjects,
                      breadthId,
                      courseYear
                  }: { coursePlan: CoursePlan, majors: Array<Outcome>, subjects: Array<Subject>, breadthId: string, courseYear: YearRequirement }) {

    return (
        <div className="print-view__course-year" key={courseYear.year}>
            <div className="print-view__course-year__border"></div>
            <div className="print-view__course-year__title">Year {courseYear.year} - {coursePlan.course.yearStart + (courseYear.year - 1)}</div>
            {
                courseYear.semesters.map((semester: SemesterRequirement) =>
                    <SemesterCmpt majors={majors}
                                  subjects={subjects}
                                  breadthId={breadthId}
                                  year={courseYear}
                                  semester={semester}
                                  key={semester.name}/>
                )
            }
        </div>
    );
}


function SubjectCmpt({
                         subject,
                         majors,
                         breadthId
                     }: { subject: Subject, majors: Array<Outcome>, breadthId: string }) {

    function getCategoryClassName(subject: Subject) {
        // if is a breadth subject
        let categoryClassName = 'print-view__course-year__course-semester__subject--elective';

        if (Given.subject(subject).isBreadth(breadthId, majors)) {
            categoryClassName = 'print-view__course-year__course-semester__subject--breadth';
        }

        if (!!majors && !!Given.subject(subject).isCompulsory(majors)) {
            categoryClassName = 'print-view__course-year__course-semester__subject--compulsory';
        }

        return categoryClassName;
    }

    return (
        <div className={`print-view__course-year__course-semester__subject ${getCategoryClassName(subject)}`} key={subject.code}>
            <div className="print-view__course-year__course-semester__subject-code">
                {subject.code}
            </div>
            <div className="print-view__course-year__course-semester__subject-name">
                {subject.info.name}
            </div>
        </div>
    )
}


interface PrintViewCmptProps {
    myCoursePlans?: MyCoursePlansStore;
}


@inject('myCoursePlans')
@observer
export class PrintViewCmpt extends React.Component<PrintViewCmptProps> {

    componentDidMount(): void {
        EventBus.fire({name: Events.APP_READY});
    }

    render() {
        const {myCoursePlans} = this.props;
        const coursePlan = myCoursePlans.coursePlan;
        const selectedMajors = myCoursePlans.selectedMajors;
        const selectedSubjects = myCoursePlans.selectedSubjects;
        const breadthId = myCoursePlans.coursePlan.coursePlanReq.breadthId;
        const completionRequirements = myCoursePlans.coursePlan.coursePlanReq;

        return (
            <div className="print-view">
                <HeaderCmpt myCoursePlans={myCoursePlans}/>
                <div className="print-view__content">
                    {
                        completionRequirements.yearReq.map((courseYear) =>
                            <YearCmpt coursePlan={coursePlan}
                                      majors={selectedMajors}
                                      subjects={selectedSubjects}
                                      breadthId={breadthId}
                                      courseYear={courseYear}
                                      key={courseYear.year}/>
                        )
                    }
                </div>
            </div>
        );
    }
}
