import {DragData} from "./drag-data.vm";


export class UiStateStore {
    private _dragData: DragData = null;

    get dragData() {
        return this._dragData;
    }

    set dragData(dragDataArg: DragData) {
        this._dragData = dragDataArg;
    }
}

