import './major-details-actions.scss'
import {ClickableCmpt} from "../../../shared/components/clickable/clickable.cmpt";
import * as React from "react";


const NOOP = () => {};

function SelectAndFavouriteIconsCmpt({isSelected, isFavourited, major, onFavouriteMajor, onSelectMajor}) {
    return (
        <>
            <ClickableCmpt content={
                <div className="major-details-actions__action major-details-actions__action__favourite material-icons dp48">
                    {isFavourited ? 'favorite' : 'favorite_border'}
                </div>
            } onclick={isFavourited ? NOOP : () => onFavouriteMajor(major)}/>
            <div className="major-details-actions__action__spacer"></div>
            <ClickableCmpt content={
                <div
                    className={`major-details-actions__action major-details-actions__action__select ${isSelected ? 'major-details-actions__action__select--selected' : ''} material-icons dp48`}>
                    {isSelected ? 'remove_circle_outline' : 'add_circle'}
                </div>
            } onclick={isSelected ? NOOP : () => onSelectMajor(major)}/>
            <div className="major-details-actions__action__spacer"></div>
        </>
    );
}

export function MajorDetailsActionsCmpt({isSelected, isFavourited, major, onSelectMajor, onFavouriteMajor, hideMajor, showSelectAndFavouriteIcons}) {

    return (
        <div className="major-details-actions">
            {showSelectAndFavouriteIcons
                ? <SelectAndFavouriteIconsCmpt isSelected={isSelected}
                                               isFavourited={isFavourited}
                                               major={major}
                                               onSelectMajor={onSelectMajor}
                                               onFavouriteMajor={onFavouriteMajor}/>
                : null}
            <ClickableCmpt
                content={
                    <div className="major-details-actions__action major-details-actions__action__close material-icons dp48">
                        close
                    </div>
                }
                onclick={() => hideMajor()}/>
        </div>
    );
}