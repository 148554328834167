import * as React from "react";

import {inject, observer} from "mobx-react";
import './my-course-plan.cmpt.scss';
import {SelectedSubjectsCmpt} from "./selected-subjects/selected-subjects.cmpt";
import {CourseStatus, Given} from "./course-plan";
import {CollapsibleCmpt} from "../shared/components/collapsible/collapsible.cmpt";
import {ClickableCmpt} from "../shared/components/clickable/clickable.cmpt";
import ReactDOM from "react-dom";
import {CourseEligibilityCmpt} from "./course-eligibility/course-eligibility.cmpt";
// import {CourseProgressBarCmpt} from "./course-progress-bar/course-progress-bar.cmpt";
import {MyCoursePlansStore} from "../shared/stores/my-course-plans.store";


const ProgressStatus = {
    inProgress: 'In Progress',
    overloaded: 'Overload',
    complete: 'Complete',
    getStatus: (progressPercentage: number) => {
        if (progressPercentage === 100) {
            return ProgressStatus.complete;
        }
        if (progressPercentage > 100) {
            return ProgressStatus.overloaded;
        }
        if (progressPercentage < 100) {
            return ProgressStatus.inProgress;
        }
    }
};

function MessagePanelCmpt({msgs}) {
    return (
        <div className="my-course-plan__course-message-panel">
            <div className="my-course-plan__course-message-panel__headline">
                {`There is a problem with your course.`}
            </div>
            <div className="my-course-plan__course-message-panel__details">
                {msgs.map((msg, index) => <div className="my-course-plan__course-message-panel__details__item" key={index}>{msg}</div>)}
            </div>
        </div>
    );

}

function CourseHeaderCmpt({courseStatus, courseName}) {
    return (
        <div className="my-course-plan__course-header">
            <div className="my-course-plan__course-status material-icons dp48">
                {courseStatus === CourseStatus.complete ? 'check_circle' : ''}
            </div>
            <div className="my-course-plan__course-title">{courseName}</div>
        </div>
    );

}

function CourseHeaderActionsCmpt({clearSelections}) {
    return (
        <ClickableCmpt content={
            <div className="my-course-plan__course-header__clear-all">
                <div className="my-course-plan__course-header__clear-all__text">CLEAR ALL</div>
                <div className="material-icons dp48">delete_forever</div>
            </div>
        } onclick={() => clearSelections()}/>
    );

}

export interface MyCoursePlanCmptProps {
    myCoursePlans?: MyCoursePlansStore;
}

interface MyCoursePlanCmptState {
    isSticky: boolean;
}

@inject('myCoursePlans')
@observer
export class MyCoursePlanCmpt extends React.Component<MyCoursePlanCmptProps, MyCoursePlanCmptState> {

    private domOffsetTop: number;

    constructor(props: MyCoursePlanCmptProps) {
        super(props);

        this.state = {isSticky: false};

        this.clearSelections = this.clearSelections.bind(this);
    }

    componentDidMount(): void {
        window.addEventListener('scroll', this.onWindowScroll.bind(this));
    }

    // eslint-disable-next-line
    componentDidUpdate(prevProps: Readonly<MyCoursePlanCmptProps>, prevState: Readonly<MyCoursePlanCmptState>, snapshot?: any): void {
        //  we only need to update once and once only.
        if (!this.domOffsetTop) {
            this.domOffsetTop = (ReactDOM.findDOMNode(this) as HTMLElement).getBoundingClientRect().top + window.pageYOffset;
        }
    }

    componentWillUnmount(): void {
        window.removeEventListener('scroll', this.onWindowScroll.bind(this));
    }

    onWindowScroll() {
        const isSticky = this.state.isSticky;
        if (window.pageYOffset <= this.domOffsetTop && isSticky) {
            this.setState({isSticky: false})
        }
        if (window.pageYOffset > this.domOffsetTop && !isSticky) {
            this.setState({isSticky: true})
        }
    }

    getCourseStatusClassName(courseStatus: CourseStatus) {
        switch (courseStatus) {
            case CourseStatus.complete:
                return 'my-course-plan__course--complete';
            case CourseStatus.in_progress:
            case CourseStatus.available:
                return 'my-course-plan__course--in-progress';
            case CourseStatus.invalid:
                return 'my-course-plan__course--invalid';
            default:
                return null;
        }
    }

    private clearSelections() {
        this.props.myCoursePlans.clearAllSubjects();
    }

    render() {
        const {isSticky} = this.state;
        const myCoursePlans = this.props.myCoursePlans;
        const currentCoursePlan = myCoursePlans.coursePlan;
        const courseName = `${currentCoursePlan.course.name}`;
        // const courseProgress = myCoursePlans.coursePlan.course.progress;
        const givenCoursePlan = Given.coursePlan(myCoursePlans.coursePlan);

        const requiredPoints = givenCoursePlan.getRequiredPoints();
        const progress = myCoursePlans.courseTotalPoints / requiredPoints * 100;
        const progressStatusText = ProgressStatus.getStatus(progress);
        const courseStatus = givenCoursePlan.getCourseStatus();
        const msgs = givenCoursePlan.getCourseLevelMsgs();

        return (
            <div className={`my-course-plan ${isSticky ? 'my-course-plan--sticky' : ''}`}>
                <div className="my-course-plan__header">
                    <div className="my-course-plan__header-text">MY COURSE PLAN</div>
                    <div className="my-course-plan__course-progress">
                        <span className='my-course-plan__course-progress-status'>{progressStatusText}</span>
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                        {`${myCoursePlans.courseTotalPoints}/${requiredPoints}`}
                    </div>
                </div>
                <div className="my-course-plan__course-progress-bar">
                    <div className="my-course-plan__course-progress-completeness" style={{width: `${progress}%`}}></div>
                </div>
                <div className={`my-course-plan__course ${this.getCourseStatusClassName(courseStatus)}`}>
                    <CollapsibleCmpt
                        header={
                            {
                                content: <CourseHeaderCmpt courseName={courseName} courseStatus={courseStatus}/>,
                                actions: <CourseHeaderActionsCmpt clearSelections={this.clearSelections}/>
                            }
                        }
                        content={
                            <React.Fragment>
                                {myCoursePlans.isValidCoursePlan ? null : <MessagePanelCmpt msgs={msgs}/>}
                                {/*<CourseProgressBarCmpt courseProgress={courseProgress}/>*/}
                            </React.Fragment>
                        }
                        contentVisible={'initial.false'}/>
                </div>
                <CourseEligibilityCmpt/>
                <SelectedSubjectsCmpt/>
            </div>

        );
    }
}












