import './selected-major.cmpt.scss'
import {ClickableCmpt} from "../../../../shared/components/clickable/clickable.cmpt";
import * as React from "react";
import {Outcome} from "../../../outcome";
import {MajorImages} from "../selected-majors-image";
import {Given} from "../../../../course-plan/course-plan";
import ReactTooltip from "react-tooltip";


function WarningIconCmpt({major}) {
    let scoreClass = 'selected-major__wrapper__availability--warning';
    if (major.state.availability_score === 1) {
        scoreClass = 'selected-major__wrapper__availability--error'
    }
    return (
        <div className="selected-major__wrapper__availability"
             data-tip={Given.outcome(major).getAvailbilityAsMessage()}
             onMouseOver={(e) => {
                 ReactTooltip.show(e.currentTarget)
             }}
             onMouseOut={(e) => ReactTooltip.hide(e.currentTarget)}>
            <i className={`${scoreClass} material-icons dp48`}>error</i>
        </div>
    );
}


export interface SelectedMajorCmptProps {
    major: Outcome;
    specialisationName: string;
    availability_score: number;
    toggleMajor: (e: React.MouseEvent, major: Outcome) => void;
    onUnSelectMajor: (e: React.MouseEvent, major: Outcome) => void;
}


export function SelectedMajorCmpt({
                                      major,
                                      specialisationName,
                                      availability_score,
                                      toggleMajor,
                                      onUnSelectMajor
                                  }: SelectedMajorCmptProps) {

    return (
        <>
            <div className="selected-major"
                 style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("${MajorImages[major.code]}")`}}
                 key={major.info.name}>
                <div className="selected-major__wrapper" onClick={(e) => toggleMajor(e, major)}>
                    <div className="selected-major__wrapper__content-wrapper">
                        <div className="selected-major__wrapper__content-wrapper__text">
                            {major.info.name}
                        </div>
                        <div className="selected-major__wrapper__content-wrapper__specialisation">
                            {specialisationName}
                        </div>

                        <ClickableCmpt
                            content={
                                <div className="material-icons dp48 selected-major__wrapper__content-wrapper__unselected-major-icon">remove_circle_outline</div>
                            }
                            onclick={(e) => onUnSelectMajor(e, major)}/>
                    </div>
                    {availability_score !== 5 ? <WarningIconCmpt major={major}/> : null}
                </div>
            </div>
            <div className="selected-major-spacer" key={`${major.info.name}-spacer`}></div>
        </>
    );
}