import './selected-majors.cmpt.scss';

import * as React from "react";
import {Outcome} from "../../outcome";
import {inject, observer} from "mobx-react";
import {SelectedMajorCmpt} from "./selected-major/selected-major.cmpt";
import {Specialisation} from "../../outcome.user-pref";
import {MyCoursePlansStore} from "../../../shared/stores/my-course-plans.store";


export interface SelectedMajorsCmptProps {
    header: string;
    category: SelectedMajorsCategory;
    selectedMajors: Array<Outcome>;
    selectedMajorClickedCallback: (dom: HTMLElement, major: Outcome) => void
    unselectMajor: (major: Outcome) => void;
    myCoursePlans?: MyCoursePlansStore;
}

export enum SelectedMajorsCategory {
    selected = 'selected', favourited = 'favourited'
}

@inject('myCoursePlans')
@observer
export class SelectedMajorsCmpt extends React.Component<SelectedMajorsCmptProps> {

    private static NO_SELECTION_ELE = (
        <div className="selected-majors selected-majors__none-selected" key="none-selected">
            None Selected
        </div>
    );

    constructor(props: SelectedMajorsCmptProps, context: any) {
        super(props, context);

        this.toggleMajor = this.toggleMajor.bind(this);
        this.onUnSelectMajor = this.onUnSelectMajor.bind(this);
    }


    private toggleMajor(e: React.MouseEvent, major: Outcome) {
        this.props.selectedMajorClickedCallback((e.currentTarget as HTMLElement), major);
    }

    private onUnSelectMajor(e: React.MouseEvent, major: Outcome) {
        e.preventDefault();
        e.stopPropagation();
        this.props.unselectMajor(major);
    }

    private getSelectedSpecialisationName(category: SelectedMajorsCategory, outcome: Outcome) {
        const special: Specialisation = outcome.userPref.specialisation;
        if (category === SelectedMajorsCategory.selected && !!special.selected) {
            return special.selected.info.name;
        }
        if (category === SelectedMajorsCategory.favourited && !!special.favourite) {
            return special.favourite.info.name;
        }
    }


    render() {
        const {selectedMajors, category} = this.props;

        return (
            <div className="selected-majors">
                <div className="selected-majors__header">{this.props.header}</div>
                <div className="selected-majors__content-scrollbar">
                    <div className="selected-majors__content-wrapper">
                        {
                            selectedMajors.length === 0
                                ? [SelectedMajorsCmpt.NO_SELECTION_ELE] :
                                selectedMajors.map(major => <SelectedMajorCmpt major={major}
                                                                               specialisationName={this.getSelectedSpecialisationName(category, major)}
                                                                               availability_score={major.state.availability_score}
                                                                               toggleMajor={this.toggleMajor}
                                                                               onUnSelectMajor={this.onUnSelectMajor}
                                                                               key={major.info.name}/>)
                        }
                    </div>
                </div>
            </div>
        );
    }
}
