import * as React from "react";
import * as ReactDOM from "react-dom";
import { App } from './app';

import './analytics';
import './stackdriver';

ReactDOM.render(
  <App/>,
  document.getElementById("app")
);
