export enum Status {
    NOOP, SUCCESS, FAILURE
}

export type Fn<P, R> = (param?: Param<P>) => Result<R>;
export type Param<T> = { param?: T };
export type Result<T> = { status: Status, result?: T };

export class IdempotentScheduler<P, R> {
    static NOOP: Result<any> = {status: Status.NOOP};

    private queue: Array<Fn<P, R>> = new Array<Fn<P, R>>();

    schedule(newFn: Fn<P, R>): void {
        this.queue.push(newFn);
    }

    execute(param?: Param<P>): Result<R> {
        const callback = this.queue.shift();
        if (this.queue.length === 0) {
            return callback(param);
        }
        return IdempotentScheduler.NOOP;
    }

    isEmpty() {
        return this.queue.length === 0;
    }
}

export class DebounceScheduler<P, R> {
    static NOOP: Result<any> = {status: Status.NOOP};

    private queue: Array<Fn<P, R>> = new Array<Fn<P, R>>();

    schedule(newFn: Fn<P, R>): void {
        this.queue.push(newFn);
    }

    execute(): Result<R> {
        if (this.queue.length === 0) {
            return DebounceScheduler.NOOP;
        }

        // execute the job in the queue when there is only one job
        if (this.queue.length === 1) {
            const callback = this.queue[this.queue.length - 1];
            return callback();
        }
    }

    next(): Result<R> {
        // if there is only one job in the queue, that would
        // be the last job that has been executed. Therefore,
        // the batch is done.
        if (this.queue.length === 1) {
            this.queue.length = 0;
            return DebounceScheduler.NOOP;
        }

        // execute the batch and leave the last job (which is going to be executed)
        // in the queue, as an indication there is a job being executed.
        this.queue.splice(0, this.queue.length - 1);
        return this.execute();
    }
}
