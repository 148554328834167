import './subject-header-actions.cmpt.scss'

import * as React from "react";
import {MouseEvent} from "react";
import ReactTooltip from "react-tooltip";
import {Subject} from "../../../../../subject";
import {ClickableCmpt} from "../../../../../../shared/components/clickable/clickable.cmpt";
import {StringUtil} from "../../../../../../shared/util";


export interface SubjectHeaderActionsCmptProps {
    subject: Subject;
    isFavourited: boolean;
    isSelected: boolean;
    unFavourite: (subject: Subject) => void;
    favourite: (subject: Subject) => void;
    unSelect: (subject: Subject) => void;
    select: (subject: Subject) => void;
}

export class SubjectHeaderActionsCmpt extends React.Component<SubjectHeaderActionsCmptProps> {

    shouldComponentUpdate(nextProps: Readonly<SubjectHeaderActionsCmptProps>): boolean {
        const {subject, isSelected, isFavourited} = this.props;
        if (subject.code !== nextProps.subject.code) {
            return true;
        }
        if (isSelected !== nextProps.isSelected) {
            return true;
        }
        if (isFavourited !== nextProps.isFavourited) {
            return true;
        }
        return false;
    }

    private onMouseOver(e: MouseEvent) {
        ReactTooltip.show(e.currentTarget);
    }

    private onMouseOut(e: MouseEvent) {
        ReactTooltip.hide(e.currentTarget);
    }

    render() {
        const {subject, isSelected, isFavourited} = this.props;
        const {unFavourite, favourite, unSelect, select} = this.props;

        const className = `subject-header-actions__icon
                     subject-header-actions__icon__select
                     ${isSelected ? 'subject-header-actions__icon__select--selected' : ''}
                     material-icons dp48`;
        return (
            <div className="subject-header-actions" key={`header-actions-${subject.code}`}>
                <ClickableCmpt content={
                    <div className="subject-header-actions__icon subject-header-actions__icon__favourite material-icons dp48">
                        {isFavourited ? 'favorite' : 'favorite_border'}
                    </div>
                } onclick={isFavourited ? unFavourite : favourite}/>
                <div className="subject-header-actions__icon__spacer"></div>
                <ClickableCmpt content={
                    <div className={StringUtil.ensureOneLine(className)}
                         data-tip=''
                         data-for={`subject-action-tooltip`}
                         onMouseOver={(e) => this.onMouseOver(e)}
                         onMouseOut={(e) => this.onMouseOut(e)}
                         onClick={(e) => this.onMouseOut(e)}>
                        {isSelected ? 'remove' : 'add'}

                    </div>
                } onclick={isSelected ? unSelect : select}/>
            </div>
        );
    }

}