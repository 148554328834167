import {CoursePlan} from "./course-plan/course-plan";
import {VCESubject} from "./browse-subjects/subject";


export class MyEligibility {

    constructor(private coursePlan: CoursePlan) {
    }

    add(vceSubject: VCESubject) {
        let vceSubjects = this.coursePlan.vceSubjects;
        if (vceSubjects.indexOf(vceSubject) !== -1) {
            return false;
        }
        vceSubjects.push(vceSubject);
        return true;
    }

    remove(vceSubject: VCESubject) {
        let vceSubjects = this.coursePlan.vceSubjects;
        const index = vceSubjects.indexOf(vceSubject);
        if (index === -1) {
            return false;
        }
        vceSubjects.splice(index, 1);
        return true;
    }
}