import './subject-header-category.cmpt.scss'

import {Category} from "../../../../../subject";
import * as React from "react";


export interface SubjectHeaderCategoryCmptProps {
    isSelected: boolean;
    expand: boolean;
    category: Category;
}

function getCategoryClass(category: Category) {
    switch (category) {
        case Category.breadth:
            return 'subject-header-category--breadth';
        case Category.compulsory:
            return 'subject-header-category--compulsory'
        default:
            break;
    }
    return 'subject-header-category--elective';
}

export function SubjectHeaderCategoryCmpt({isSelected, expand, category}: SubjectHeaderCategoryCmptProps) {
    const typeClassName = getCategoryClass(category);
    const isSelectedClassName = isSelected ? 'subject-header-category--selected' : '';
    return (
        expand
            ? <div className={`subject-header-category subject-header-category--expand ${typeClassName} ${isSelectedClassName} material-icons dp48`}>arrow_drop_down</div>
            : <div className={`subject-header-category subject-header-category--collapse ${typeClassName} ${isSelectedClassName} material-icons dp48`}>arrow_right</div>
    );

}