import './available-majors.cmpt.scss'

import * as React from "react";
import {AvailableMajorCmpt} from "./available-major/available-major.cmpt";
import {Outcome} from "../outcome";
import {MajorDetailsCmpt} from "../major-details/major-details.cmpt";
import {CollapsibleCmpt} from "../../shared/components/collapsible/collapsible.cmpt";
import {Given} from "../../shared/html-util";
import {EventBus, Events} from "../../shared/services/event-bus.service";
import {CourseHandbookStore} from "../../shared/stores/course-handbook.store";
import {MyCoursePlansStore} from "../../shared/stores/my-course-plans.store";

export interface AvailableMajorsCmptProps {
    courseHandBook: CourseHandbookStore;
    myCoursePlans: MyCoursePlansStore
}

export interface AvailableMajorsCmptState {
    expandedMajor?: Outcome;
    insertDomIndex?: number;
    majorDom?: HTMLElement;
    showAllMajors?: boolean;
}


export class AvailableMajorsCmpt extends React.Component<AvailableMajorsCmptProps, AvailableMajorsCmptState> {

    static UNSELECT_MAJOR: AvailableMajorsCmptState = {expandedMajor: null, insertDomIndex: null, majorDom: null};
    private majorsDomRef: HTMLElement;

    constructor(props: AvailableMajorsCmptProps) {
        super(props);

        this.state = {...AvailableMajorsCmpt.UNSELECT_MAJOR, showAllMajors: true};
    }

    selectMajor(major: Outcome) {
        EventBus.fire({name: Events.APP_COLLAPSIBLE_SHOW, payload: null});
        this.props.myCoursePlans.selectMajor(major);
    }

    private getAvailableMajors() {
        return this.props.courseHandBook.outcomes;
    }

    private resetSelectedMajor() {
        this.setState(AvailableMajorsCmpt.UNSELECT_MAJOR);
    }

    private toggleMajorDetails(majorDom: HTMLElement, expandedMajor: Outcome) {

        const updateStateToShowMajorDetails = () => {
            let targetOffsetTop = majorDom.offsetTop;
            let targetedSibling = majorDom as Node;
            while ((targetedSibling = targetedSibling.nextSibling) != null) {
                if ((targetedSibling as HTMLElement).offsetTop > targetOffsetTop) {
                    break;
                }
            }

            let insertDomIndex = majorDom.parentElement.childElementCount;
            if (!!targetedSibling) {
                insertDomIndex = Given.anElement(targetedSibling as HTMLElement).getCurrentIndex();
                const currentInsertDomIndex = this.state.insertDomIndex || insertDomIndex;
                if (currentInsertDomIndex < insertDomIndex) {
                    insertDomIndex--;
                }
            }
            this.setState({expandedMajor, insertDomIndex, majorDom});
        };

        const currDetailedMajor = this.state.expandedMajor;

        if (currDetailedMajor === expandedMajor) {
            this.setState(AvailableMajorsCmpt.UNSELECT_MAJOR);
        } else {
            // show another major, so hide the current one that is open and update the state
            updateStateToShowMajorDetails();
        }
    }

    render() {
        const {expandedMajor, showAllMajors} = this.state;
        const availableMajorEles: Array<JSX.Element> =
            this.getAvailableMajors()
                .slice()
                .sort((a: Outcome, b: Outcome) => a.state.availability_score === b.state.availability_score ? 0 : a.state.availability_score <= b.state.availability_score ? 1 : -1)
                .map(((major: Outcome) => {
                    return (<AvailableMajorCmpt major={major}
                                                key={major.info.name}
                                                selectMajor={this.selectMajor.bind(this)}
                                                onMajorClicked={this.toggleMajorDetails.bind(this)}/>);
                }));
        if (expandedMajor !== null) {
            let majorDetailsEle = <MajorDetailsCmpt major={expandedMajor}
                                                    majorDom={this.state.majorDom}
                                                    favouriteMajorCallback={this.resetSelectedMajor.bind(this)}
                                                    selectMajorCallback={this.resetSelectedMajor.bind(this)}
                                                    closeCallback={this.resetSelectedMajor.bind(this)}
                                                    key={`selected-major-detail-${expandedMajor.code}`}/>;
            availableMajorEles.splice(this.state.insertDomIndex, 0, majorDetailsEle);
        }

        return (
            <div className={`available-majors ${showAllMajors ? '' : 'available-major--hide-all-majors'}`}>
                <CollapsibleCmpt
                    header={
                        {content: <span className="available-majors__header">MAJORS</span>}
                    }
                    content={
                        <div className="available-majors__selection" ref={(ref) => this.majorsDomRef = ref}>
                            {availableMajorEles}
                        </div>
                    }
                    contentVisible={true}
                />
            </div>
        );
    }
}