import './major-details.cmpt.scss';

import {Outcome} from "../outcome";
import * as React from "react";
import {StringUtil} from "../../shared/util";
import {inject, observer} from "mobx-react";
import {EventBus, EventRegistrationHandle, Events} from "../../shared/services/event-bus.service";
import {SelectedMajorsCategory} from "../my-majors/selected-majors/selected-majors.cmpt";
import {MessageCmpt, MessageType} from "../../shared/components/message/message.cmpt";
import {Given} from "../../course-plan/course-plan";
import {Given as Html_Given} from "../../shared/html-util";
import {MajorDetailsActionsCmpt} from "./major-details-actions/major-details-actions";
import {MajorDetailsSpecialisationsCmpt} from "./major-details-specialisations/major-details-specialisations.cmpt";
import {MyCoursePlansStore} from "../../shared/stores/my-course-plans.store";


function MessagePanelCmpt({major}) {
    const message = Given.outcome(major).getAvailbilityAsMessage();
    if (!message) {
        return null;
    }
    let type: MessageType;
    switch (major.state.availability_score) {
        case 1:
            type = MessageType.error;
            break;
        case 3:
            type = MessageType.warn;
            break;
        default:
            break;
    }
    return <MessageCmpt message={message} type={type}/>;
}


export interface MajorDetailsCmptProps {
    major: Outcome;
    majorDom: HTMLElement;
    selectedMajorsCategory?: SelectedMajorsCategory;
    selectMajorCallback: () => void;
    favouriteMajorCallback: () => void;
    closeCallback: () => void;
    myCoursePlans?: MyCoursePlansStore;
}

interface MajorDetailsCmptState {
    closed: boolean;
}


@inject('myCoursePlans')
@observer
export class MajorDetailsCmpt extends React.Component<MajorDetailsCmptProps, MajorDetailsCmptState> {

    static MAX_OVERVIEW_LENGTH = 720;

    private closeMajorDetailsHandle: EventRegistrationHandle;
    private closeMajorDetailsWhenSimulateHandle: EventRegistrationHandle;


    constructor(props: MajorDetailsCmptProps, context: any) {
        super(props, context);

        this.state = {closed: true};

        this.selectMajor = this.selectMajor.bind(this);
        this.favouriteMajor = this.favouriteMajor.bind(this);
        this.selectSpecialisation = this.selectSpecialisation.bind(this);
        this.unSelectSpecialisation = this.unSelectSpecialisation.bind(this);
        this.favouriteSpecialisation = this.favouriteSpecialisation.bind(this);
        this.unFavouriteSpecialisation = this.unFavouriteSpecialisation.bind(this);
        this.hideMajor = this.hideMajor.bind(this);

    }

    componentDidMount(): void {
        setTimeout(() => {
            this.closeMajorDetailsHandle = EventBus.register(Events.APP_COLLAPSIBLE_SHOW, (payload: MajorDetailsCmpt) => {
                if (payload === this) {
                    return;
                }
                this.close(this.props.closeCallback);
            });
            this.closeMajorDetailsWhenSimulateHandle = EventBus.register(Events.SIMULATE_OUTCOMES, ((simulateOutcome: boolean) => {
                if (!!simulateOutcome) {
                    this.close(this.props.closeCallback);
                }
            }));
            EventBus.fire({name: Events.APP_COLLAPSIBLE_SHOW, payload: this});
            this.setState({closed: false});
        });
    }

    componentWillUnmount(): void {
        this.closeMajorDetailsHandle.done();
        this.closeMajorDetailsWhenSimulateHandle.done();
    }

    private selectMajor(selectedMajor: Outcome) {
        this.close(() => {
            this.props.selectMajorCallback();
            this.props.myCoursePlans.selectMajor(selectedMajor);
        });
    }

    private favouriteMajor(favouritedMajor: Outcome) {
        this.close(() => {
            this.props.favouriteMajorCallback();
            this.props.myCoursePlans.favouriteMajor(favouritedMajor);
        });
    }

    private selectSpecialisation(specialisation: Outcome) {
        this.close(() => {
            this.props.selectMajorCallback();
            this.props.myCoursePlans.selectSpecialisation(this.props.major, specialisation);
        });
    }

    private unSelectSpecialisation(specialisation: Outcome) {
        this.close(() => {
            this.props.selectMajorCallback();
            this.props.myCoursePlans.unSelectSpecialisation(this.props.major, specialisation);
        });
    }

    private favouriteSpecialisation(specialisation: Outcome) {
        this.close(() => {
            this.props.favouriteMajorCallback();
            this.props.myCoursePlans.favouriteSpecialisation(this.props.major, specialisation);
        })
    }

    private unFavouriteSpecialisation(specialisation: Outcome) {
        this.close(() => {
            this.props.favouriteMajorCallback();
            this.props.myCoursePlans.unFavouriteSpecialisation(this.props.major, specialisation);
        })
    }

    private hideMajor() {
        this.close(() => {
            this.props.closeCallback();
        });
    }

    private close(callbackFn: () => void) {
        this.setState({closed: true});
        callbackFn();
    }

    render() {
        const {closed} = this.state;
        const {major, majorDom, selectedMajorsCategory} = this.props;
        const scrollingParent = Html_Given.anElement(majorDom).findFirstScrollingParent() || {scrollLeft: 0};
        const indicatorOffsetLeft = (majorDom.offsetLeft + majorDom.offsetWidth / 2) - scrollingParent.scrollLeft;
        const overview_text = major.info.overview || '';

        const isSelected = Given.outcome(major).isSelected();
        const isFavourited = Given.outcome(major).isFavourited();
        const showSelectAndFavouriteIcons = !(selectedMajorsCategory || major.specialisations.length > 0);


        return (
            <div className={`major-details ${closed ? 'major-details--loading' : ''}`}
                 key={`${major.info.name}-expanded`}>
                <div className="major-details__indicator" style={{left: indicatorOffsetLeft}}></div>
                <div className="major-details__resizer">
                    <div className="major-details__header">
                        {major.info.name}
                    </div>
                    <div>
                        <MajorDetailsActionsCmpt isSelected={isSelected}
                                                 major={major}
                                                 isFavourited={isFavourited}
                                                 onFavouriteMajor={this.favouriteMajor}
                                                 onSelectMajor={this.selectMajor}
                                                 showSelectAndFavouriteIcons={showSelectAndFavouriteIcons}
                                                 hideMajor={this.hideMajor}/>
                    </div>
                    <MessagePanelCmpt major={major}/>
                    <MajorDetailsSpecialisationsCmpt major={major}
                                                     selectedCategory={selectedMajorsCategory}
                                                     onSelect={this.selectSpecialisation}
                                                     onUnSelect={this.unSelectSpecialisation}
                                                     onFavourite={this.favouriteSpecialisation}
                                                     onUnFavourite={this.unFavouriteSpecialisation}/>
                    <div className="major-details__content__overview">
                        <div className="major-details__content__overview-header">
                            OVERVIEW
                        </div>
                        <div className="major-details__content__overview-content">
                            {overview_text.length > MajorDetailsCmpt.MAX_OVERVIEW_LENGTH ? `${StringUtil.extract(overview_text, MajorDetailsCmpt.MAX_OVERVIEW_LENGTH, ' ')}... ` : overview_text}
                            {<a href={`${major.info.handbook_url}`}
                                // eslint-disable-next-line
                                target="_blank"
                                className="major-details__content__overview-content__more">more information</a>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}