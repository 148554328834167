import {Class as OutcomeClass, Outcome} from "./outcomes/outcome";
import {CoursePlan, Given} from "./course-plan/course-plan";
import {Subject, VCESubject} from ".//browse-subjects/subject";
import {Suggestions} from "./course-plan/suggestions";
import {CourseHandbookStore} from "./shared/stores/course-handbook.store";
import {StringUtil} from "./shared/util";
import {MyEligibility} from "./my-eligibility";
import {MyMajors} from "./my-majors";
import {MySubjects} from "./my-subjects";

const zlib = require('zlib');
const _ = require('lodash');

export class MyCoursePlan {

    private readonly _myEligibility: MyEligibility;
    private readonly _myMajors: MyMajors;
    private readonly _mySubjects: MySubjects;

    constructor(private courseCode: string,
                private coursePlan: CoursePlan,
                private courseHandbook: CourseHandbookStore) {

        this._myEligibility = new MyEligibility(this.coursePlan);
        this._myMajors = new MyMajors(this.coursePlan);
        this._mySubjects = new MySubjects(this.coursePlan, this.courseHandbook);
    }

    private parseSavedCoursePlan(savedHex: string) {
        if (savedHex.indexOf('%7B%22c%22%') === 0) {
            return JSON.parse(decodeURIComponent(savedHex));
        }

        return JSON.parse(zlib.inflateSync(StringUtil.fromHexString(savedHex), {}));
    }

    private toJS(obj:any) {
        return JSON.parse(JSON.stringify(obj));
    }


    get myEligibility(): MyEligibility {
        return this._myEligibility;
    }

    get myMajors(): MyMajors {
        return this._myMajors;
    }

    get mySubjects(): MySubjects {
        return this._mySubjects;
    }

    get vceSubjects(): Array<VCESubject> {
        return this.coursePlan.vceSubjects;
    }

    get majors(): Array<Outcome> {
        return Given.outcomes(this.coursePlan.course.outcomes).findByClasses([OutcomeClass.major]).get();
    }

    get selectedMajors(): Array<Outcome> {
        return Given.outcomes(this.majors).findSelected().get();
    }

    get favouritedMajors(): Array<Outcome> {
        return Given.outcomes(this.majors).findFavourites().get();
    }

    get selectedSubjects(): Array<Subject> {
        return Given.subjects(this.coursePlan.course.subjects).findSelected();
    }

    get favouritedSubjects(): Array<Subject> {
        return Given.subjects(this.coursePlan.course.subjects).findFavourite();
    }

    updateCoursePlan(resp: any) {
        const currentCoursePlan = this.coursePlan;
        const respPlan = resp.data.plan;

        // update course validation/msg
        currentCoursePlan.status = respPlan.status;
        currentCoursePlan.msgs = respPlan.msgs;
        currentCoursePlan.course.state = respPlan.course.state;
        currentCoursePlan.course.progress = Object.keys(respPlan.course.progress).map(key => respPlan.course.progress[key]);

        // update selected majors with validation
        const selectedOutcomes = this.selectedMajors;
        const outcomesValidation = respPlan.outcomes;
        selectedOutcomes.forEach((selectedOutcome) => {
            const matchedOutcome: Outcome = Given.outcomes(outcomesValidation).findByCodes([selectedOutcome]).get()[0];
            // update state for selected outcome
            if (!!matchedOutcome) {
                const newState = {...matchedOutcome.state, availability_score: selectedOutcome.state.availability_score};
                if (!_.isEqual(this.toJS(selectedOutcome.state), this.toJS(newState))) {
                    selectedOutcome.state = newState;
                }
            }
            // update state for selected specialisation
            const specialisation = selectedOutcome.userPref.specialisation.selected;
            const matchedSpecial: Outcome = Given.outcomes(outcomesValidation).findByCodes([specialisation]).get()[0];
            if (!!matchedSpecial) {
                const newState = {...matchedSpecial.state, availability_score: specialisation.state.availability_score};
                if (!_.isEqual(this.toJS(specialisation.state), this.toJS(newState))) {
                    specialisation.state = newState;
                }
            }
        });

        // update subjects with validation
        // TODO: how to optimise the merge?
        const selectedSubjects: Array<Subject> = this.selectedSubjects;
        const subjectsValidation: Array<Subject> = respPlan.subjects;
        selectedSubjects
            .filter((selectedSubject) => Given.subjects(subjectsValidation).findByCodes([selectedSubject.code]).length > 0)
            .forEach((selectedSubject) => {
                const subjectValidation = Given.subjects(subjectsValidation).findByCodes([selectedSubject.code])[0];
                subjectValidation.state.msgs = subjectValidation.state.msgs.map((msg: string) => {
                    if (msg.indexOf('\n') === -1) {
                        return msg;
                    }
                    const msgs = msg.split('\n');
                    return {heading: msgs.shift(), msgs: msgs}
                });
                if (!_.isEqual(this.toJS(selectedSubject.state), this.toJS(subjectValidation.state))) {
                    selectedSubject.state = subjectValidation.state;
                }
            });
    }

    updateSimulation(resp: any) {
        const suggestions: Suggestions = resp.data.suggestions;

        // update outcomes suggestions
        const outcomeSuggestionMap: Map<string, any> = new Map();
        if (!!suggestions.outcomes) {
            suggestions.outcomes.forEach(outcome => outcomeSuggestionMap.set(outcome.code, outcome.state));

            this.courseHandbook.outcomes.forEach((outcome) => {
                const matchedOutcome = outcomeSuggestionMap.get(outcome.code);
                if (!!matchedOutcome) {
                    const newScore = matchedOutcome.available === true ? 5 : matchedOutcome.available === false && matchedOutcome.timedOut === true ? 3 : 1;
                    if (outcome.state.availability_score !== newScore) {
                        outcome.state.availability_score = newScore;
                        // console.error(`${outcome.info.name} - ${outcome.state.availability_score}`);
                    }
                } else {
                    console.error('missing outcome=' + outcome.code);
                }
            });
        }
    }

    loadSave(savedHex: any) {
        const coursePlan: CoursePlan = this.coursePlan;
        const courseHandBook = this.courseHandbook;

        const saved = this.parseSavedCoursePlan(savedHex);

        // course does not match
        if (this.courseCode !== saved.c) {
            this._mySubjects.clearAllSubjects();
            throw new Error('course code does not match saved plan.');
        }

        coursePlan.course.code = saved.c;
        coursePlan.course.yearStart = saved.y;

        saved.v = saved.v || []
        saved.v.forEach((savedVCESubject: any) => {
            const found = coursePlan.coursePlanReq.vceSubjects.find((vceSubject) => {
                return vceSubject === savedVCESubject[0]
            });
            if (!!found) {
                coursePlan.vceSubjects.push({
                    name: savedVCESubject[0],
                    score: savedVCESubject[1]
                });
            }
        });

        saved.o.forEach((savedOutcome: any) => {
            const found = this.courseHandbook.outcomes.find(outcome => outcome.code === savedOutcome[0]);
            if (!!found) {
                found.userPref.selected = savedOutcome[1] === 1 ? true : false;
                found.userPref.favourite = savedOutcome[2] === 1 ? true : false;
                if (found.userPref.selected) {
                    found.userPref.selected = false;
                    this._myMajors.selectMajor(found);
                }
                if (found.userPref.favourite) {
                    found.userPref.favourite = false;
                    this._myMajors.favouriteMajor(found);
                }

                const selectedSpecialisation = savedOutcome[3];
                if (!!selectedSpecialisation) {
                    found.userPref.specialisation.selected =
                        Given.outcome(found).findSpecialisatoin([selectedSpecialisation])[0];
                }
                const favouritedSpecialisation = savedOutcome[4];
                if (!!favouritedSpecialisation) {
                    found.userPref.specialisation.favourite =
                        Given.outcome(found).findSpecialisatoin([favouritedSpecialisation])[0];
                }

            }
        });

        saved.s.forEach((savedSubject: any) => {
            const found = courseHandBook.subjects.find(subject => subject.code === savedSubject[0]);
            if (!!found) {
                found.userPref.selected = savedSubject[3] === 1 ? true : false;
                if (found.userPref.selected) {
                    this._mySubjects.selectSubject(found);
                    found.userPref.year = savedSubject[1];
                    found.userPref.semester = savedSubject[2];
                }
            }
        });
    }

    generateSave(): string {
        let coursePlanJsonSave = new Buffer(Given.coursePlan(this.coursePlan).toUrlSavedJson());
        const compressed: Buffer = zlib.deflateSync(coursePlanJsonSave);
        return StringUtil.toHexString(compressed);
    }
}