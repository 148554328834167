import './course-eligibility.cmpt.scss';
import * as React from "react";
import {inject, observer} from "mobx-react";
import {CollapsibleCmpt} from "../../shared/components/collapsible/collapsible.cmpt";
import {Select} from "antd";
import {ClickableCmpt} from "../../shared/components/clickable/clickable.cmpt";
import {InputFieldCmpt} from "../../shared/components/input-field/input-field.cmpt";
import {VCESubject} from "../../browse-subjects/subject";
import {ArrayUtil} from "../../shared/util";
import {EventBus, Events} from "../../shared/services/event-bus.service";
import {IdempotentScheduler} from "../../shared/services/scheduler-service";
import {MyCoursePlansStore} from "../../shared/stores/my-course-plans.store";

const Option = Select.Option;


function VCESubjectsCmpt({vceSubjects, removeCourseEligibility}) {
    return (
        <div className="course-eligibility__content__vce-subjects">
            {vceSubjects.map((vceSubject: VCESubject, index: number) =>
                <div className="course-eligibility__added-vce-subjects__vce-subject" key={index}>
                    <div className="course-eligibility__added-vce-subjects__vce-subject__name">{vceSubject.name}</div>
                    <div className="course-eligibility__added-vce-subjects__vce-subject__score">{vceSubject.score}</div>
                    <div className="course-eligibility__added-vce-subjects__vce-subject__remove-button">
                        <ClickableCmpt content={'REMOVE'} onclick={() => removeCourseEligibility(vceSubject)}/>
                    </div>
                </div>
            )}
        </div>
    );
}


export interface CourseEligibilityCmptState {
    vceSubject: VCESubject;
}

export interface CourseEligibilityCmptProps {
    myCoursePlans?: MyCoursePlansStore;
}

@inject('myCoursePlans')
@observer
export class CourseEligibilityCmpt extends React.Component<CourseEligibilityCmptProps, CourseEligibilityCmptState> {

    private static readonly EMPTY_VCE_SUBJECT: VCESubject = {name: undefined, score: undefined};

    private vceSubjectSelectDomRef: HTMLElement;
    private scheduler: IdempotentScheduler<any, any> = new IdempotentScheduler();


    constructor(props: CourseEligibilityCmptProps) {
        super(props);
        this.state = {vceSubject: this.emptyVCESubject()};

        this.removeCourseEligibility = this.removeCourseEligibility.bind(this);
    }

    emptyVCESubject() {
        return Object.assign({}, CourseEligibilityCmpt.EMPTY_VCE_SUBJECT);
    }

    onVCESubjectSelectionVisibilityChange(visable: boolean) {
        if (visable) {
            const onWindowScroll = () => this.onWindowScroll();
            window.addEventListener('scroll', onWindowScroll);
            this.scheduler.schedule(() => {
                window.removeEventListener('scroll', onWindowScroll);
                return IdempotentScheduler.NOOP;
            });
        } else {
            this.scheduler.execute();
        }
    }

    onWindowScroll() {
        const selectDropdown = this.vceSubjectSelectDomRef.getElementsByClassName('ant-select-dropdown');
        if (selectDropdown.length > 0) {
            const boundingClientRect = this.vceSubjectSelectDomRef.getBoundingClientRect();
            (selectDropdown.item(0) as HTMLElement).style.top =
                `${boundingClientRect.top + boundingClientRect.height }px`;
        }
    }

    validateVCEScore(value: string) {
        // reset the score every time validate happens
        let vceSubject: VCESubject = this.state.vceSubject;

        const vcceScore = Number(value);
        if (isNaN(vcceScore)) {
            return false;
        }

        const isValid = (vcceScore >= 0 && vcceScore <= 50) ? true : false;
        if (isValid) {
            vceSubject.score = vcceScore;
        }
        this.setState({vceSubject});
        return isValid;
    }

    selectVCESubject(vceSubjectName: string) {
        let vceSubject = this.state.vceSubject;
        vceSubject.name = vceSubjectName;
        this.setState({vceSubject});
    }

    addCourseEligibility() {
        this.props.myCoursePlans.addVCESubject(this.state.vceSubject);
        this.setState({vceSubject: this.emptyVCESubject()});
        EventBus.fire({name: Events.VALIDATING_COURSE_PLAN_UPDATE_UI});
    }

    removeCourseEligibility(vceSubject: VCESubject) {
        this.props.myCoursePlans.removeVCESubject(vceSubject);
        EventBus.fire({name: Events.VALIDATING_COURSE_PLAN_UPDATE_UI});
    }

    render() {
        const {myCoursePlans} = this.props;
        const coursePlan = myCoursePlans.coursePlan;
        const addedVCESubjects = ArrayUtil.extract(coursePlan.vceSubjects, 'name');
        const vceSubjects = ArrayUtil.filterWith(coursePlan.coursePlanReq.vceSubjects, (ele: string) => addedVCESubjects.indexOf(ele) === -1);
        const vceSubject = this.state.vceSubject;
        const isValid = (!!vceSubject.name && (!!vceSubject.score || vceSubject.score === 0)) ? true : false;
        return (
            <div className="course-eligibility">
                <CollapsibleCmpt
                    header={
                        {
                            content: <span className="course-eligibility__header">VCE Results</span>,
                            actions: <span className="course-eligibility__vce-subject-count">{addedVCESubjects.length} ADDED</span>
                        }
                    }
                    content={
                        <>
                            <div className="course-eligibility__added-vce-subjects">
                                <VCESubjectsCmpt vceSubjects={coursePlan.vceSubjects} removeCourseEligibility={this.removeCourseEligibility}/>
                            </div>
                            <div className={`course-eligibility__add-course-eligibility ${isValid ? '' : 'course-eligibility__add-course-eligibility--invalid'}`}>
                                <div className="course-eligibility__add-course-eligibility__item course-eligibility__add-course-eligibility__name" ref={(ref) => this.vceSubjectSelectDomRef = ref}>
                                    <Select showSearch={true}
                                            allowClear={true}
                                            value={vceSubject.name}
                                            placeholder="Select a VCE subject"
                                            onChange={this.selectVCESubject.bind(this)}
                                            filterOption={(input, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            getPopupContainer={() => this.vceSubjectSelectDomRef}
                                            onDropdownVisibleChange={this.onVCESubjectSelectionVisibilityChange.bind(this)}>
                                        {vceSubjects.map((vceSubject) => <Option value={vceSubject} key={vceSubject}>{vceSubject}</Option>)}
                                    </Select>
                                </div>
                                <div className="course-eligibility__add-course-eligibility__item course-eligibility__add-course-eligibility__score">
                                    <InputFieldCmpt validate={this.validateVCEScore.bind(this)} maxLength={2} value={this.state.vceSubject.score}/>
                                </div>
                                <div className="course-eligibility__add-course-eligibility__item course-eligibility__add-course-eligibility__add-button">
                                    <ClickableCmpt content={"ADD"} onclick={this.addCourseEligibility.bind(this)}/>
                                </div>
                            </div>
                        </>
                    }
                    contentVisible={'initial.false'}
                />
            </div>
        );
    }
}