import {Outcome} from "../outcomes/outcome";

export interface Suggestions {
    outcomes: Array<Outcome>;
    sets: Array<Suggestion>;
}


export class Suggestion {
    name: string;
    uuid: string;
    type: Type;
    subjects: Array<string>;
}

export enum Type {
    defaults = "defaults",
    compulsory = "compulsory",
    selective = "selective",
    associated = "associated"
}

