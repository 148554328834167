const {GCP_PROJECT='dev-melbuni-courseplanner', STACK_DRIVER_KEY='AIzaSyCnSZrivoz1LbC9qO4ZvPS9rQ5JFFBqgAc'} = process.env;

window.addEventListener('DOMContentLoaded', function() {
  var errorHandler = new window.StackdriverErrorReporter();
  errorHandler.start({
    key: STACK_DRIVER_KEY,
    projectId: GCP_PROJECT
  });
});

export {}