import 'antd/dist/antd.css';
import './theme/style/reset.scss';

import * as React from "react";
import {CoursePlannerCmpt} from "./course-planner/course-planner.cmpt";
import {Provider} from "mobx-react";

import {browserName, isMobile, isTablet} from 'react-device-detect';
import {MyCoursePlansService} from "./course-planner/shared/services/my-course-plans.service";
import {PrintViewCmpt} from "./print-view/print-view.cmpt";
import {EventBus, Events} from "./course-planner/shared/services/event-bus.service";
import {Given} from "./course-planner/shared/html-util";
import {CourseHandbookStore} from "./course-planner/shared/stores/course-handbook.store";
import {MyCoursePlansStore} from "./course-planner/shared/stores/my-course-plans.store";
import {FeatureSupportStore} from "./course-planner/shared/stores/feature-support.store";
import {UIConfigStore} from "./course-planner/shared/stores/ui-config.store";
import {UiStateStore} from "./course-planner/shared/stores/ui/ui-state.store";


const {REACT_APP_API_URL} = process.env;

export enum RenderingMode {
    print = 'print'
}

const planKey = 'planId';

export class App extends React.Component {

    private readonly mode: string;
    private readonly courseCode: string;
    private readonly myCoursePlansService: MyCoursePlansService;
    private readonly courseHandbook: CourseHandbookStore;
    private readonly myCoursePlans: MyCoursePlansStore;
    private readonly featureSupport: FeatureSupportStore;
    private readonly uiConfig: UIConfigStore;
    private readonly uiState: UiStateStore;

    constructor(props: any) {
        super(props);

        EventBus.register(Events.APP_DATA_LOADED, this.load.bind(this));
        EventBus.register(Events.VALIDATING_COURSE_PLAN_UPDATE_UI, this.save.bind(this));

        const givenWin = Given.window();
        this.courseCode = givenWin.getQueryParam('course');
        if (!this.courseCode) {
            // default to bsci to start with
            this.courseCode = 'bsci';
            givenWin.putQueryParam('course', 'bsci');
        }
        this.mode = givenWin.getQueryParam('mode');

        this.myCoursePlansService = new MyCoursePlansService(REACT_APP_API_URL);
        this.courseHandbook = new CourseHandbookStore(this.myCoursePlansService);
        this.myCoursePlans = new MyCoursePlansStore(this.myCoursePlansService, this.courseHandbook, this.courseCode);
        this.featureSupport = {htmlDnD: !isMobile && !isTablet, browserName};
        this.uiConfig = new UIConfigStore(this.courseCode);
        this.uiState = new UiStateStore();
    }


    load() {
        const givenWin = Given.window();
        const planId = givenWin.getQueryParam(planKey);
        if (!!planId) {
            try {
                this.myCoursePlans.loadSave(planId);
            } catch (error) {
                console.error("Unable to load saved plan.", error);
                this.myCoursePlans.clearAllSubjects();
            }
        } else {
            // kick off an empty plan validation for course level suggestions
            this.myCoursePlans.clearAllSubjects();
        }
    }

    save() {
        const givenWin = Given.window();
        const currPlan = givenWin.getQueryParam(planKey);
        const newPlan = this.myCoursePlans.generateSave();
        if (currPlan !== newPlan) {
            givenWin.putQueryParam(planKey, newPlan);
        }
    }

    render() {

        return (
            <Provider
                myCoursePlans={this.myCoursePlans}
                courseHandBook={this.courseHandbook}
                featureSupport={this.featureSupport}
                uiState={this.uiState}
                uiConfig={this.uiConfig}>
                {this.mode === RenderingMode.print ? <PrintViewCmpt/> : <CoursePlannerCmpt/>}
            </Provider>
        );
    }
}
