import './subjects-by-levels.cmpt.scss';
import {Subject} from "../../../subject";
import {EventBus, Events} from "../../../../shared/services/event-bus.service";
import {inject, observer} from "mobx-react/custom";
import {Given} from "../../../../course-plan/course-plan";
import {SubjectDetailsCmpt} from "../../subject-details/subject-details.cmpt";
import {CollapsibleListCmpt} from "../../../../shared/components/collapsible-list/collapsible-list.cmpt";
import * as React from "react";
import {SubjectHeaderCmpt} from "./subject-header/subject-header.cmpt";
import {SubjectHeaderActionsCmpt} from "./subject-header/subject-header-actions/subject-header-actions.cmpt";
import {CollapsibleCmpt} from "../../../../shared/components/collapsible/collapsible.cmpt";
import {MyCoursePlansStore} from "../../../../shared/stores/my-course-plans.store";
import {ArrayUtil} from "../../../../shared/util";
import {From} from "../../../../shared/stores/ui/drag-data.vm";
import {UiStateStore} from "../../../../shared/stores/ui/ui-state.store";


function onStartDragSubjectItem({event, subject, uiState}) {
    event.stopPropagation();

    uiState.dragData = {
        dragSubject: subject,
        insertSubject: null,
        position: null,
        from: From.all_subjects,
        currDraggingDomRef: null
    };
    event.dataTransfer.setData('dummy', `${subject.code}`);
    EventBus.fire({name: Events.REORDERING_SELECTED_SUBJECT, payload: true});
}

function onEndDragSubjectItem({event}) {
    event.preventDefault();
    event.dataTransfer.setData('dummy', null);
    EventBus.fire({name: Events.REORDERING_SELECTED_SUBJECT, payload: false});
}


function SubjectAtLevel({
                            subject,
                            expand,
                            category_uuid,
                            breadthId,
                            selectedMajors,
                            onSubjectExpandChange,
                            onFavourite,
                            onUnFavourite,
                            onSelect,
                            onUnSelect,
                            uiState
                        }) {
    const givenSubject = Given.subject(subject);
    const isSelected = givenSubject.isSelected();
    const isFavourite = Given.subject(subject).isFavourited();
    const subjectCategory = givenSubject.getSubjectCategory(breadthId, selectedMajors);

    return (
        <CollapsibleCmpt
            header={{
                showIndicator: false,
                content: (isExpanded: boolean) =>
                    <SubjectHeaderCmpt subject={subject}
                                       subjectCategory={subjectCategory}
                                       isSelected={isSelected}
                                       isExpand={isExpanded}
                                       onDragStart={event => onStartDragSubjectItem({event, subject, uiState})}
                                       onDragEnd={event => onEndDragSubjectItem({event})}/>,
                actions: () =>
                    <SubjectHeaderActionsCmpt subject={subject}
                                              isSelected={isSelected}
                                              isFavourited={isFavourite}
                                              unFavourite={onUnFavourite}
                                              favourite={onFavourite}
                                              unSelect={onUnSelect}
                                              select={onSelect}/>
            }}
            content={<SubjectDetailsCmpt subject={subject} key={`${subject.code}`}/>}
            contentVisible={expand}
            onContentVisibility={
                {
                    callback: (visible) => {
                        if (visible === false) {
                            onSubjectExpandChange(null, null);
                        } else {
                            onSubjectExpandChange(category_uuid, subject.code);
                        }

                    }
                }
            }
        />
    );
}

export interface SubjectsByLevelsCmptProps {
    subjects: Array<Subject>;
    category_uuid: string;
    expandSubject: string;
    onSubjectExpandChange: (category_uuid: string, subjectCode: string) => void
    myCoursePlans?: MyCoursePlansStore;
    uiState?: UiStateStore;
}

// TODO: remove @observer from this class
@inject('myCoursePlans', 'uiState')
@observer
export class SubjectsByLevelsCmpt extends React.Component<SubjectsByLevelsCmptProps> {

    wrapAction(onAction: () => void) {
        // reset the expanding subject
        this.props.onSubjectExpandChange(null, null);
        onAction();
    }

    render() {
        const {subjects, myCoursePlans, category_uuid, expandSubject, onSubjectExpandChange, uiState} = this.props;
        const temp = Given.subjects(subjects).groupByLevels();
        const keys = Array.from(temp.keys());
        const values = Array.from(temp.values());
        const groupByLevels = ArrayUtil.sortBy(keys.map((key, idx) => ({level: key, subjects: values[idx]})), 'level');

        // eslint-disable-next-line
        const {selectedMajors, selectedSubjects, coursePlan} = myCoursePlans;
        const breadthId = coursePlan.coursePlanReq.breadthId;

        return (
            groupByLevels.map(({level, subjects}) => {
                return (
                    <div className="subjects-by-category-level-group" key={level}>
                        <span className="subjects-by-category-level-group__level">L{level}</span>
                        <CollapsibleListCmpt renderingItems={
                            () => subjects
                                .sort((s1, s2) => {
                                    return Given.subject(s1).getAvailabilitySortValue() < Given.subject(s2).getAvailabilitySortValue() ? -1 : 1;
                                })
                                .map((subject) => {
                                    const expand = `${category_uuid}-${subject.code}` === expandSubject;
                                    return <SubjectAtLevel key={`${subject.code}`}
                                                           subject={subject}
                                                           expand={expand}
                                                           category_uuid={category_uuid}
                                                           breadthId={breadthId}
                                                           selectedMajors={selectedMajors}
                                                           onSubjectExpandChange={onSubjectExpandChange}
                                                           onFavourite={() => this.wrapAction(() => myCoursePlans.favouriteSubject(subject))}
                                                           onUnFavourite={() => this.wrapAction(() => myCoursePlans.unFavouriteSubject(subject))}
                                                           onSelect={() => this.wrapAction(() => myCoursePlans.selectSubject(subject))}
                                                           onUnSelect={() => this.wrapAction(() => myCoursePlans.unSelectSubject(subject))}
                                                           uiState={uiState}/>
                                })
                        }/>
                    </div>
                );
            })
        );
    }
}