const {REACT_APP_ANALYTICS_ID = 'UA-130164848-2'} = process.env;
const dataLayer = window.dataLayer || [];
const history = window.history;
const pushState = history.pushState;

function gtag() {
  dataLayer.push(arguments);
}

try {

// setup google analytics tag
  gtag('js', new Date());
  gtag('config', REACT_APP_ANALYTICS_ID);
// ensure we send pageview on url change
  history.pushState = function (state) {
    if (typeof history.onpushstate == "function") {
      history.onpushstate({state: state});
    }

    // send page view whenever page url updates
    gtag('config', REACT_APP_ANALYTICS_ID, {'page_path': window.location.pathname});
    return pushState.apply(history, arguments);
  }
} catch (e) {
  console.error('google analytics fialed.', e);
}

export {}

