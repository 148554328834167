import './selected-subject-header.cmpt.scss'

import {ClickableCmpt} from "../../../../shared/components/clickable/clickable.cmpt";
import * as React from "react";
import {Status} from "../../../../status";
import {Subject} from "../../../../browse-subjects/subject";
import {Given as CoursePlan_Given} from "../../../course-plan";
import {Outcome} from "../../../../outcomes/outcome";


function getStatusIcon(status: Status, selectionClass: string) {
    if (selectionClass === 'favourite') {
        return <i className="selected-subject-header__subject-status--met material-icons dp48">check_circle</i>;
    }

    switch (status) {
        case Status.unmet:
            return <i className="selected-subject-header__subject-status--unmet material-icons dp48">report</i>;
        case Status.met:
            return <i className="selected-subject-header__subject-status--met material-icons dp48">check_circle</i>;
        case Status.open:
            return <i className="selected-subject-header__subject-status--open material-icons dp48">warning</i>;
        default:
            return undefined;
    }
}

function getCategoryClassName(subject: Subject, breadthId: string, selectedMajors: Array<Outcome>) {
    // if is a breadth subject
    let categoryClassName = 'selected-subject-header__subject-category--elective';

    if (CoursePlan_Given.subject(subject).isBreadth(breadthId, selectedMajors)) {
        categoryClassName = 'selected-subject-header__subject-category--breadth';
    }

    if (!!selectedMajors && !!CoursePlan_Given.subject(subject).isCompulsory(selectedMajors)) {
        categoryClassName = 'selected-subject-header__subject-category--compulsory';
    }

    return categoryClassName;
}

export interface SelectedSubjectHeaderCmptProps {
    selectedSubject: Subject;
    selectionClass: string;
    // status: Status;
    mappedAvailability: string;
    breadthId: string;
    selectedMajors: Array<Outcome>;
    unSelectSubject: (s: Subject) => void;
}

export function SelectedSubjectHeaderCmpt({
                                              selectedSubject,
                                              selectionClass,
                                              mappedAvailability,
                                              breadthId,
                                              selectedMajors,
                                              unSelectSubject
                                          }: SelectedSubjectHeaderCmptProps) {
    const status = selectedSubject.state.status;

    return (
        <div className={`selected-subject-header-header ${getCategoryClassName(selectedSubject, breadthId, selectedMajors)}`}>
            <div className="selected-subject-header__subject-status">
                {getStatusIcon(status, selectionClass)}
            </div>
            <div className="selected-subject-header__subject-name">{selectedSubject.info.name}</div>
            <div className="selected-subject-header__subject-code">
                {selectedSubject.code}
            </div>
            <div className="selected-subject-header__subject-semesters">
                {mappedAvailability}
            </div>
            <div className="selected-subject-header__subject-remove-button">
                <ClickableCmpt content={<i className="material-icons dp48">remove_circle_outline</i>}
                               onclick={() => unSelectSubject(selectedSubject)}/>
            </div>
        </div>
    );

}