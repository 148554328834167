import './subject-breadcrumb.cmpt.scss';
import {Subject} from "../../subject";
import * as React from "react";
import {ArrayUtil} from "../../../shared/util";
import {Given} from "../../../course-plan/course-plan";
import {Availability} from "../../availability";

export interface SubjectBreadcrumbCmptProps {
    subject: Subject;
}

export class SubjectBreadcrumbCmpt extends React.Component<SubjectBreadcrumbCmptProps> {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    shouldComponentUpdate(nextProps: Readonly<SubjectBreadcrumbCmptProps>, nextState: Readonly<{}>, nextContext: any): boolean {
        if(this.props.subject.code !== nextProps.subject.code) {
            return true;
        }
        return false;
    }

    render() {
        const {subject} = this.props;
        const mappedAvailability = Given.subject(subject).getMappedAvailability();
        const allTerms: Array<Availability> = ArrayUtil.flatten(
            Object.keys(mappedAvailability).map(semester => mappedAvailability[semester])
        );

        return (
            <div className="subject-breadcrumb">
                <div className="subject-breadcrumb__items">
                    {
                        allTerms
                            .map((semester, index) => {
                                let semesterIcon: string | JSX.Element = semester.shortText;
                                switch (semester.shortText) {
                                    case 'Winter':
                                        semesterIcon = <div className="subject-breadcrumb__semester__winter"></div>;
                                        break;
                                    case 'Summer':
                                        semesterIcon = <div className="subject-breadcrumb__semester__summer"></div>;
                                        break;
                                    default:
                                        break;
                                }
                                return (
                                    <React.Fragment key={`subject-breadcrumb-semester-${semester.shortText}`}>
                                        <div className="subject-breadcrumb__item subject-breadcrumb__semester" key={semester.shortText}>
                                            {semesterIcon}
                                        </div>
                                        {index === (allTerms.length - 1) ? null : <div className="subject-breadcrumb__spacer" key={`${semester.shortText}-spacer`}></div>}
                                    </React.Fragment>
                                );
                            })
                    }
                </div>
            </div>
        );
    }

}